import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import CheckedBox from "assets/icons/boxes/checked-box";
import { EbayProductExplorerState } from "types/services/ebayProductExplorer";

export const marketPlaces = [
  "All",
  "ebay.com",
  "ebay.co.uk",
  "ebay.com.au",
  "ebay.ca",
  "ebay.fr",
  "ebay.de",
  "ebay.it",
  "ebay.es",
];

export const getMarketplaceFlag = {
  [marketPlaces[0]]: "WW",
  [marketPlaces[1]]: "US",
  [marketPlaces[2]]: "GB",
  [marketPlaces[3]]: "AU",
  [marketPlaces[4]]: "CA",
  [marketPlaces[5]]: "FR",
  [marketPlaces[6]]: "DE",
  [marketPlaces[7]]: "IT",
  [marketPlaces[8]]: "ES",
  [marketPlaces[9]]: "MY",
  [marketPlaces[10]]: "SG",
};

export const shippingLocations = [
  "All Locations",
  "United States",
  "United Kingdom",
  "Canada",
  "Australia",
  "China",
  "France",
  "Deutschland",
  "Italy",
  "Spain",
  "Malaysia",
  "Singapore",
];

export const getMarketplaceFromSettings = (plan: string) => {
  switch (plan) {
    case ".co.uk":
      return marketPlaces[2];
    case ".com.au":
      return marketPlaces[3];
    case ".ca":
      return marketPlaces[4];
    case ".fr":
      return marketPlaces[5];
    case ".de":
      return marketPlaces[6];
    case ".it":
      return marketPlaces[7];
    case ".es":
      return marketPlaces[8];
    case "all":
      return marketPlaces[0];
    default:
      return marketPlaces[1];
  }
};

export const listingsList = [
  { text: "Fixed Price", value: "fixed" },
  { text: "Auction", value: "auction" },
];
export const buyingFormats = [
  "Buy it now",
  "Accept Offers",
  "Auction",
  "Classified Ads",
];

export const conditionList = ["Brand New", "Not Specified", "Used"];

export const initialPEFiltersState: EbayProductExplorerState = {
  searchByKeywords: [],
  excludeKeywords: [],
  marketplace: marketPlaces[1],
  shippingLocation: shippingLocations[1],
  supplierWebsite: [],
  buyingFormat: "",
  condition: "",
  salesDateRange: ["7", "14", "30"],
  categories: [],
  exclude: [],
  supplierItemMatch: [],
  searched: false,
  sales: {
    min: null,
    max: null,
  },
  revenue: {
    min: null,
    max: null,
  },
  price: {
    min: null,
    max: null,
  },
};

export const excludeItems = [
  {
    value: "excludeVero",
    text: "VeRO Items",
  },
  {
    value: "excludeRisky",
    text: "Risky Items",
  },
];

const boxStyles =
  "fill-darkBlue group-hover:fill-white w-[14px] h-[13px] dark:fill-lilac400";
const checkedBoxStyles = "!fill-white dark:!fill-deepSpace900";
export const exactSimilarFilter = [
  {
    value: "exactItems",
    text: "Exact Items",
    icon: CheckedBox,
    iconClassName: boxStyles,
    checkedIconClassName: checkedBoxStyles,
  },
  {
    value: "similarItems",
    text: "Similar Items",
    icon: Box,
    iconClassName: boxStyles,
    checkedIconClassName: checkedBoxStyles,
  },
];
export const supplierSites = (marketplace: string) => [
  ...(marketplace === marketPlaces[0] || marketplace === marketPlaces[1]
    ? [
        {
          value: "1",
          text: "amazon.com",
        },
        {
          value: "4",
          text: "walmart.com",
        },
      ]
    : marketplace === marketPlaces[2]
    ? [
        {
          value: "3",
          text: "amazon.co.uk",
        },
      ]
    : []),

  {
    value: "2",
    text: "aliexpress.com",
  },
];

export const ebayPECategories = {
  [marketPlaces[1]]: [
    "Antiques",
    "Art",
    "Baby",
    "Books & Magazines",
    "Business & Industrial",
    "Cameras & Photos",
    "Cell Phones & Accessories",
    "Clothing, Shoes & Accessories",
    "Coins & Paper Money",
    "Collectibles",
    "Computers/Tablets & Networking",
    "Consumer Electronics",
    "Crafts",
    "Dolls & Bears",
    "eBay Motors",
    "Entertainment Memorabilia",
    "Health & Beauty",
    "Home & Garden",
    "Jewelry & Watches",
    "Movies & TV",
    "Music",
    "Musical Instruments & Gear",
    "Pet Supplies",
    "Pottery & Glass",
    "Sporting Goods",
    "Sports Mem, Cards & Fan Shop",
    "Stamps",
    "Toys & Hobbies",
    "Video Games & Consoles",
  ],
  [marketPlaces[2]]: [
    "Antiques",
    "Art",
    "Baby",
    "Books, Comics & Magazines",
    "Business, Office & Industrial",
    "Cameras & Photography",
    "Cars, Motorcycles & Vehicles",
    "Clothes, Shoes & Accessories",
    "Coins",
    "Collectables",
    "Computers/Tablets & Networking",
    "Crafts",
    "Dolls & Bears",
    "DVD's, Films & TV",
    "Consumer Electronics",
    "Garden & Patio",
    "Health & Beauty",
    "Home, Furniture & DIY",
    "Jewelry & Watches",
    "Mobile Phones & Communication",
    "Music",
    "Musical Instruments",
    "Pet Supplies",
    "Pottery, Porcelain & Glass",
    "Sound & Vision",
    "Sporting Goods",
    "Sports Memorabilia",
    "Toys & Games",
    "Vehicle Parts & Accessories",
    "Video Games & Consoles",
    "Wholesale & Job Lots",
    "Stamps",
  ],
  [marketPlaces[3]]: [
    "Alcohol & Food",
    "Antiques",
    "Art",
    "Baby",
    "Books, Magazines",
    "Business",
    "Cameras",
    "Cars, Bikes, Boats",
    "Clothes, Shoes & Accessories",
    "Coins",
    "Collectables",
    "Computers/Tablets & Networking",
    "Crafts",
    "Dolls, Bears",
    "Electronics",
    "Health & Beauty",
    "Home Appliances",
    "Home Entertainment",
    "Home & Garden",
    "Industrial",
    "Jewelry & Watches",
    "Movies",
    "Music",
    "Musical Instruments",
    "Pet Supplies",
    "Phones & Accessories",
    "Pottery, Glass",
    "Services",
    "Sporting Goods",
    "Stamps",
    "Toys, Hobbies",
    "Vehicle Parts & Accessories",
    "Video Games & Consoles",
    "Lots More...",
    "Tickets, Travel",
  ],
  [marketPlaces[4]]: [
    "Antiques",
    "Art",
    "Automotive",
    "Baby",
    "Books",
    "Business & Industrial",
    "Cameras & Photos",
    "Cell Phones & Accessories",
    "Clothing, Shoes & Accessories",
    "Coins & Paper Money",
    "Collectibles",
    "Computers/Tablets & Networking",
    "Consumer Electronics",
    "Crafts",
    "Dolls & Bears",
    "DVDs & Movies",
    "Entertainment Memorabilia",
    "Health & Beauty",
    "Home & Garden",
    "Jewelry & Watches",
    "Music",
    "Musical Instruments & Gear",
    "Pet Supplies",
    "Pottery & Glass",
    "Sporting Goods",
    "Sports Mem, Cards & Fan Shop",
    "Stamps",
    "Toys & Hobbies",
    "Video Games & Consoles",
  ],
  [marketPlaces[5]]: [
    "Animalerie",
    "Art, antiquités",
    "Auto, moto",
    "Bateaux, voile, nautisme",
    "Beauté, bien-être, parfums",
    "Bébé, puériculture",
    "Bijoux, montres",
    "Bricolage",
    "Céramiques, verres",
    "Collections",
    "DVD, cinéma",
    "Électroménager",
    "Image, son",
    "Immobilier",
    "Informatique, réseaux",
    "Instruments de musique",
    "Jardin, terrasse",
    "Jouets et jeux",
    "Jeux vidéo, consoles",
    "Livres, BD, revues",
    "Loisirs créatifs",
    "Maison",
    "Musique, CD, vinyles",
    "Photo, caméscopes",
    "PME, artisans, agriculteurs",
    "Sports, vacances",
    "Téléphonie, mobilité",
    "Timbres",
    "Véhicules: pièces, accessoires",
    "Vêtements, accessoires",
    "Vins, Gastronomie",
  ],
  [marketPlaces[6]]: [
    "Antiquitäten & Kunst",
    "Auto & Motorrad",
    "Baby",
    "Bastel- & Künstlerbedarf",
    "Beauty & Gesundheit",
    "Briefmarken",
    "Bücher",
    "Büro & Schreibwaren",
    "Business & Industrie",
    "Computer, Tablets & Netzwerk",
    "Feinschmecker",
    "Filme & DVDs",
    "Foto & Camcorder",
    "Garten & Terrasse",
    "Handys & Kommunikation",
    "Haushaltsgeräte",
    "Haustierbedarf",
    "Heimwerker",
    "Immobilien",
    "Kleidung & Accessoires",
    "Modellbau",
    "Möbel & Wohnen",
    "Münzen",
    "Musik",
    "Musikinstrumente",
    "PC- & Videospiele",
    "Reisen",
    "Sammeln & Seltenes",
    "Spielzeug",
    "Sport",
    "TV, Video & Audio",
    "Uhren & Schmuck",
    "Verschiedenes",
  ],
  [marketPlaces[7]]: [
    "Arte e antiquariato",
    "Abbigliamento e accessori",
    "Auto, moto e altri veicoli",
    "Bellezza e salute",
    "Casa, arredamento e bricolage",
    "Collezionismo",
    "Commercio, ufficio e industria",
    "Elettrodomestici",
    "Film e DVD",
    "Fotografia e video",
    "Francobolli",
    "Fumetti",
    "Giardino e arredamento esterni",
    "Giocattoli e modellismo",
    "Hobby creativi",
    "Infanzia e premaman",
    "Informatica",
    "Libri e riviste",
    "Orologi e gioielli",
    "Sport e viaggi",
    "Strumenti musicali",
    "Telefonia fissa e mobile",
    "TV, audio e video",
    "Videogiochi e console",
  ],
  [marketPlaces[8]]: [
    "Arte y antigüedades",
    "Artesanía y manualidades",
    "Bebés",
    "Belleza y salud",
    "Cámaras y fotografía",
    "Casa, jardín y bricolaje",
    "Cine, DVD y películas",
    "Coches, motos y otros vehíc.",
    "Coleccionismo",
    "Consolas y videojuegos",
    "Deportes",
    "Electrodomésticos",
    "Equipamiento y maquinaria",
    "Imagen y sonido",
    "Informática y tablets",
    "Instrumentos musicales",
    "Juguetes",
    "Libros, revistas y cómics",
    "Monedas y billetes",
    "Motor: recambios y accessorios",
    "Móviles y telefonía",
    "Música, CDs y vinilos",
    "Relojes y joyas",
    "Ropa, calzado y complementos",
    "Sellos",
    "Viajes",
    "Vinos Y gastronomía",
  ],
};
