import Card from "components/Card";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import ShopifyMarketplaceIcon from "components/MarketplaceIcon/Shopify";
import Toaster from "components/Toast";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import ShopifyBillingHeader from "pages/Shopify/Billing/components/BillingHeader";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
    useGetDefaultCouponQuery,
    useLazyCheckUpgradeQuery,
    useLazyPayoneerPurchaseQuery,
    useLazyPaypalPurchaseQuery,
    useLazyUpgradeQuery
} from "store/api/managePaymentApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { SetAccessToken } from "utils/localStorage";

import { ReactComponent as IconLeft } from "assets/icons/arrow-left-extended-bold.svg";
import { ReactComponent as IconRight } from "assets/icons/arrow-right-extended-bold.svg";
import Button from "components/Button";
import CheckoutCouponInput from "components/Input/CheckoutCouponInput";
import Loading from "components/Loading";

import EbayMarketplaceIcon from "components/MarketplaceIcon/Ebay";
import PaymentMethod from "components/PaymentMethod/Index";
import { onSelectPlan } from "store/slices/planSlice";
import { makeQueryParams } from "utils/makeQueryParams";
import { getPlanDetails, getPlanName, getPlanSuffix, isCurrentPlan, isDowngrade } from "utils/planUtils";
import LimitUpgradeSwitch from "./LimitUpgradeSwitch";

const SubscriptionUpgradePage = () => {
    const [isCCTAlreadyTrial, setIsCCAlreadyTrial] = useState(false);
    const [ccPaymentLoading, setCCPaymentLoading] = useState(false);
    const user = useDecodeToken();
    const isTrialFromToken = /^true$/i.test(user.active_trail);
    const [paypalPurchase, { isFetching: paypalFetching }] = useLazyPaypalPurchaseQuery();
    const [payoneerPurchase, { isFetching: payoneerFetching }] = useLazyPayoneerPurchaseQuery();
    const { search } = useLocation();
    const [method, setMethod] = useState("");
    const fromAutopilot = search.includes("from=AutoPilot")
    const fromTurboScanner = search.includes("from=TurboScanner")
    const fromZikPro = fromAutopilot || fromTurboScanner
    const fromPE = search.includes("from=PE");
    const [searchParams] = useSearchParams()
    const { selectedPlan } = useAppSelector((state) => state.planSlice);
    const [couponUsed, setCouponUsed] = useState(false);
    const currentPlan = user.package_name || "";
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [coupon, setCoupon] = useState("");
    const [couponApplying, setCouponApplying] = useState(true);
    const handleMethod = (type: string) => setMethod(type);
    const changeCouponUsed = (isUsed: boolean) => setCouponUsed(isUsed)
    const changeCouponApplying = (applying: boolean) => setCouponApplying(applying);
    const handleCCAlreadyTrial = (isTrial: boolean) => { setIsCCAlreadyTrial(isTrial); setCouponUsed(false) };
    const handleApplyCoupon = (newVal: string) => setCoupon(newVal);
    const { data: defaultCoupon, isFetching: couponLoading } =
        useGetDefaultCouponQuery(selectedPlan.type, { skip: selectedPlan.type === "" });
    const [checkUpgrade, { data, isFetching: checkUpgradeFetching, isUninitialized: checkUpgradeUninitialized }] =
        useLazyCheckUpgradeQuery();
    const changeCCPaymentLoading = (isLoading: boolean) => setCCPaymentLoading(isLoading);
    const isTrial = data?.result.isActiveTrial;
    const summaryLoading = checkUpgradeFetching || couponLoading || couponApplying || checkUpgradeUninitialized
    const [makeUpgrade, { isFetching: upgradeFetching }] = useLazyUpgradeQuery();
    const [checkoutData, setCheckoutData] = useState(data);
    const checkCurrent = isCurrentPlan(currentPlan || "", selectedPlan.type);
    const tool = fromAutopilot ? "AutoPilot" : fromTurboScanner ? "TurboScanner" : "EbayPe";
    const isShopify = search.includes("isShopify");
    const isFreeTrial = isShopify ? user.shopify_free_trial === "True" : user.free_trial === "True";

    useEffect(() => {
        if (selectedPlan.type === "" && searchParams.get("type")) {
            const planType = searchParams.get("type") || ""
            const plan = getPlanDetails(planType);
            const skip = searchParams.get("skip") || false
            dispatch(onSelectPlan({
                type: planType,
                planName: plan?.planName || "",
                planPrice: plan?.planPrice || 0,
                skip
            }))
        }
    }, [selectedPlan, searchParams, fromZikPro]);

    const handleRefetchUpgrade = (type?: string) => {
        checkUpgrade({
            package: type || selectedPlan.type,
            coupon: coupon,
            ...(fromZikPro || fromPE) && { tool },
        })
            .unwrap()
            .then((data) => setCheckoutData(data))
            .catch((error) => toast.error(error.data.message));
    }

    useEffect(() => {
        if (!couponLoading && selectedPlan.type !== "") {
            handleRefetchUpgrade(selectedPlan.type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponLoading, selectedPlan, coupon]);

    const handleVatNumberUpdate = () => {
        if (selectedPlan?.type != null) {
            handleRefetchUpgrade();
        }
    }


    const handlePurchase = () => {
        if (method === "") {
            toast.error("Please, select a payment method.");

            return;
        }

        if (method === "pp") {
            paypalPurchase({
                couponName: coupon,
                requestedSubscriptionType: selectedPlan.type,
            })
                .unwrap()
                .then((data) => window.location.replace(data.message));
        }

        if (method === "py") {
            payoneerPurchase({
                couponName: coupon,
                type: selectedPlan.type,
                skipTrial: selectedPlan.skip
            })
                .unwrap()
                .then((data) => window.location.replace(data.message));
        }
    };


    const handleUpgrade = () => {
        makeUpgrade({
            type: selectedPlan.type,
            couponName: coupon,
            ...(fromZikPro || fromPE) && { tool },
        })
            .unwrap()
            .then((data) => {
                if (data.result.token != null && data.result.token != "") {
                    SetAccessToken(data.result.token);
                    dispatch(setUserAccessToken(data.result.token));
                }
                if (data.result.trial) {
                    navigate(PATH.purchaseComplete.changed);
                } else if (data.result.downgrade) {
                    navigate(PATH.purchaseComplete.downgrade);
                } else if (data.result.message === "redirect" && data.result.additionalInformation !== null) {
                    window.location.replace(data.result.additionalInformation!)
                } else if (data.result.message === "retry") {
                    navigate(makeQueryParams(PATH.managementPanel.subscriptionPurchase, { type: selectedPlan.type, Retry: true }));
                }
                else {
                    navigate(PATH.purchaseComplete.upgrade);
                }
            })
            .catch((error) => toast.error(error.data));
    };

    const changeNowStyles = `w-full mb-2 py-4 hover:bg-primaryPurple dark:text-lilac400 rounded-full dark:hover:bg-purple500 font-bold dark:font-medium hover:text-white 
    ${isTrial ? "border-2 border-darkBlue hover:border-primaryPurple dark:border-lilac400 hover:dark:border-purple500" : ""}`
    const isLifeTime = selectedPlan.type.includes("LT")
    const isEnterprise = selectedPlan.type.toLowerCase().includes("enterprise")
    const isDowngradePlan = (isTrialFromToken && fromPE) ? false : isDowngrade(currentPlan, selectedPlan.type)
    const cardStyles = "w-full border-[1px] border-solid border-secondary  dark:text-grey100 dark:border-grey700";
    const showPaymentOptions = isFreeTrial;
    return (
        <>
            <ShopifyBillingHeader />
            <div className="flex gap-5 max-lg:flex-col">
                <Card className={`p-8 flex flex-col gap-6 ${cardStyles}`}>
                    <div className="flex w-full justify-between items-center">
                        <h6 className="font-medium">Billing Details</h6>
                        {isShopify ? <ShopifyMarketplaceIcon /> : <EbayMarketplaceIcon />}
                    </div>
                    {summaryLoading ? <div className="w-full h-10 flex justify-center items-center">
                        <Loading fill="fill-primaryPurple" />
                    </div> : showPaymentOptions ? (
                        <div>
                            <div className="flex flex-col md:flex-row items-center gap-[29px]">
                                <div className={"h-[100px] px-6 w-full dark:bg-grey100 dark:text-deepSpace900 bg-neutral2 rounded-[15px] flex justify-center flex-col gap-2.5"}>
                                    <div className="flex items-center text-[20px] max-sm:text-base">
                                        <span className={`font-bold ${isShopify ? "capitalize" : "uppercase"}`}>
                                            {isShopify ? selectedPlan.planName : getPlanName(selectedPlan.type)}
                                        </span>
                                        {isShopify ? "" : (
                                            <span className="font-medium ml-2">
                                                {isLifeTime && !isEnterprise ? " /life" : getPlanSuffix(selectedPlan.type)}
                                            </span>
                                        )}
                                    </div>
                                    <div className="text-[14px] font-medium">Selected Plan</div>
                                </div>
                            </div>
                            <PaymentMethod
                                alreadyTrial={isCCTAlreadyTrial}
                                className="mt-[18px] mb-[26px]"
                                handleMethod={handleMethod}
                                handleApplyCoupon={handleApplyCoupon}
                                onCCPaymentLoading={changeCCPaymentLoading}
                                selectedPlan={selectedPlan.type}
                                coupon={coupon}
                                isShopify={isShopify}
                                skip={selectedPlan.skip}
                                handleAlreadyTrial={handleCCAlreadyTrial}
                                fromRegistration={couponUsed || isCCTAlreadyTrial}
                                amount={data?.result.totalToday}
                            />


                            {(method === "pp" || method === "py") && (
                                <Button
                                    className="mb-8 w-full mx-auto bg-darkBlue  hover:bg-smoothGreen hover:border-smoothGreen  dark:bg-lilac400 dark:border-lilac400 dark:hover:!bg-paradiseBlue dark:hover:!border-paradiseBlue"
                                    titleClassName="!text-base !font-bold text-smoothGreen group-hover:text-darkBlue dark:text-darkBlue"
                                    height="h-[56px]"
                                    title="Subscribe"
                                    loading={paypalFetching || payoneerFetching || ccPaymentLoading}
                                    handleClick={() => {
                                        handlePurchase();
                                    }}
                                />
                            )}
                        </div>

                    ) : isDowngradePlan ? (
                        <>
                            <div>
                                <div className="flex flex-col mt-2 md:flex-row items-center">
                                    <div className="h-[100px] w-full px-6 py-5 bg-grey100 text-grey300 dark:bg-grey700 dark:text-grey300 rounded-[15px] flex items-start justify-center flex-col gap-[10px]">
                                        <div className="flex items-center text-[20px]">
                                            <span className="font-bold uppercase">
                                                {selectedPlan.planName}
                                            </span>
                                            <span className="font-medium">
                                                {getPlanSuffix(selectedPlan.type)}
                                            </span>
                                        </div>
                                        <div className="text-sm font-medium">Downgrading to</div>
                                    </div>
                                    <div className="min-w-[46px] h-[46px] flex justify-center items-center rotate-90 md:rotate-0">
                                        <IconLeft className="w-[23px] h-[26.86px fill-grey300 dark:fill-grey300" />
                                    </div>
                                    <div className="h-[140px] w-full px-6 py-5 bg-darkBlue dark:bg-paradiseBlue rounded-[15px] flex items-start justify-center flex-col gap-[10px]">
                                        <div className="flex text-white items-center dark:text-deepSpace900 text-[36px]">
                                            <span className="font-bold uppercase">
                                                {getPlanName(currentPlan)}
                                            </span>
                                            <span className="font-medium">
                                                {getPlanSuffix(currentPlan)}
                                            </span>
                                        </div>
                                        <div className="text-[16px] text-white font-medium dark:text-deepSpace900">
                                            Current Plan
                                        </div>
                                    </div>
                                </div>
                                <LimitUpgradeSwitch handleRefetchUpgrade={handleRefetchUpgrade} />
                            </div>
                            {
                                upgradeFetching || summaryLoading ?
                                    <div className="mt-[25px] mb-2 flex justify-center">
                                        <Loading fill={"fill-primaryPurple dark:fill-purple500"} />
                                    </div>
                                    :
                                    <button
                                        disabled={checkUpgradeFetching}
                                        className={changeNowStyles}
                                        onClick={handleUpgrade}>{isTrial ? "Change Now" : "Downgrade"}</button>
                            }
                        </>
                    ) : (
                        <>
                            <div>
                                <div className="flex flex-col md:flex-row mt-2 items-center max-sm:gap-2.5">
                                    <div className="h-[100px] max-sm:h-auto max-sm:p-2.5 w-full px-[25px] py-[20px] bg-neutral2 dark:bg-grey100 dark:text-deepSpace900 rounded-[15px] flex items-start justify-center flex-col gap-[10px]">
                                        <div className="flex items-center text-[24px] max-sm:text-lg">
                                            <span className="font-bold uppercase">
                                                {getPlanName(currentPlan)}
                                            </span>
                                            <span className="font-medium">
                                                {getPlanSuffix(currentPlan)}
                                            </span>
                                        </div>
                                        <div className="text-[14px] font-medium">Current Plan</div>
                                    </div>
                                    <div className="min-w-[46px] h-[46px] flex justify-center items-center rotate-90 md:rotate-0">
                                        <IconRight className="w-[23px] h-[26.86px] fill-smoothGreen dark:fill-paradiseBlue" />
                                    </div>
                                    <div className={`h-[140px] max-sm:h-auto max-sm:p-2.5 w-full px-[25px] py-5 bg-darkBlue dark:bg-paradiseBlue rounded-[15px] flex items-start justify-center flex-col ${isLifeTime ? "gap-[5px]" : "gap-2.5"}`}>
                                        <div className="flex text-white dark:text-deepSpace900 items-center text-[28px] max-sm:text-lg">
                                            <span className="font-bold uppercase">
                                                {selectedPlan.planName}
                                            </span>
                                            <span className="font-medium ml-1">
                                                {isLifeTime ? isEnterprise ? "" : "/life" : getPlanSuffix(selectedPlan.type)}
                                            </span>
                                        </div>
                                        {isLifeTime && isEnterprise ?
                                            <span className="font-medium text-white dark:text-deepSpace900">
                                                Lifetime
                                            </span>
                                            : ""}

                                        <div className="text-sm text-white font-medium dark:text-deepSpace900">
                                            Upgrading to
                                        </div>
                                    </div>
                                </div>
                                <LimitUpgradeSwitch handleRefetchUpgrade={handleRefetchUpgrade} />
                            </div>
                            {(upgradeFetching || summaryLoading) ?
                                (<div className="mt-[25px] mb-2 flex justify-center">
                                    <Loading fill={"fill-primaryPurple dark:fill-purple500"} />
                                </div>)
                                : (isTrial && !checkCurrent) ?
                                    (<button
                                        disabled={checkUpgradeFetching}
                                        className={changeNowStyles}
                                        onClick={handleUpgrade}>Change Now</button>
                                    ) : (
                                        <Button
                                            className="mb-2 h-[60px] w-full mx-auto border-smoothGreen bg-smoothGreen dark:border-lilac400 dark:bg-deepSpace900 hover:bg-primaryPurple hover:border-primaryPurple dark:hover:!bg-purple500 dark:hover:!border-purple500 shadow-[2px_5px_30px_-4px_rgba(28,163,147,0.15)]"
                                            titleClassName="!text-base !font-bold dark:!font-medium text-base dark:text-lilac400 text-white"
                                            title="Upgrade now"
                                            disabled={checkUpgradeFetching}
                                            loading={upgradeFetching}
                                            handleClick={handleUpgrade}
                                        />
                                    )}

                        </>
                    )}

                    {!couponLoading && (
                        <CheckoutCouponInput
                            handleApplyCoupon={handleApplyCoupon}
                            selectedPlan={selectedPlan.type}
                            preloadCoupon={defaultCoupon?.message}
                            handleRefetchUpgrade={handleRefetchUpgrade}
                            couponValidating={changeCouponApplying}
                        />
                    )}
                </Card>

                <Card className={cardStyles}>
                    <CheckoutOrderSummary
                        wrapperStyles="!border-0 !max-w-[100%] !p-8"
                        selectedPlan={selectedPlan}
                        isLoading={summaryLoading}
                        data={checkoutData}
                        handleVatNumberUpdate={handleVatNumberUpdate}
                        couponUsed={couponUsed}
                    />
                </Card>
            </div>
            <Toaster />
        </>
    )
}

export default SubscriptionUpgradePage