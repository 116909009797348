import Button from 'components/Button';
import Logo from 'components/Logo';
import Modal from 'components/Modal';
import PlansDiscountTxt from 'components/Plans/DiscountTxt';
import PlanSwitch from 'components/Plans/PlanSwitch';
import SlidedPlansWithComparison from 'components/Plans/SlidedPlansWithComparison';
import SlidedShopifyWithComparison from 'components/Plans/SlidedShopifyWithComparison';
import Toaster from 'components/Toast';
import { DEFAULT_PLAN_PERIOD, NO_CAMPAIGN } from 'config/campaign-settings';
import { BLUESNAP_SCRIPT } from 'config/config';
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from 'hooks/useDecodeToken';
import useExternalScript from 'hooks/useExternalScript';
import useLogout from 'hooks/useLogout';
import useModal from "hooks/useModal";
import usePlans from 'hooks/usePlans';
import LoadingPage from 'pages/Loading';
import SubscriptionPurchase from 'pages/ManagementPanel/components/ManagePayments/SubscriptionPurchase';
import SelectMarketplace from 'pages/Shopify/Billing/components/SelectMarketplace';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyGetPfTokenQuery, useLazyJourneyCompleteQuery } from 'store/api/managePaymentApi';
import { useEbaySubCountQuery, useIsShopifyUserQuery, useShopifySubCountQuery } from 'store/api/quickSettingsApi';
import { setUserAccessToken } from 'store/slices/userSlice';
import { TSelectedPlan } from 'types/plans';
import { formatCurrency } from 'utils/formatCurrency';
import { SetAccessToken } from 'utils/localStorage';

declare global {
    interface Window {
        gpfToken: any;
    }
}

const NoSubscription = () => {
    const { data, isFetching } = useIsShopifyUserQuery();
    const { data: shopifySubCount, isFetching: shopifySubsFetching } = useShopifySubCountQuery()
    const { data: ebaySubCount, isFetching: ebaySubsFetching } = useEbaySubCountQuery()
    const { search } = useLocation();
    const isShopifyActive = search.includes("marketplace=shopify")
    const [activeTab, setActiveTab] = useState<"ebay" | "shopify">(isShopifyActive ? "shopify" : "ebay");
    const onMarketplaceChange = (tab: "ebay" | "shopify") => setActiveTab(tab)
    const [getPfToken] = useLazyGetPfTokenQuery();
    const [journeyComplete] = useLazyJourneyCompleteQuery();
    const dispatch = useAppDispatch();
    const userDetails = useDecodeToken();
    const user = useDecodeToken();
    const { plans, handlePlanChange } = usePlans(DEFAULT_PLAN_PERIOD);
    const isShopifyTrial = search.includes("isShopify");
    const noEbayPlan = ebaySubCount?.result === 0;
    const noShopifyPlan = shopifySubCount?.result === 0;
    const shopifyAlreadyTrial = user.shopify_already_trial === "1";
    const ebayAlreadyTrial = user.already_trial === "1"
    const ebayUpgrade = isShopifyTrial ? false : !noEbayPlan || ebayAlreadyTrial || user.shopify_attempt === "False"
    const shopifyUpgrade = isShopifyTrial || !noShopifyPlan || shopifyAlreadyTrial || (noShopifyPlan && data?.result);
    const ebayIsPayNow = ebayUpgrade || plans.period === "lifetime";
    const eBayFreeTrialExpired = user.free_trial_expired === "True";
    const shopifyFreeTrialExpired = user.shopify_free_trial_expired === "True";
    const freeTrialExpired = (eBayFreeTrialExpired && activeTab === "ebay") || (shopifyFreeTrialExpired && activeTab === "shopify");

    useEffect(() => {
        if ((shopifyUpgrade && !ebayUpgrade) || shopifyFreeTrialExpired) {
            setActiveTab("shopify")
        }
    }, [shopifyUpgrade, ebayUpgrade])

    useEffect(() => {
        getPfToken(null)
            .unwrap()
            .then((data) => {
                window.gpfToken = data.message;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useExternalScript(BLUESNAP_SCRIPT, "");
    const modal = useModal();
    const [selectedPlan, setSelectedPlan] = useState<TSelectedPlan>({
        type: "",
        planName: "",
        planPrice: 0,
        skip: false
    });
    useEffect(() => {
        if (userDetails?.journey_completed.toLowerCase() !== "true") {
            journeyComplete(null).unwrap().then((data) => {
                SetAccessToken(data.message);
                dispatch(setUserAccessToken(data.message));
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const logout = useLogout();
    const handleSelectPlan = (newVal: TSelectedPlan) => {
        setSelectedPlan(newVal);
        modal.open();
    };

    const planProps = {
        plans,
        handleSelectPlan,
        fromRegistration: !ebayIsPayNow,
        isPayNow: ebayIsPayNow,
    }

    return (
        <div>
            <LoadingPage loading={isFetching || ebaySubsFetching || shopifySubsFetching}>
                <div className="h-[90px] w-full bg-white px-[102px] flex items-center justify-between max-md:px-[20px]">
                    <Logo className="gap-[19px]" imageClassName="w-[38px] h-[38px]" lettersClassName="w-[52px] h-[23px]" />
                    <Button title="Log Out" className="!w-[124px] !h-[44px]" handleClick={logout} />
                </div>
                {freeTrialExpired ? (
                    <h1 className='text-center text-[28px] max-sm:text-[20px] max-sm:leading-[28px] leading-[36px] py-6 max-sm:py-3 max-sm:mx-[30px] max-lg:mx-[60px]'>
                        Your Trial has ended - <span className='text-primaryPurple font-bold'>Continue with Unlimited Usage!</span>
                    </h1>
                ) : (
                    <h1 className={`text-center text-[28px] font-bold  leading-[32px] max-sm:text-[20px] max-sm:py-3 py-6 `}>Great to have you back!</h1>
                )}
                <div className="flex flex-col gap-4 max-sm:px-[30px] ">
                    {freeTrialExpired ? "" : NO_CAMPAIGN ? (
                        <h4 className="text-center text-[24px] max-sm:text-base font-medium">Unlock Unlimited Market Insight With ZIK</h4>
                    ) : (
                        <h4 className="text-center text-base font-bold">Select your Marketplace</h4>
                    )}
                    <SelectMarketplace marketplace={activeTab} onMarketplaceChange={onMarketplaceChange} />
                    {freeTrialExpired ? (
                        <p className='text-base leading-[24px] font-medium text-center mx-auto max-lg:mx-[100px] max-sm:mx-0'>
                            Your 7-day free trial is over, but your success story doesn’t have to end here.<br className='max-lg:hidden' />
                            For a limited time, you can still unlock ZIK Analytics’ full suite of tools and{" "}
                            <span className='font-bold text-primaryPurple'>Save Up to {formatCurrency(activeTab === "ebay" ? "500" : "100")}!</span>
                        </p>
                    ) : (
                        <PlansDiscountTxt className='max-lg:px-[60px] max-sm:px-[0px]' activePlans={activeTab} period={plans.period} fromNoSub />
                    )}
                </div>
                {activeTab === "ebay" ? (
                    <div>
                        <PlanSwitch
                            activePlan={plans.period}
                            handlePlanChange={handlePlanChange}
                            longSwitchStyles="max-lg:px-[60px]  max-sm:px-[30px]"
                            showLifetime
                        />
                        <SlidedPlansWithComparison
                            {...planProps}
                            showComparisonOnMobile
                        />
                    </div>
                ) : <div className='w-full mt-4 max-sm:mt-0'>
                    <SlidedShopifyWithComparison
                        handleSelectPlan={handleSelectPlan}
                        isPayNow={shopifyUpgrade}
                        fromRegistration={!shopifyUpgrade}
                        showComparisonOnMobile
                        customHandleClick
                    />
                </div>}

                <Modal helpers={modal} maxWidth="max-w-[1200px]" cardClassName='overflow-y-auto max-h-[600px]'>
                    <SubscriptionPurchase selectedPlan={selectedPlan} isShopify={activeTab === "shopify"} hideAlreadyTrialWarning
                        isUpgrade={activeTab === "shopify" ? shopifyUpgrade : ebayUpgrade}
                        fromNoSubscription />
                </Modal >
                <div className='h-8' />
                <Toaster />
            </LoadingPage>
        </div>
    )
}

export default NoSubscription

