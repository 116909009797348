import { ReactComponent as Clock } from "assets/icons/clock.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder-icon.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import Button from "components/Button";
import Input from "components/Input";
import Loading from "components/Loading";
import Search from "components/Search";
import Select from "components/Select";
import SelectOption from "components/Select/Option";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import useFilters from "hooks/useFilters";
import useModal from "hooks/useModal";
import { FC, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useAddFolderMutation, useGetFoldersPreviewQuery } from "store/api/foldersApi";
import { Option } from "types/common";
import Modal from ".";

interface Props {
  selectedFolder: Option | null;
  onFolderSelect: (newVal: Option | null) => void;
  className?: string;
  btnClassName?: string;
  titleClassName?: string;
  selectFirstOption?: boolean;
  listWrapperClassName?: string;
  isOpen: boolean;
  cutTitle?: number;
  cutLongOption?: number
  hideFolderIcon?: boolean;
  type: "aliexpress" | "eBay";
  hideAddNewFolder?: boolean;
  addNewFolderBtnTitle?: string;
  preSelectedFolderId?: number;
  hideRecentFolders?: boolean;
}

const initialValues = {
  name: "",
  description: "",
};
const SelectFolder: FC<Props> = ({
  selectedFolder,
  onFolderSelect,
  hideAddNewFolder,
  listWrapperClassName,
  selectFirstOption,
  btnClassName,
  titleClassName,
  type,
  hideFolderIcon,
  addNewFolderBtnTitle,
  isOpen,
  cutLongOption,
  cutTitle,
  className,
  preSelectedFolderId,
  hideRecentFolders
}) => {
  const [defaultListId, setDefaultListId] = useState<number | undefined>(preSelectedFolderId)
  const helpers = useModal();
  const { delayedValue, immediateValue, onChange } = useDebaunceSearch();
  const modal = useModal();
  const [addFolder, { isLoading, isSuccess }] = useAddFolderMutation();
  const [error, setError] = useState(false);
  const { filters: fields, handleFilterChange: handleChange } =
    useFilters(initialValues);
  const dataFetchedRef = useRef(false);
  const marketplaceType = type === "aliexpress" ? 2 : 1;
  const { currentData, isFetching: folderListLoading } = useGetFoldersPreviewQuery({ type: marketplaceType, Search: delayedValue, FolderId: defaultListId }, {
    skip: !isOpen,
    refetchOnMountOrArgChange: true
  });
  const allFolders = currentData?.result.allFolders || []
  const recentFolders = currentData?.result.recentUsedFolders || []
  const hideRecent = immediateValue.length > 0 || hideRecentFolders;
  const showNoFolders = allFolders.length === 0
  useEffect(() => {
    if (currentData && selectFirstOption && !dataFetchedRef.current) {
      const listItem = allFolders[(allFolders[0].listID === 0 && allFolders.length > 2) ? 1 : 0];
      if (defaultListId !== undefined) {
        setDefaultListId(undefined);
      }
      onFolderSelect({
        text: listItem?.title,
        value: listItem?.listID?.toString(),
      });
      dataFetchedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Folder added Successfully");
      modal.close();
      handleChange("", "name");
      handleChange("", "description");

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      handleChange("Folder name already exist", "name");
      setTimeout(() => {
        setError(false)
        handleChange(fields.name, "name");
      }, 2000)
    }

  }, [error])


  const onAddFolder = async (e: React.FormEvent) => {
    e.preventDefault();
    if (fields.name === "") {
      toast.error("Please fill the Folder Name");
    }
    else if (fields.name.length > 30) {
      toast.error("Please provide max 30 characters for folder name")
    }
    else if (fields.description.length > 200) {
      toast.error("Please provide max 200 characters for folder description")
    }
    else {
      await addFolder({
        title: fields.name,
        description: fields.description,
        type: marketplaceType,
      }).unwrap().then((res) => {
        if (res?.result) {
          onFolderSelect({
            text: res?.result.title,
            value: res.result.listID.toString(),
          });
        }
        onChange("")
        dataFetchedRef.current = false
        helpers.close();
      }).catch((err) => {
        err.data === "folder with same name already exists." && toast.error("New folder couldn’t be created");
        setError(true);
      })


    }
  };
  const hasScrollbar = hideRecent ? allFolders.length > 6 : [...allFolders, ...recentFolders].length > 5
  const listItemClass = `!mb-[3px] !pl-2.5 !font-normal ${hasScrollbar ? "" : "mr-2.5"}`
  const onOptionClick = (newVal: string, text: string) => {
    helpers.close();
    onChange("");
    onFolderSelect(
      newVal
        ? {
          text,
          value: newVal,
        }
        : null
    );
  }
  return (
    <div className={`${className || ""} ]`}>
      <Select
        listWrapperClassName={`${listWrapperClassName} !py-2.5 !pl-[7px] pr-[0px]`}
        cutTitle={cutTitle}
        cutLongOption={cutLongOption}
        titleClassName={titleClassName}
        className="w-full"
        helpers={helpers}
        btnClassName={btnClassName}
        arrowWrapperClassName="!w-4 !h-4"
        handleChange={() => { }}
        value={selectedFolder?.value || ""}
        iconClassName="ml-[3.33px] mr-[8.67px] !w-[10px] !h-[7px]"
        Icon={hideFolderIcon ? undefined : FolderIcon}
        CustomOptions={
          <div className="flex flex-col gap-[3px] max-h-[350px] h-full">
            <Search
              search={immediateValue}
              hideClear
              labelClassName="!pl-[2px] !pr-1"
              handleChange={onChange}
              placeholder="Search"
              searchIconClassName="!w-3 !h-3 !ml-[0px]"
              searchIconWrapper="min-w-[20px] ml-[2.21px] h-5 flex justify-center items-center"
              className={hasScrollbar ? "pr-[15px]" : "pr-2.5"}
              inputClassName="!pl-2 pr-1"
            />
            {folderListLoading ?
              <div className="flex justify-center w-full h-12 items-center">
                <Loading fill="fill-primaryPurple" />
              </div> : showNoFolders ?
                <p className="text-xs py-2 px-2 text-grey400">No Folders found.</p> : (

                  <div className="overflow-y-auto scrollbar min-h-[32px]">
                    <div className={`${hideRecent ? "hidden" : ""}`}>
                      <p className="text-primaryPurple dark:text-lilac400 text-xs flex items-center h-8 gap-2 pl-2">
                        <Clock className="fill-primaryPurple dark:fill-lilac400" />
                        <span>
                          Recently Used Folders
                        </span>
                      </p>
                      {recentFolders.map((item) => (
                        <SelectOption
                          listItemClassName={listItemClass}
                          key={item.listID?.toString()}
                          item={{ text: item?.title, value: item?.listID?.toString() }}
                          handleOptionClick={onOptionClick} />
                      ))}
                      <div className="py-[3.25px]">
                        <div className="w-full h-[1.5px] bg-secondary rounded-full" />
                      </div>
                      <p className="text-primaryPurple dark:text-lilac400 text-xs flex items-center h-8 gap-2 px-2">
                        <FolderIcon className="fill-primaryPurple dark:fill-lilac400 w-2.5 h-[7px]" />
                        <span>
                          All Folders
                        </span>
                      </p>
                    </div>
                    {allFolders.map((item) => (
                      <SelectOption
                        listItemClassName={listItemClass}
                        key={item.listID?.toString()} item={{ text: item?.title, value: item?.listID?.toString() }}
                        isActive={item.listID.toString() === selectedFolder?.value}
                        handleOptionClick={onOptionClick} />
                    ))}
                  </div>
                )}
            {
              !hideAddNewFolder ? (
                <div className={hasScrollbar ? "pr-[15px]" : "pr-2.5"}>
                  <Button
                    title={addNewFolderBtnTitle || "New Folder"}
                    handleClick={modal.open}
                    color="smoothGreen"
                    height="h-9"
                    iconClassName="!w-3.5 !h-3.5"
                    Icon={Plus}
                  />
                </div>
              ) : (
                ""
              )
            }
          </div>
        }
        title={selectedFolder ? selectedFolder.text : "Select Folder"}
      />
      <Modal
        helpers={modal}
        cardClassName="py-7"
        maxWidth="max-w-[580px]"
        title="Add New Folder"
        titleClassName="text-xs font-bold mt-1"
        overlay
        overlayColor="rgba(37, 37, 37, 0.2)"
      >
        <form onSubmit={onAddFolder}>
          <div className="flex items-center justify-center gap-12 mb-8">
            <Input
              value={fields.name}
              className={`min-w-[15rem] ${error && "text-errorRed"}`}
              handleChange={(newVal) => handleChange(newVal, "name")}
              erase
              placeholder="Name"

            />
            <Input
              value={fields.description}
              className="min-w-[15rem]"
              handleChange={(newVal) => handleChange(newVal, "description")}
              erase
              placeholder="Description"
            />
          </div>
          <div className="flex justify-center">
            <Button
              title="New Folder"
              loading={isLoading}
              className="max-w-[11rem] w-full mx-auto"
              type="submit"
              color="smoothGreen"
              Icon={Plus}
              disabled={error}
            />
          </div>

        </form>
      </Modal>
    </div>
  );
};

export default SelectFolder;
