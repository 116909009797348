import Button from "components/Button";
import useAutoDsConnectFromItemFinder from "hooks/useAutoDsConnectFromItemFinder";
import { FC } from "react";
import { IntegrationStepTypes } from "./Index";

interface Props {
    onIntegrationChange: (step: IntegrationStepTypes) => void;
}
const AutoDsIntegration: FC<Props> = ({ onIntegrationChange }) => {
    const onAutoDsSuccess = () => onIntegrationChange("success")
    const autoDsConnect = useAutoDsConnectFromItemFinder(onAutoDsSuccess);

    return (
        <div className="text-center">
            <h6 className="font-bold text-[20px] mb-2">
                AutoDS Integration
            </h6>
            <p className="font-medium text-[20px] mb-8">
                Integrate AutoDS with ZIK for an automated dropshipping journey!
            </p>
            <Button title="Connect to AutoDS" height="h-[60px]" color="smoothGreen" titleClassName="!font-bold !text-base" className="max-w-[256px]" handleClick={autoDsConnect} />
        </div>
    )
}

export default AutoDsIntegration;