import { FC } from "react";


interface Props {
    className?: string;
    activePlans: "shopify" | "ebay";
    period?: string;
    showYearlyTxt?: boolean;
}
const NoCampaignDiscountTxt: FC<Props> = ({ className, period, activePlans, showYearlyTxt }) => {
    return (
        <div className={`text-[24px] max-sm:text-[20px] text-center font-medium ${!showYearlyTxt && activePlans === "ebay" && period === "yearly" ? "hidden" : ""} ${className}`}>
            {activePlans === "ebay" && period === "yearly" && showYearlyTxt ? "Unlock Unlimited Market Insights with ZIK" : (
                <span>
                    Start Now with Up to <span className="text-[#24921A] font-bold">50% Off</span>
                </span>
            )}
        </div>
    )
}

export default NoCampaignDiscountTxt;