import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image-icon.svg";
import { ReactComponent as Message } from "assets/icons/message.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { FC, useEffect, useState } from "react";
import "styles/ali-steps-loading.css";
import { ItemFinderMarketplace } from ".";

interface Props {
    similarLoading?: boolean;
    marketplace: ItemFinderMarketplace;
}
const steps = [
    {
        Icon: <Message />,
        title: "Sending Image",
        idx: 1
    },
    {
        Icon: <ImageIcon />,
        title: "Analyzing Image",
        idx: 2
    },
    {
        Icon: <Search className="fill-primaryPurple w-[14px] h-[14px]" />,
        title: "Looking for Products",
        idx: 3
    },
]
interface StepProps {
    icon: JSX.Element;
    title: string;
    idx: number;
    isLoading?: boolean;
    isSuccess?: boolean;
}
const Step: FC<StepProps> = ({ icon, title, idx, isLoading, isSuccess }) => {
    return (
        <div className="flex items-center gap-4 h-12">
            <div className="relative">
                <div className={`${isLoading ? "ali-steps-loading" : ""} w-8 rounded-full border-[4px] border-solid aspect-square ${isSuccess ? "border-smoothGreen" : "border-lilac400"}`} />
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    {isSuccess ? <div className="flex w-[17px] h-[17px] justify-center items-center bg-smoothGreen rounded-full">
                        <Checkmark className="fill-darkBlue w-[9px] h-2" />
                    </div> : icon}
                </div>
            </div>
            <p className="text-xs">{title}</p>
        </div>
    )
}

const AliItemSteps: FC<Props> = ({ similarLoading, marketplace }) => {
    const [loadingStep, setLoadingStep] = useState<1 | 2 | 3 | null>(1);
    const [successStep, setSuccessStep] = useState<1 | 2 | 3 | null>(null)

    useEffect(() => {
        let stepOneTimeout: ReturnType<typeof setTimeout>;
        let stepTwoTimeout: ReturnType<typeof setTimeout>;
        if (similarLoading) {
            setSuccessStep(null);
            setLoadingStep(1);
            stepOneTimeout = setTimeout(() => {
                setLoadingStep(2);
                setSuccessStep(1);
            }, 500);
            stepTwoTimeout = setTimeout(() => {
                setLoadingStep(3);
                setSuccessStep(2);
            }, 1000);
        } else {
            if (loadingStep !== null) {
                setSuccessStep(3);
            }
            setLoadingStep(null);
        }
        return () => {
            clearTimeout(stepOneTimeout);
            clearTimeout(stepTwoTimeout);
        };
    }, [similarLoading, marketplace])

    return (
        <div className="flex flex-col mb-[38px]">
            {steps.map((item) => <Step title={item.title} isSuccess={successStep !== null && successStep >= item.idx} icon={item.Icon} idx={item.idx} key={item.title} isLoading={loadingStep && similarLoading ? (loadingStep === item.idx) : false} />)}
        </div>
    )
}

export default AliItemSteps