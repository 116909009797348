
import { ReactComponent as Prime } from "assets/icons/prime.svg";
import { ReactComponent as Rating } from "assets/icons/rating.svg";
import { FC } from "react";
import { formatNumber } from "utils/formatCurrency";

const ratingClassName = "fill-yellow "

interface Props {
    totalReviews?: number | null;
    rating?: number | null;
    isPrime?: boolean | null
}
const AmazonRatings: FC<Props> = ({ totalReviews, rating, isPrime }) => {
    const ratingToShow = rating || 0;
    const totReviews = totalReviews || 0
    const calcRating = (curr: number) => `${curr > ratingToShow ? "fill-secondary" : "fill-yellow"} w-2.5 h-[9px]`
    return (
        <div>
            <div className="flex justify-between text-[#1B63BA] mb-[9px]">
                <div className="flex items-center gap-1">
                    <p className="text-[10px]">{ratingToShow}</p>
                    <div className="flex gap-[5px]">
                        <Rating className={calcRating(1)} />
                        <Rating className={calcRating(2)} />
                        <Rating className={calcRating(3)} />
                        <Rating className={calcRating(4)} />
                        <Rating className={calcRating(5)} />
                    </div>
                </div>
                <p className="text-[10px]">
                    {formatNumber(totReviews)} rating{totReviews > 1 ? "s" : ""}
                </p>
            </div>
            {isPrime ? (
                <Prime />
            ) : ""}
        </div>
    )
}

export default AmazonRatings