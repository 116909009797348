import Loading from "components/Loading";
import { FC, ReactNode } from "react";
import { ItemFinderMarketplace } from "..";
import AddItemByAsinOrId from "../AddItemByAsinOrId";
import { SimilarItemType } from "../SimilarItem";


interface Props {
    isLoading?: boolean;
    length: number;
    children: ReactNode;
    isAliExpress?: boolean;
    marketplace?: ItemFinderMarketplace;
    selectedItemId?: string;
    profit?: string;
    onSimilarItemChange?: (itm: SimilarItemType) => void;
}

const SimilarItemsWrapper: FC<Props> = ({ isLoading, length, selectedItemId, profit, onSimilarItemChange, children, isAliExpress, marketplace }) => {
    const noItems = length === 0;
    const showTwoCols = length > 1;
    const bg = "bg-neutral2 dark:bg-grey800";
    const isAmazon = marketplace === "US"
    const isWal = marketplace === "WAL";
    const showAsinInput = (isWal || isAmazon) && selectedItemId

    return isLoading ? (
        <div className={`flex justify-center items-center h-[86px] rounded-b-[16px] ${bg}`}>
            <Loading fill="fill-primaryPurple dark:fill-purple500" width={32} height={32} />
        </div>
    ) : noItems ? (
        <div className={`text-center h-[86px] flex justify-center rounded-b-[16px] px-6 ${showAsinInput ? "flex-col items-start" : "items-center"} ${bg}`}>
            <p className="text-xs text-center w-full">
                No similar item found
            </p>
            {showAsinInput ? (
                <AddItemByAsinOrId isWal={isWal} itemId={selectedItemId} onSimilarItemChange={onSimilarItemChange} profit={profit || "0"} wrapperClassName="!mb-0" />
            ) : ""}
        </div>
    ) : (<div className={`overflow-y-auto overflow-x-hidden white-scrollbar rounded-b-[16px] ${length > 1 ? `${isAliExpress ? "max-h-[calc(100vh-192px)]" : "max-h-[calc(100vh-146px)]"} h-fit` : ""} ${bg}`}>
        <div className={`w-full ${showTwoCols ? "grid grid-cols-2 gap-x-2 gap-y-2.5 p-2 pr-1" : "p-2"}`}>
            {children}
        </div>
    </div>)
}

export default SimilarItemsWrapper;