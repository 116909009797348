import { ReactComponent as Info } from "assets/icons/info.svg";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useProPlusLimitsQuery } from "store/api/zikProApi";
import { onSelectPlan } from "store/slices/planSlice";
import { PLAN_NAME, PLAN_TYPE } from "types/plans";
import { makeQueryParams } from "utils/makeQueryParams";
import { getPlanDetails } from "utils/planUtils";


const LimitReachedInfoPE = () => {
    const user = useDecodeToken();
    const { data: limitsData } = useProPlusLimitsQuery("EbayPe");
    const limits = limitsData?.result
    const scansLeft = limits?.scansLeft || 0;
    const isLimitReached = limits?.limitReached || false;
    const searchLimit = limits?.limit || 5
    const isTrial = /^true$/i.test(user.active_trail);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const plan = user.package;
    const isFreeTrial = user.free_trial === "True"
    const isPro = plan === PLAN_TYPE.PRO;
    const showGreen = isTrial ? (scansLeft > 3) : isPro ? (scansLeft > 10) : (scansLeft > 50);
    const showRed = isTrial ? (scansLeft < 2) : isPro ? (scansLeft < 4) : (scansLeft < 20);
    const onUpgrade = () => {
        const setPlans = {
            [PLAN_NAME.ProMonthly]: PLAN_NAME.ProPlusMonthly,
            [PLAN_NAME.ProYearly]: PLAN_NAME.ProPlusYearly,
            [PLAN_NAME.ProLifetime]: PLAN_NAME.ProPlusLifetime,
            [PLAN_NAME.ProPlusMonthly]: PLAN_NAME.EnterpriseMonthly,
            [PLAN_NAME.ProPlusYearly]: PLAN_NAME.EnterpriseYearly,
            [PLAN_NAME.ProPlusLifetime]: PLAN_NAME.EnterpriseLifetime
        }
        // @ts-ignore
        const upgradePlan = isTrial ? user.package_name : setPlans[user.package_name]
        const plan = getPlanDetails(upgradePlan);
        dispatch(onSelectPlan({
            type: upgradePlan,
            planName: plan?.planName || "",
            planPrice: plan?.planPrice || 0,
            skip: false
        }));
        navigate(makeQueryParams(PATH.managementPanel[isFreeTrial ? "subscriptionPurchase" : "subscriptionUpgrade"], { type: upgradePlan, from: "PE" }));
    }


    return (
        <div className={`w-full flex items-center justify-end max-xl:flex-col max-xl:items-start ${isTrial ? "gap-4" : "gap-6"}`}>
            <div className="flex gap-1">
                <div className={`h-11 rounded-[8px] flex items-center text-center px-2 text-sm ${showGreen ? "bg-[#9BFBCA]" : showRed ? "bg-[#FF8383]" : "bg-[#F7DA63]"} `}>
                    {scansLeft}/{searchLimit} {isTrial ? "Trial" : ""} Searches Left
                </div>
                {isTrial ? (
                    <div className="h-full">
                        <Info className={"info-icon"} data-tooltip-id="limit-trial-info" />
                        <Tooltip title={<span>
                            As a trial user, your searches are limited to 5 per month.
                            Upgrade your subscription today to unlock the full potential of your plan: <br />
                            <ul className="list-disc pl-4">
                                <li>Pro Plan: 20 searches per month</li>
                                <li>Pro+ Plan: 100 searches per month</li>
                                <li>Enterprise Plan: Unlimited searches per month</li>
                            </ul>

                        </span>} maxWidth="max-w-[280px]" anchorId="limit-trial-info" />
                    </div>)
                    : ""}
            </div>
            {isLimitReached ? (
                <Button title={`Upgrade ${isTrial ? "Now for more!" : isPro ? "for more Searches!" : "for Unlimited Searches!"}`} handleClick={onUpgrade}
                    titleClassName={"!font-bold group-hover:text-darkBlue dark:text-darkBlue !text-base"}
                    color="smoothGreen"
                    className={`${isTrial ? "max-w-[252px]" : isPro ? "max-w-[289px]" : "max-w-[327px]"} dark:bg-deepSpace900 bg-darkBlue text-smoothGreen !border-darkBlue hover:!border-smoothGreen dark:bg-lilac400 dark:!border-lilac400 dark:hover:!border-paradiseBlue`}
                    height="h-11"
                />) : ""}
        </div>

    )
}

export default LimitReachedInfoPE