import { createApi } from "@reduxjs/toolkit/query/react";
import { queryTags } from "store/api/queryTags";
import {
  ExportProductsReq,
  ExportProductsRes,
  LoadScanHistoryReq,
  LoadScanHistoryRes,
  UplaodProductsRes,
  UploadFolderItemsReq,
  UploadFolderItemsRes,
  UploadProductsReq,
  UploadSingleProductReq,
  UploadSingleProductRes,
} from "types/services/productMangement";
import {
  ExportFolderItemsReq,
  ExportFolderItemsRes,
} from "../../types/services/productMangement";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const productManagementApi = createApi({
  reducerPath: "uploadHistoryApi",
  baseQuery: (args: any, api: any, extraOptions: any) =>
    baseQuery(args, api, extraOptions, 300000),
  tagTypes: [queryTags.productMangement],
  endpoints: (builder) => ({
    loadScanHistory: builder.query<LoadScanHistoryRes, LoadScanHistoryReq>({
      query: (params) => ({
        url: queryKeys.productManagement.loadHistory,
        params,
        method: "GET",
      }),
      providesTags: [queryTags.productMangement],
    }),
    uploadProducts: builder.mutation<UplaodProductsRes, UploadProductsReq>({
      query: ({ pageType, ...body }) => ({
        url: queryKeys.productManagement.uploadProducts,
        params: { pageType },
        body: body,
        method: "POST",
      }),
      invalidatesTags: [queryTags.productMangement],
    }),
    uploadSingleProduct: builder.mutation<
      UploadSingleProductRes,
      UploadSingleProductReq
    >({
      query: (body) => ({
        url: queryKeys.productManagement.uploadSingleProduct,
        body,
        method: "POST",
      }),
      invalidatesTags: [queryTags.productMangement],
    }),
    uploadFolderItems: builder.mutation<
      UploadFolderItemsRes,
      UploadFolderItemsReq
    >({
      query: (body) => ({
        url: queryKeys.productManagement.uploadFolderItems,
        body: body,
        method: "POST",
      }),
      invalidatesTags: [queryTags.productMangement],
    }),
    exportProducts: builder.mutation<ExportProductsRes, ExportProductsReq>({
      query: ({ type, ...body }) => ({
        url: queryKeys.productManagement.exportProducts,
        params: { type },
        body: body,
        method: "POST",
      }),
    }),
    exportFolderItems: builder.mutation<
      ExportFolderItemsRes,
      ExportFolderItemsReq
    >({
      query: (body) => ({
        url: queryKeys.productManagement.exportFolderItems,
        body: body,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyLoadScanHistoryQuery,
  useLoadScanHistoryQuery,
  useUploadFolderItemsMutation,
  useUploadProductsMutation,
  useUploadSingleProductMutation,
  useExportFolderItemsMutation,
  useExportProductsMutation,
} = productManagementApi;
