import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ProductExplorer } from "assets/icons/product-exlorer.svg";
import { ReactComponent as StoreFinder } from "assets/icons/store-finder-shopify.svg";
import {
  MONTHLY_HAS_DISCOUNT,
  YEARLY_HAS_DISCOUNT,
} from "config/campaign-settings";
import { ShopifyPlanList } from "types/plans";

export const shopifyPlans: ShopifyPlanList[] = [
  {
    type: "ShopProM",
    name: "Monthly",
    id: "monthly",
    skipAndSave: 0,
    isDiscount: MONTHLY_HAS_DISCOUNT,
    description: "Scan, Search and Track as much as you want!",
    price: 29.99,
    discountedPrice: 14.99,
    isYearly: false,
  },
  {
    type: "ShopProY",
    name: "Yearly",
    id: "yearly",
    isDiscount: YEARLY_HAS_DISCOUNT,
    skipAndSave: 15,
    description: "Scan, Search and Track as much as you want!",
    price: 24.99,
    discountedPrice: 16.65,
    isYearly: true,
  },
];

export const shopifyPlanDescription = [
  {
    title: "Market Insights",
    desc: "Get instant access to winning products, top stores, and upcoming trends all-in-one dashboard",
    id: "01",
    Icon: Logo,
    width: 17.5,
    height: 17.5,
  },
  {
    title: "Sales Tracker",
    desc: "Conduct limitless Shopify competitor research by simply copying and pasting store URLs",
    id: "02",
    Icon: CompetitorResearch,
    width: 15,
    height: 16.25,
  },
  {
    title: "Product Explorer",
    desc: "Instantly uncover winning products with a quick search",
    id: "03",
    Icon: ProductExplorer,
    width: 17.5,
    height: 18.75,
  },
  {
    title: "Store Finder",
    desc: "Identify leading Shopify stores and reveal their best-selling products",
    id: "04",
    Icon: StoreFinder,
    width: 16.58335,
    height: 19.13465,
  },
];

export const shopifyPlanComparison = [
  {
    Icon: Logo,
    icon: "Logo",
    title: "Market Insights",
    description: "Find the best products to maximize sales.",
    monthly: true,
    yearly: true,
    iconClassName: "w-[19px] h-[19px]",
  },
  {
    Icon: CompetitorResearch,
    icon: "CompetitorResearch",
    title: "Sales Tracker",
    description: "Get current intel on competitors products and strategies.",
    monthly: true,
    yearly: true,
    iconClassName: "w-4 h-[17px]",
  },
  {
    Icon: ProductExplorer,
    icon: "ProductExplorer",
    title: "Product Explorer",
    description:
      "Know and use all the keywords currently driving traffic + sales.",
    monthly: true,
    yearly: true,
    iconClassName: "w-[19px] h-5",
  },
  {
    Icon: StoreFinder,
    icon: "StoreFinder",
    title: "Store Finder",
    description:
      "Identify leading Shopify stores and reveal their best-selling products",
    monthly: true,
    yearly: true,
    iconClassName: "w-4.5 h-5",
  },
];
