import { FC, useEffect, useState } from "react";
import { formatNumber } from "utils/formatCurrency";

interface Props {
    deadline: Date;
    daysNumColor?: string;
    daysTitleColor?: string;
    periodTitleColor?: string;
    dotColor?: string;
    hideDays?: boolean;
    periodAmountStyle?: string;
}

interface CountdownTimeLeft {
    days: number;
    hours: number;
    mins: number;
    secs: number;
}
const INITIAL_TIME = {
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
};

const TimeCounter: FC<Props> = ({ deadline, daysNumColor, daysTitleColor, periodAmountStyle, periodTitleColor = "text-grey900 dark:text-grey100", dotColor = "bg-electricBlue", hideDays }) => {
    const [timeLeft, setTimeLeft] = useState<CountdownTimeLeft>(INITIAL_TIME);
    const calculateTimeLeft = () => {
        const current = new Date();
        const difference = deadline.getTime() - current.getTime();
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const mins = Math.floor((difference / 1000 / 60) % 60);
        const secs = Math.floor((difference / 1000) % 60);

        return { days, hours, mins, secs };
    };
    const { days, hours, mins, secs } = timeLeft;


    useEffect(() => {
        const update = () => setTimeLeft(calculateTimeLeft());
        update();

        const timer = setInterval(() => {
            update();
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const twoDots = "flex flex-col justify-center gap-[3px] h-[18px]";
    const dot = `w-1 h-1 rounded-full ${dotColor}`;
    const period = "flex w-[26px] flex-col items-center text-center";
    const periodAmount = `text-base leading-[18px] font-normal mb-1 ${periodAmountStyle}`
    const periodTitle = `text-[8px] leading-[12px] font-[300] ${periodTitleColor}`

    return (
        <div
            className="flex max-w-[116px] justify-start w-fit rounded-[8px]"
        >
            {hideDays ? "" : (
                <div className={period}>
                    <span
                        className={`${periodAmount} ${daysNumColor}`}
                    >
                        {formatNumber(days)}
                    </span>
                    <span className={`${periodTitle} ${daysTitleColor}`}>days</span>
                </div>
            )}
            {hideDays ? "" : (
                <div className={twoDots}>
                    <div className={dot} />
                    <div className={dot} />
                </div>
            )}
            <div className={period}>
                <span
                    className={periodAmount}
                >
                    {formatNumber(hours)}
                </span>
                <span className={periodTitle}>hours</span>
            </div>
            <div className={twoDots}>
                <div className={dot} />
                <div className={dot} />
            </div>
            <div className={period}>
                <span
                    className={periodAmount}
                >
                    {formatNumber(mins)}
                </span>
                <span className={periodTitle}>min.</span>
            </div>
            <div className={twoDots}>
                <div className={dot} />
                <div className={dot} />
            </div>
            <div className={period}>
                <span
                    className={periodAmount}
                >
                    {formatNumber(secs)}
                </span>
                <span className={periodTitle}>sec.</span>
            </div>
        </div>
    )
}

export default TimeCounter