import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import NoImage from "assets/icons/no-image";
import Button from "components/Button";
import ProductNameCell from "components/ProductNameCell";
import { FC, useState } from "react";
import { useSelectedBulkStoreQuery } from "store/api/quickSettingsApi";
import { CURRENCY } from "types/currency";
import { BabaDetails, IconFinderItem } from "types/services/iconFinder";
import { CURRENCY_SIGNS, formatCurrency } from "utils/formatCurrency";
import { ItemFinderMarketplace, SaveItem } from ".";
import AddItemByAsinOrId from "./AddItemByAsinOrId";
import AmazonRatings from "./AmazonRatings";
import AliBabaDetails from "./BabaDetails";

export interface SimilarItemType {
    id: string,
    outOfStock: boolean,
    pictureUrl: string,
    price: number,
    similiar: boolean,
    title: string,
    url: string,
    amazonsite?: string | null;
    profit: string;
    rating?: number | null;
    totalReviews?: number | null;
    isPrime?: boolean | null;
}
interface Props {
    pictureUrl: string;
    title: string
    currency: CURRENCY_SIGNS | undefined
    price: number
    profit: string;
    isSaved?: boolean;
    savedLoading?: boolean;
    url: string;
    babaDetails?: BabaDetails;
    marketplace: ItemFinderMarketplace;
    selectedItem: IconFinderItem;
    onSimilarItemChange?: (itm: SimilarItemType) => void;
    onSaveItem?: (itm: SaveItem) => void;
    id?: string;
    saveLoading?: boolean;
    totalReviews?: number | null;
    rating?: number | null;
    isPrime?: boolean | null;
    isShopify?: boolean;
    handleIntegration?: (title: string, id: string) => void
}


const SimilarItem: FC<Props> = ({ pictureUrl, babaDetails, handleIntegration, isShopify, isSaved, savedLoading, title, rating, isPrime, totalReviews, currency, price, profit, url, id, marketplace, selectedItem, onSimilarItemChange, onSaveItem, saveLoading }) => {
    const [imageLoadError, setImageLoadError] = useState(false);
    const [saveItemId, setSaveItemId] = useState("");
    const isWal = marketplace === "WAL";
    const isBaba = marketplace === "Baba";
    const isCjd = marketplace === "Cjd"
    const isAmazon = marketplace === "US"
    const isAli = marketplace === "Ali";
    const secondaryBorder = "bg-secondary dark:bg-deepSpace900 w-full rounded-full min-h-[2px]";
    const { data: selectedStore, isFetching: listerLoading } = useSelectedBulkStoreQuery();
    const hasStore = (selectedStore?.result.bulkStoreId || 0) > 0
    const lister = selectedStore?.result.type
    const highlightSaved = marketplace === "Ali" && isSaved
    const handleAddToStoreClick = () => {
        if (handleIntegration) {
            handleIntegration(title, id || "")
        }
    }
    const handleSaveClick = () => {
        if (onSaveItem && !isSaved && !saveLoading) {
            setSaveItemId(id || "");
            onSaveItem({
                asin: id || "",
                title,
                price,
                pictureUrl,
                profit,
            })
        }
    }
    return (
        <div className={`flex flex-col justify-between w-full p-[14px] rounded-[10px] border-2 ${highlightSaved ? "border-smoothGreen dark:border-purple500" : "border-secondary dark:border-grey600"}  text-darkBlue bg-white dark:bg-black900 dark:text-grey100`}>
            <div className={`flex gap-4 ${isCjd || isShopify ? "" : isAli ? "mb-[27px]" : "mb-2"}`}>
                {imageLoadError ? (
                    <div className="w-1/2 h-[auto] max-h-[160px] shadow-shadow1 flex items-center justify-center rounded-[7px] bg-neutral1 dark:bg-grey800 text-grey200 dark:text-grey700">
                        <NoImage />
                    </div>
                ) : (
                    <div className="w-1/2 flex justify-center">
                        <img
                            src={pictureUrl} alt={"Product"}
                            className={`w-[auto] max-h-[180px] rounded-[7px] shadow-shadow1`}
                            referrerPolicy="no-referrer"
                            onError={() => pictureUrl !== "" && setImageLoadError(true)}
                        />
                    </div>

                )}
                <div className="w-1/2">
                    <ProductNameCell name={title} checkKeywords url={url} titleClassName="text-xs" className="mb-2" noIcons />
                    <div className={secondaryBorder} />
                    <div className="flex flex-col text-sm py-2 gap-2 text-xs">
                        <div className="w-full flex justify-between">
                            <p>Price</p>
                            <p className="font-bold">
                                {formatCurrency(
                                    price,
                                    CURRENCY.none,
                                    currency || "$"
                                )}
                            </p>
                        </div>
                        {isAli || isBaba ? <div className={secondaryBorder} /> : ""}
                        <div className={`w-full flex justify-between ${isAli || isBaba ? "bg-neutral2 dark:bg-deepSpace900 p-1 rounded-[4px]" : ""}`}>
                            <p>Profit</p>
                            <p className="font-bold">{formatCurrency(String(profit))}</p>
                        </div>
                    </div>
                    {isAli || isBaba ? "" :
                        <div className={`${secondaryBorder} mb-2`} />
                    }
                    {isAmazon ? (
                        <AmazonRatings totalReviews={totalReviews} isPrime={isPrime} rating={rating} />
                    ) : ""}
                </div>
            </div>
            {isWal || isAmazon ? (
                <AddItemByAsinOrId isWal={isWal} itemId={selectedItem.id.toString()} onSimilarItemChange={onSimilarItemChange} profit={profit} />
            ) : babaDetails ? (
                <AliBabaDetails details={babaDetails} />
            ) : ""}
            {isBaba || isCjd || isShopify ? <div className="w-full" /> : (
                <div className="flex gap-4">
                    <Button title={!hasStore ? "Add to Store" : lister === 1 ? "Add to SaleFreaks" : "Add to AutoDS"} loading={listerLoading} height="h-11" color="smoothGreen" titleClassName="!text-base" className={`dark:bg-deepSpace900 max-w-[177px]`} handleClick={handleAddToStoreClick} />
                    <Button title={isSaved ? "Saved" : "Save to Folder"} Icon={isSaved ? Checkmark : undefined} height="h-11"
                        iconClassName="w-2.5 h-2 group-hover:!fill-darkBlue dark:group-hover:!fill-lilac400"
                        className={`border-none ${isSaved ? "bg-neutral2 hover:!bg-neutral2 hover:!text-darkBlue dark:bg-deepSpace900 dark:hover:!bg-deepSpace900 dark:hover:!text-lilac400 cursor-default" : ""} ${isAli ? "max-w-[127px]" : "max-w-[177px]"}`}
                        titleClassName="!text-base" handleClick={handleSaveClick} loading={saveLoading && saveItemId === id || savedLoading}
                    />
                </div>
            )}
        </div>
    )
}

export default SimilarItem