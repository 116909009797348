import { ReactComponent as RedOpen } from "assets/icons/boxes/red-open.svg";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Tooltip from "components/Tooltip";
import { FC } from "react";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import findBoxIcon from "utils/findBoxIcon";
import { ItemFinderMarketplace } from "..";

interface Props {
    title: string;
    id: string;
    active: ItemFinderMarketplace;
    onChange: (tab: ItemFinderMarketplace) => void;
    productType?: "similar" | "exact" | "noProduct";
}

const Tab: FC<Props> = ({ id, title, active, onChange, productType }) => {
    const findIcon = findBoxIcon(id);
    const { data: settings } = useGetSettingsQuery();
    const siteFromSettings = settings?.result.amazonsite;
    const tabTooltipAnchor = `item-finder-tooltip-tabs-${id}`
    const isActive = active === id;

    const isByImage = id === "Ali" || id === "Baba" || id === "Cjd";
    const isSimilar = productType === "similar";
    const isExact = productType === "exact" && siteFromSettings === id;
    const tabTitle = isByImage ? `Find Product by image on ${title}` :
        isExact ? `Exact product found  on ${title}` : productType === "similar" ? `Similar product found on ${title}` : ""

    return (
        <button className={`transition-all border-b-[0px]  border-2 border-secondary bg-white rounded-t-[8px] gap-[3px] dark:bg-deepSpace800
       ${isActive ? "dark:border-grey100" : "dark:border-grey600 hover:bg-primaryPurple hover:text-white group dark:hover:bg-purple500 dark:hover:text-grey100"}`}
            data-tooltip-id={tabTooltipAnchor}
            onClick={() => onChange(id as ItemFinderMarketplace)}>
            <span className={`h-fit flex items-center text-center p-2 cursor-pointer ${isActive ? "text-sm pb-2.5 font-medium" : "text-xs opacity-30 hover:opacity-100 pt-1.5 font-normal"}`}>
                <span className={`flex justify-center items-center ${isActive ? "w-6 h-6" : "w-[18px] h-[18px]"}`}>
                    <findIcon.icon className={` ${isActive ? "h-[15px]" : "h-[11px]"} fill-darkBlue dark:fill-grey100
                        group-hover:fill-white`} />
                </span>

                <span className="block">
                    {title}
                </span>
            </span>
            {tabTitle && <Tooltip anchorId={tabTooltipAnchor} className="!px-2 !py-2" title={<span className="flex items-center max-w-[160px] block">
                <span className="relative block">
                    {isExact && !isByImage ? (
                        <span className={`absolute bg-spaceGreen top-[-4px]  ${id === "WAL" ? "right-[1.5px]" : "right-[4px]"} w-[6px] h-[6px] aspect-square block flex justify-center items-center rounded-full`}>
                            <Checkmark className="fill-darkBlue w-[3px] h-[auto]" />
                        </span>
                    ) : ""}
                    {!isExact && !isSimilar && !isByImage ? (<RedOpen className="fill-errorRed w-[13px] h-2.5" />) : (
                        <findIcon.icon className="min-w-[11px] h-[11px] fill-white" />
                    )}
                </span>
                <span className="text-left block text-[10px] ml-[7px] ">{tabTitle}</span>
            </span>} />}
        </button >
    )
}

export default Tab;