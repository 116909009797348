function getSessionID() {
  const pattern =
    /_ga_NZCD8QGTXY=GS1\.1\.(\d+)\.(\d+)\.(\d+)\.(\d+)\.(\d+)\.(\d+)/;
  const match = document.cookie.match(pattern);
  if (match && match[1]) {
    return match[1].toString();
  } else {
    return null;
  }
}

export default getSessionID;
