import SlidedPlansWithComparison from "components/Plans/SlidedPlansWithComparison";
import { DEFAULT_PLAN_PERIOD } from "config/campaign-settings";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import usePlans from "hooks/usePlans";
import LoadingPage from "pages/Loading";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useEbaySubCountQuery } from "store/api/quickSettingsApi";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { makeQueryParams } from "utils/makeQueryParams";

interface Props {
    isPayNow?: boolean;
}
const EbayPlans: FC<Props> = ({ isPayNow }) => {
    const { data: ebaySubCount, isFetching } = useEbaySubCountQuery();
    const user = useDecodeToken();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const noEbayPlan = ebaySubCount?.result === 0;
    const ebayAlreadyTrial = user.already_trial === "1"
    const ebayIsPayNow = ebayAlreadyTrial || !noEbayPlan || isPayNow
    const { plans } = usePlans(DEFAULT_PLAN_PERIOD, false, !ebayIsPayNow);
    const handleSelectPlan = (newVal: TSelectedPlan) => {
        dispatch(onSelectPlan(newVal));
        navigate(makeQueryParams(PATH.managementPanel.subscriptionPurchase, {
            type: newVal.type,
            ...(newVal.skip && { skip: true })
        }));
    };

    return (
        <LoadingPage loading={isFetching}>
            <SlidedPlansWithComparison
                plans={plans}
                isPayNow={ebayIsPayNow}
                fromRegistration={!ebayIsPayNow}
                handleSelectPlan={handleSelectPlan}
            />
        </LoadingPage>

    )
}

export default EbayPlans;