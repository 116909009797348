import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { BaseQueryApi, fetchBaseQuery } from "@reduxjs/toolkit/query";
import { BASE_URL } from "config/config";
import { RootState } from "store";
import { GetAccessToken } from "utils/localStorage";
import removeLocalStorageItemsOnLogout from "utils/removeLocStoreItemsOnLogout";

export type BaseQueryFn<
  Args = any,
  Result = unknown,
  Error = unknown,
  DefinitionExtraOptions = {},
  Meta = {}
> = (
  args: Args,
  api: BaseQueryApi,
  extraOptions: DefinitionExtraOptions,
  timeout?: number
) => MaybePromise<QueryReturnValue<Result, Error, Meta>>;

const baseQueryPrepareHeaders = (timeout: number) => {
  return fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken =
        (getState() as RootState).user.result?.accessToken || GetAccessToken();

      if (accessToken) {
        headers.set("authorization", `Bearer ${accessToken}`);
      }

      return headers;
    },
    timeout: timeout || 100000,
    credentials: "include",
  });
};

const baseQuery: BaseQueryFn = async (
  args,
  api,
  extraOptions,
  timeout = 100000
) => {
  const baseQueryInstance = baseQueryPrepareHeaders(timeout);

  let result = await baseQueryInstance(args, api, extraOptions);
  const { error } = result;

  if ((error && error.status === 401) || error?.status === 403) {
    if (error && error.status === 401) {
      removeLocalStorageItemsOnLogout();
      window.location.href = "/login";
    }
  }

  return result;
};

export default baseQuery;
