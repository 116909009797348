import Card from "components/Card";
import ShopifyMarketplaceIcon from "components/MarketplaceIcon/Shopify";
import MySubscriptionRight from "components/Plans/ManagePayments/MySubscriptionRight";
import Toaster from "components/Toast";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import LoadingPage from "pages/Loading";
import CancelationFunnel from "pages/ManagementPanel/components/CancelationFunnel";
import PaymentHistory from "pages/ManagementPanel/lists/ManagePayments/PaymentHistory";
import { Link, useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import { formatDate } from "utils/formatTime";
import ShopifyBillingHeader from "./components/BillingHeader";

const ShopifyBilling = () => {
    const user = useDecodeToken();
    const currentPlan = user.shopify_package_name === "ShopProY" ? "yearly" : user.shopify_package_name === "ShopProM" ? "monthly" : "";
    const isMonthly = currentPlan === "monthly"
    const cancelModal = useModal()
    const { data, isFetching } = useGetUserPlanQuery(null);
    const shopifyPlan = data?.result.find((item) => item.isShopify);
    const paymentMethod = shopifyPlan?.paymentMethod.replace("_", " ").toLowerCase()
    const isActivePlan = !shopifyPlan?.isCanceled && currentPlan !== "";
    const isTrial = /^true$/i.test(user.shopify_active_trail);
    const renewalDate = shopifyPlan?.nextPaymentDate || "N/A";
    const navigate = useNavigate();
    const cardStyles = `w-full h-[216px] max-sm:h-[auto] border-2 border-solid border-secondary p-5.5`

    return (
        <LoadingPage loading={isFetching}>
            <ShopifyBillingHeader />
            <div className="flex items-center gap-3 mb-5 max-xl:flex-col">
                <Card className={cardStyles} bg={`${isActivePlan ? "bg-white dark:bg-black900 dark:text-grey100 dark:border-grey700" : " dark:border-grey800 bg-neutral2 dark:bg-grey800 dark:text-grey600 text-grey300"}`}>
                    <div className="flex justify-between mb-6">
                        <div className="">
                            <h5 className="mb-6 text-darkBlue dark:text-grey100">My Subscription</h5>
                            <h5 className={`text-[28px] font-bold leading-[36px] capitalize ${isActivePlan ? "text-darkBlue dark:text-grey100" : "text-grey300 dark:text-grey600 "}`}>{currentPlan || "-"}</h5>
                        </div>
                        <ShopifyMarketplaceIcon isDisabled={!isActivePlan} />
                    </div>

                    <div className="flex justify-between items-end max-sm:flex-col max-sm:items-start max-sm:gap-5">
                        <div className="flex flex-col font-bold text-sm">
                            <p className="">Renewal date: <span className="font-normal">{formatDate(renewalDate)}</span></p>
                            <p className="">Payment method: <span className="font-normal capitalize">{paymentMethod}</span></p>
                            <p className="">Subscription status: <span className={`font-normal ${isActivePlan ? "text-smoothGreen" : "text-errorRed"}`}>{isActivePlan ? "Active" : "Canceled"}</span></p>
                        </div>
                        {isActivePlan ? (
                            <div className="flex justify-end gap-[26px] max-sm:flex-col max-sm:justify-start">
                                <button className="dark:hover:text-grey100 hover:bg-primaryPurple rounded-full text-sm hover:text-white dark:hover:bg-purple500 h-9 text-neutral3 min-w-[80px]" onClick={cancelModal.open}>Cancel</button>
                                <Link to={PATH.shopify.plans} className="py-2 px-4 hover:bg-primaryPurple rounded-full text-sm hover:text-white dark:hover:bg-purple500 dark:hover:text-lilac400" >Manage Subscription</Link>
                            </div>
                        ) : ""}
                    </div>

                </Card>
                <MySubscriptionRight
                    btnTitle={isTrial ? "Change Now" : isActivePlan ? "Upgrade Now" : "Subscribe Now"}
                    redirUrl={isActivePlan ? PATH.shopify.upgrade : PATH.shopify.plans}
                    hideCard={!isMonthly || (!isMonthly && isActivePlan)}
                    isShopify
                />
            </div>
            <PaymentHistory isShopify />

            {isActivePlan ?
                <CancelationFunnel helpers={cancelModal} onUpgrade={() => navigate(PATH.shopify.plans)} isShopify />
                : ""}
            <Toaster />
        </LoadingPage>
    )
}

export default ShopifyBilling;