import { FC, useEffect, useState } from "react";
import { useGetSavedProductsQuery } from "store/api/foldersApi";
import { useFindAliItemsQuery, useLazyGetAliseekProductQuery } from "store/api/iconFinderApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { IconFinderItem, MarketPlaceSimilarItemsList } from "types/services/iconFinder";
import { calculateAliexpressProfit } from "utils/calculateProfit";
import cutLongTitle from "utils/cutLongTitle";
import { ItemFinderMarketplace, SaveItem } from "..";
import SimilarItem from "../SimilarItem";
import SimilarItemsWrapper from "./ItemsWrapper";


interface Props {
    selectedItem: IconFinderItem;
    marketplace: ItemFinderMarketplace;
    onSaveItem?: (itm: SaveItem) => void;
    saveLoading?: boolean;
    changeSimilarLoading: (loading: boolean) => void;
    isShopify?: boolean;
    selectedFolderId?: string | null;
    handleIntegration?: (title: string, id: string) => void;
}
const AliExpressSimilarItems: FC<Props> = ({ selectedItem, selectedFolderId, marketplace, onSaveItem, isShopify, saveLoading, changeSimilarLoading, handleIntegration }) => {
    const [aliSeekLoading, setAliseekLoading] = useState(false);
    const { data: aliItems, isFetching: aliLoading } = useFindAliItemsQuery(selectedItem.img, {
        refetchOnMountOrArgChange: true
    });
    const similarLoading = aliSeekLoading || aliLoading;
    const [similarList, setSimilarList] = useState<MarketPlaceSimilarItemsList[]>(
        []
    );
    const { data: savedProducts, isFetching: savedLoading } = useGetSavedProductsQuery({
        folderId: Number(selectedFolderId),
        ebayId: selectedItem.id.toString(),
        supplierIds: similarList.map((item) => item.id),
    }, {
        skip: !selectedFolderId || similarList.length === 0,
    })

    const { data: settings } = useGetSettingsQuery();
    const [getAliSeekProductId] = useLazyGetAliseekProductQuery();

    useEffect(() => {
        changeSimilarLoading(similarLoading);
    }, [similarLoading])

    useEffect(() => {
        if (savedProducts) {
            if (savedProducts.result.length === similarList.length) {
                setSimilarList((currentList) => currentList.map((item, idx) => ({
                    ...item,
                    isSaved: savedProducts.result[idx].isSaved
                })))
            }
        }
    }, [savedProducts])

    useEffect(() => {
        if (aliItems) {
            setSimilarList([]);
            const result = aliItems?.result
            if (result?.data) {
                result?.data?.forEach((aliItem) => {
                    const newItem = {
                        title: cutLongTitle(aliItem.productTitle, 100),
                        img: aliItem.productImgUrl,
                        url: aliItem.url,
                        price: aliItem.price.toString(),
                        id: aliItem.productId,
                        sales: aliItem.orderCnt,
                        isSaved: false,
                        profit: calculateAliexpressProfit(
                            aliItem.price.toString(),
                            selectedItem.price,
                            settings?.result.breakEven || 0
                        ),
                    }

                    if (result.apiName.toLowerCase() === "aliseek") {
                        setAliseekLoading(true);
                        getAliSeekProductId(aliItem.url)
                            .unwrap()
                            .then((data) => {
                                newItem.id = data.result.productId
                                newItem.url = data.result.url
                                setSimilarList((current) => [...current, newItem]);
                                setAliseekLoading(false);
                            })
                            .catch(() => {
                                setSimilarList((current) => [...current, newItem]);
                                setAliseekLoading(false);
                            })
                    }
                    else {
                        setSimilarList((current) => [...current, newItem]);
                    }

                }
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aliItems]);

    return (
        <SimilarItemsWrapper isLoading={similarLoading} length={similarList.length} isAliExpress>
            {similarList.map((item) => (
                <SimilarItem
                    url={item.url}
                    pictureUrl={item.img}
                    onSaveItem={onSaveItem}
                    id={item.id}
                    isSaved={item.isSaved}
                    title={item.title}
                    price={Number(item.price)}
                    currency={"$"}
                    profit={item.profit}
                    key={item.id}
                    savedLoading={savedLoading}
                    handleIntegration={handleIntegration}
                    isShopify={isShopify}
                    marketplace={marketplace}
                    selectedItem={selectedItem}
                    saveLoading={saveLoading}
                />
            ))}
        </SimilarItemsWrapper>
    )
}

export default AliExpressSimilarItems