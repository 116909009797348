

import { ReactComponent as AliexpressHovered } from "assets/icons/aliexpress-hovered.svg";
import { ReactComponent as Aliexpress } from "assets/icons/aliexpress.svg";
import { ReactComponent as AmazonHovered } from "assets/icons/amazon-hovered.svg";
import { ReactComponent as Amazon } from "assets/icons/amazon.svg";
import { ReactComponent as Cj } from "assets/icons/cj.svg";
import { ReactComponent as GoogleHovered } from "assets/icons/google-hovered.svg";
import { ReactComponent as Google } from "assets/icons/google.svg";
import { ReactComponent as Walmart } from "assets/icons/walmart.svg";
import HoveredIcon from "components/HoveredIcon";
import { ALIEXPRESS_SITE, CJ_DROPSHIPPING, GOOGLE_LENS, WALMART_SITE } from "config/constants";
import { FC } from "react";
import { Link } from "react-router-dom";
import { IconFinderItem } from "types/services/iconFinder";
import { makeQueryParams } from "utils/makeQueryParams";
import { getAmazonDomain } from "utils/settings";

interface Props {
    selectedItem: IconFinderItem;
    amazonDomain?: string;
}
const iconClassName = "w-[26px] h-[25px]";

const FindItemBy: FC<Props> = ({ selectedItem, amazonDomain }) => {
    const title = selectedItem.title;
    const redirectTo = {
        google: makeQueryParams(`${GOOGLE_LENS}/uploadbyurl`, {
            url: selectedItem.img,
        }),
        amazon: makeQueryParams(`https://www.amazon${getAmazonDomain(amazonDomain || "US")}/s`, { k: title }),
        aliexpress: `${ALIEXPRESS_SITE}/af/${selectedItem.title}.html?switch_new_app=y&origin=n&dida=y`,
        walmart: makeQueryParams(`${WALMART_SITE}/search`, {
            query: title,
        }),
        cj: makeQueryParams(
            `${CJ_DROPSHIPPING}/list/wholesale-all-categories-l-all.html`,
            { search: title }
        ),
    };

    return (
        <div className="flex flex-col w-full gap-6">
            <p className="text-center text-xs font-medium">Search On</p>
            <div className="flex items-center justify-between w-full">
                <Link to={redirectTo.amazon} target="_blank" rel="noreferrer">
                    <HoveredIcon
                        Icon={Amazon}
                        title="Search title on Amazon"
                        tooltipClassName="dark:!bg-deepSpace900"
                        HoveredIcon={AmazonHovered}
                        hoveredIconClassName="w-[17px] h-[19px]"
                        iconClassName="w-[17px] h-[19px]"
                        className={iconClassName}
                        anchorId="find-item-by"
                    />
                </Link>
                <Link to={redirectTo.aliexpress} target="_blank" rel="noreferrer">
                    <HoveredIcon
                        Icon={Aliexpress}
                        title="Search title on AliExpress"
                        HoveredIcon={AliexpressHovered}
                        tooltipClassName="dark:!bg-deepSpace900"
                        hoveredIconClassName="w-4 h-3.5"
                        iconClassName="w-4 h-3.5"
                        className={iconClassName}
                        anchorId="find-item-by"
                    />
                </Link>
                <Link to={redirectTo.walmart} target="_blank" rel="noreferrer">
                    <HoveredIcon
                        Icon={Walmart}
                        title="Search title on Walmart"
                        tooltipClassName="dark:!bg-deepSpace900"
                        iconColor="fill-canaryYellow"
                        hoveredIconClassName="w-[15px] h-[17px]"
                        iconClassName="w-[15px] h-[17px]"
                        className={iconClassName}
                        anchorId="find-item-by"
                    />
                </Link>
                <Link to={redirectTo.cj} target="_blank" rel="noreferrer">
                    <HoveredIcon
                        Icon={Cj}
                        title="Search title on CJ dropshipping"
                        iconColor="fill-errorOrange"
                        iconClassName="w-[19px] h-3"
                        hoveredIconClassName="w-[19px] h-[19px]"
                        className={iconClassName}
                        anchorId="find-item-by"
                    />
                </Link>
                <Link to={redirectTo.google} target="_blank" rel="noreferrer">
                    <HoveredIcon
                        Icon={Google}
                        title="Search by photo on Google"
                        tooltipClassName="dark:!bg-deepSpace900"
                        HoveredIcon={GoogleHovered}
                        hoveredIconClassName="w-[17px] h-[18px]"
                        iconClassName="w-[17px] h-[18px]"
                        className={iconClassName}
                        anchorId="find-item-by"
                    />
                </Link>
            </div>
        </div>
    )
}

export default FindItemBy