import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import useHover from "hooks/useHover";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAddVatNumberMutation } from "store/api/managePaymentApi";
import { UpgradeCheckRes } from "types/services/managePayments";


interface Props {
    handleVatNumberUpdate?: () => void;
    data?: UpgradeCheckRes;
}
const AddVatNumberInput: FC<Props> = ({ handleVatNumberUpdate, data }) => {
    const [focusRef, isFocused] = useHover<HTMLInputElement>("focus");
    const [vatCountry, setVatCountry] = useState("")
    const [vatNumber, setVatNumber] = useState<string>("");
    const [vatErrorMessage, setVatErrorMessage] = useState<string | null>(null)
    const [vatSuccess, setVatSuccess] = useState(false);
    const hasVatError = vatErrorMessage !== null;
    const [addVatNumber] = useAddVatNumberMutation();
    useEffect(() => {
        if (data) {
            const summaryData = data?.result;
            const defaultVatNumber = summaryData?.vatNumber
            const countryCode = summaryData?.countryCode;
            setVatCountry(countryCode || "");
            if (defaultVatNumber) {
                setVatNumber(defaultVatNumber.substring(2));
                setVatSuccess(true);
            }
        }
    }, [data]);


    const onClearClick = () => {
        if (vatErrorMessage) {
            setVatNumber("");;
            setVatErrorMessage(null);
        } else {
            setVatSuccess(false);
            setVatNumber("")
        }
    }

    const onValChange = (val: string) => {
        setVatNumber(val);
        setVatErrorMessage(null);
        setVatSuccess(false);
    }
    useEffect(() => {
        if (vatErrorMessage) {
            toast.error(vatErrorMessage)
        }
    }, [vatErrorMessage])

    const handleButtonClick = () => {
        if (vatNumber.length === 0) {
        } else if (vatNumber.length >= 5) {
            addVatNumber(vatCountry + vatNumber).unwrap().then((res) => {
                if (res.result) {
                    setVatErrorMessage(null)
                    handleVatNumberUpdate?.();
                    setVatSuccess(true);
                }
                else {
                    setVatErrorMessage("An error occured");
                    setVatSuccess(false);
                }
            })
                .catch((err) => {
                    if (err.data) {
                        setVatErrorMessage(err.data);
                        setVatSuccess(false);
                    }
                });
        } else {
            setVatErrorMessage("VAT number is not valid")
        }

    };
    return (
        (
            <div className="w-full max-w-[168px] relative h-9 flex group">
                <p className="leading-[36px] mr-1 text-sm pt-[0.5px]">{vatCountry}</p>
                <input
                    ref={focusRef}
                    className={`w-full h-9 w-full text-sm placeholder:text-secondary placeholder:font-medium uppercase bg-transparent 
                        dark:placeholder:text-grey300 ${hasVatError ? "text-errorRed" : ""}`}
                    placeholder="123456789"
                    value={vatNumber}
                    onChange={(e) => onValChange(e.target.value)}
                    onBlur={handleButtonClick}
                />
                {hasVatError || vatSuccess ? (
                    <button className="min-w-[16px] h-4 flex items-center justify-center my-auto" onClick={onClearClick}>
                        {vatSuccess ? (
                            <Checkmark className="fill-success w-2 h-[6px]" />
                        ) : (
                            <Clear className="fill-errorRed w-2 h-2" />
                        )}
                    </button>
                ) : ""}
                <div className={`${isFocused ? "bg-primaryPurple dark:bg-purple500" :
                    hasVatError ? "bg-errorRed" : vatSuccess ? "bg-darkBlue dark:bg-grey100" : "bg-secondary dark:bg-grey100"} 
                    ${hasVatError ? "" : " group-hover:bg-primaryPurple dark:group-hover:bg-purple500"}
                 
                  h-[3px] w-full rounded-full absolute bottom-[0px] left-[0px]`} />
            </div>
        )
    )
}

export default AddVatNumberInput