import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Tokens } from "assets/icons/tokens.svg";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import Select from "components/Select";
import Toaster from "components/Toast";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useAddStoreMutation } from "store/api/quickSettingsApi";
import { SetAutoDsPage } from "utils/localStorage";
import { object, string } from "yup";
import TokenList from "./TokenList";

const integrations = [
  {
    text: "Choose type",
    value: "",
  },
  {
    text: "Sale Freaks",
    value: "1",
  },
  {
    text: "AutoDS",
    value: "0",
  },

];

interface FormValues {
  token: string;
  store: string;
  type: string;
}

let integrationsSchema = (isAutoDS: boolean) =>
  object({
    token: isAutoDS
      ? string()
      : string()
        .required("Token is Required")
        .min(10, "Token must be minimum 10 characters"),
    store: isAutoDS ? string() : string().required("Store is Required"),
    type: string().required("Type is Required"),
  });

const RedirectToAutoDs = (url: string) => {
  SetAutoDsPage(window.location.pathname + window.location.search);
  window.location.href = url;
};

const Integrations = () => {
  const [isAutoDS, setIsAutoDS] = useState(false);
  const [updateStore, { isLoading }] = useAddStoreMutation();
  const { t } = useTranslation(['home']);
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(integrationsSchema(isAutoDS)),
  });

  useEffect(() => {
    if (watch("type") === "0") {
      setIsAutoDS(true);
    } else {
      setIsAutoDS(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("type")]);
  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    if (isAutoDS) {
      RedirectToAutoDs(PATH.auth.connectautods);
    } else {
      updateStore({ ...values, userId: 0 })
        .unwrap()
        .then(() => {
          toast.success("Token successfully Added");
          reset();
        });
    }
  };

  return (
    <>
      <div className="flex gap-[140px] max-xl:flex-col max-xl:gap-10">
        <form
          className="border-[2px] border-neutral2 rounded-[10px] p-6 w-full h-fit dark:bg-grey800 dark:border-[1px] dark:border-grey700"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex items-center gap-5 font-bold mb-7 w-full">
            <Dollar className="fill-darkBlue dark:fill-grey100 w-3.5 h-3.5" />
            <p>{t('ChooseYourPreferredIntegration')}</p>
          </div>

          <div className="mb-[58px] flex justify-between items-center w-full">
            <div className="max-w-[230px]">
              <Controller
                control={control}
                defaultValue=""
                name="type"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      options={integrations}
                      value={value || ""}
                      handleChange={onChange}
                      btnClassName="w-full"
                    />
                    {errors.type?.message && (
                      <p className={`text-errorRed text-xs mt-2`}>
                        {errors.type?.message?.toString()}
                      </p>
                    )}
                  </>
                )}
              />
            </div>

            {isAutoDS && <Button
              title={isAutoDS ? "Connect AutoDS Store" : "Add Store"}
              height="h-9"
              loading={isLoading}
              type="submit"
              className={`${isAutoDS ? "max-w-[13rem]" : "max-w-[143px]"
                } ml-auto dark:bg-deepSpace900`}
            />}


            {/*  <span className="text-xs mt-3 text-electricBlue">
              Just a reference for easier recognition
            </span> */}
          </div>
          {watch("type") === "0" || watch("type") === "" ? (
            ""
          ) : (
            <div className="flex items-center gap-10 mb-[38px] w-full">
              <ControlledInput<FormValues>
                placeholder="Token"
                className="w-full"
                inputClassName="w-full"
                name="token"
                register={register}
                errors={errors}
              />
              <ControlledInput<FormValues>
                placeholder="Store Name"
                className="w-full"
                inputClassName="w-full"
                name="store"
                register={register}
                errors={errors}
              />
            </div>
          )}


          {(!isAutoDS && watch("type") !== "") && <div className="flex justify-end gap-11 max-[660px]:flex-col">
            <Button
              title={isAutoDS ? "Connect AutoDS Store" : "Connect SaleFreaks Store"}
              height="h-9"
              loading={isLoading}
              type="submit"
              className={`${isAutoDS ? "max-w-[13rem]" : "max-w-[232px]"
                } ml-auto dark:bg-deepSpace900`}
            />

          </div>}

        </form>

        <div className="border-[2px] border-neutral2 rounded-[10px] p-6 pr-3 w-full max-[1300px]:max-w-full dark:bg-grey800 dark:border-[1px] dark:border-grey700">
          <div className="flex font-bold w-full items-center gap-5 mb-4">
            <Tokens className="fill-darkBlue dark:fill-grey100" />
            <p>Active Integrations</p>
          </div>

          <TokenList />
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default Integrations;
