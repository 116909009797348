import { useAppSelector } from "hooks/useAppSelector";
import jwt from "jwt-decode";
import { useEffect, useState } from "react";
import { GetAccessToken } from "utils/localStorage";

export type UserPackages = "Free" | "Pro" | "ProPlus" | "Enterprise";
export interface DecodedUser {
  country: string;
  active: string;
  active_trail: string;
  already_trial: string;
  amazon_site: string;
  aud: string;
  email: string;
  exp: number;
  has_special_subscription: string;
  iat: number;
  iss: string;
  journey_completed: string;
  jti: string;
  default_page: string;
  market_type: string;
  nameid: string;
  nbf: number;
  no_of_subscriptions: string;
  no_of_shopify_subscriptions: string;
  package_name: string;
  package: UserPackages;
  role: string;
  session_id: string;
  store: string;
  theme: string;
  unique_name: string;
  days_for_trial: string;
  shopify_attempt: string;
  marketplace: string;
  shopify_active_trail: string;
  shopify_already_trial: string;
  shopify_package: string;
  shopify_package_name: string;
  shopify_days_for_trial: string;
  shopify_free_trial: string;
  free_trial: string;
  next_payment_date: string;
  shopify_next_payment_date: string;
  free_trial_expired: string;
  shopify_free_trial_expired: string;
}
const initialUser = {
  country: "",
  active: "",
  active_trail: "",
  already_trial: "",
  amazon_site: "",
  aud: "",
  email: "",
  exp: 0,
  has_special_subscription: "",
  iat: 0,
  iss: "",
  journey_completed: "",
  jti: "",
  default_page: "",
  market_type: "",
  nameid: "",
  nbf: 0,
  no_of_subscriptions: "",
  no_of_shopify_subscriptions: "",
  package_name: "",
  package: "free" as UserPackages,
  role: "",
  session_id: "",
  store: "",
  theme: "",
  unique_name: "",
  days_for_trial: "",
  shopify_attempt: "",
  marketplace: "",
  shopify_active_trail: "",
  shopify_already_trial: "",
  shopify_package: "",
  shopify_package_name: "",
  shopify_days_for_trial: "",
  free_trial: "",
  shopify_free_trial: "",
  next_payment_date: "",
  shopify_next_payment_date: "",
  free_trial_expired: "",
  shopify_free_trial_expired: "",
};
const useDecodeToken = () => {
  const [userDetails, setUserDetails] = useState<DecodedUser>(initialUser);
  const { accessToken } = useAppSelector((state) => state.user.result);
  const token = accessToken || GetAccessToken();

  useEffect(() => {
    if (token) {
      setUserDetails(jwt(token));
    }
  }, [token]);

  return userDetails;
};

export default useDecodeToken;
