import { LOCAL_STORAGE_KEYS } from "./localStorage";

const removeLocalStorageItemsOnLogout = () => {
  const keys = LOCAL_STORAGE_KEYS;
  localStorage.removeItem(keys.ACCESS);
  localStorage.removeItem(keys.REFRESH);
  localStorage.removeItem(keys.USER);
  localStorage.removeItem(keys.ALI_ITEM_FINDER_FOLDER);
  localStorage.removeItem(keys.EBAY_ITEM_FINDER_FOLDER);
  sessionStorage.removeItem(keys.FREE_TRIAL_CRO);
};

export default removeLocalStorageItemsOnLogout;
