import { NO_CAMPAIGN } from "config/campaign-settings";
import { FC } from "react";
import CampaignDiscountTxt from "./Campaign";
import NoCampaignDiscountTxt from "./NoCampaign";


interface Props {
    className?: string;
    activePlans: "shopify" | "ebay";
    fromRegFunnel?: boolean;
    period?: string;
    showTwoPlans?: boolean
    fromNoSub?: boolean;
    showYearlyTxt?: boolean;
}
const DiscountTxt: FC<Props> = ({ className, showYearlyTxt, period, activePlans, showTwoPlans, fromNoSub, fromRegFunnel = false }) => {
    const sameProps = {
        activePlans,
        className
    }
    return NO_CAMPAIGN ?
        <NoCampaignDiscountTxt showYearlyTxt={showYearlyTxt} period={period} {...sameProps} /> :
        <CampaignDiscountTxt showTwoPlans={showTwoPlans} fromNoSub={fromNoSub} fromRegFunnel={fromRegFunnel} {...sameProps} />
}

export default DiscountTxt;