import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-extended.svg";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import { FC, useState } from "react";
import { useSelectedBulkStoreQuery } from "store/api/quickSettingsApi";
import { ItemFinderMarketplace } from "..";
import ActiveIntegrations from "./ActiveIntegrations";
import AutoDsIntegration from "./AutoDsIntegration";
import ChooseIntegration from "./ChooseIntegration";
import ImportProductsTo from "./ImportProductsTo";
import IntegrationSuccessfull from "./IntegrationSuccessfull";
import SaleFreaksIntegration from "./SaleFreaksIntegration";

interface Props {
    helpers: ReturnType<useModal>;
    isEditIntegration?: boolean;
    marketplace?: ItemFinderMarketplace;
    supplierTitle?: string;
    ebayTitle?: string;
    asin?: string;
}
export type IntegrationStepTypes = null | "autoDS" | "saleFreaks" | "success"
const ItemFinderIntegration: FC<Props> = ({ helpers, isEditIntegration, marketplace, supplierTitle, ebayTitle, asin }) => {
    const [integrationType, setIntegrationType] = useState<IntegrationStepTypes>(null);
    const { data: selectedBulkStore } = useSelectedBulkStoreQuery();
    const storeType = (selectedBulkStore?.result.bulkStoreId || 0) > 0 ? selectedBulkStore?.result.type : null;
    const showAutoDS = storeType === 0;
    const showSalesFreak = storeType === 1;
    const showImportProductsTo = (showAutoDS || showSalesFreak) && !isEditIntegration && integrationType === null
    const onIntegrationChange = (val: IntegrationStepTypes) => setIntegrationType(val);
    const isSuccess = integrationType === "success"
    const onBackClick = () => setIntegrationType(null);
    const handleClose = () => {
        helpers.close();
        onIntegrationChange(null)
    }
    return (
        <Modal helpers={{ ...helpers, close: handleClose }} maxWidth={isSuccess ? "max-w-[354px]" : "max-w-[911px]"} shouldCloseOnOverlayClick hideCloseIcon={isSuccess} buttonCloseClassName="!w-6 !h-6 right-[24px] top-[24px]"
            cardClassName={` ${isSuccess ? "border-smoothGreen dark:border-paradiseBlue py-8 px-8" : "!border-primaryPurple dark:!border-purple500 pt-12 pb-6 px-6"} rounded-[16px]`}>
            {integrationType !== null && !isSuccess && (
                <button className="w-6 h-6 flex items-center justify-center absolute top-0 left-0 top-[24px] left-[24px] group" onClick={onBackClick}>
                    <LeftArrow className="fill-darkBlue w-4 h-[14px] group-hover:fill-primaryPurple dark:fill-grey100 dark:group-hover:fill-purple500" />
                </button>
            )}
            {showImportProductsTo ?
                <ImportProductsTo
                    type={showAutoDS ? "AutoDS" : "Salefreaks"}
                    marketplace={marketplace}
                    supplierTitle={supplierTitle}
                    onIntegrationChange={onIntegrationChange}
                    ebayTitle={ebayTitle}
                    asin={asin}
                    handleClose={handleClose}
                /> :
                integrationType === null ? (isEditIntegration ?
                    <ActiveIntegrations onIntegrationSelect={onIntegrationChange} /> : (
                        <ChooseIntegration onIntegrationSelect={onIntegrationChange} />
                    )) : integrationType === "autoDS" ? (
                        <AutoDsIntegration onIntegrationChange={onIntegrationChange} />
                    ) : integrationType === "saleFreaks" ? (
                        <SaleFreaksIntegration onIntegrationSelect={onIntegrationChange} />
                    ) : isSuccess ? <IntegrationSuccessfull handleClose={handleClose} /> : ""}
        </Modal>
    )
}

export default ItemFinderIntegration