import Button from "components/Button";
import Card from "components/Card";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import CheckoutCouponInput from "components/Input/CheckoutCouponInput";
import EbayMarketplaceIcon from "components/MarketplaceIcon/Ebay";
import ShopifyMarketplaceIcon from "components/MarketplaceIcon/Shopify";
import PaymentMethod from "components/PaymentMethod/Index";
import Toaster from "components/Toast";
import { BLUESNAP_SCRIPT } from "config/config";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useExternalScript from "hooks/useExternalScript";
import ShopifyBillingHeader from "pages/Shopify/Billing/components/BillingHeader";
import { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    useGetDefaultCouponQuery,
    useLazyCheckRegistrationQuery, useLazyCheckUpgradeQuery, useLazyGetPfTokenQuery, useLazyPayoneerPurchaseQuery,
    useLazyPaypalPurchaseQuery,
    useLazyPaypalRegistrationPurchaseQuery,
    useLazyValidateCouponQuery
} from "store/api/managePaymentApi";
import { useEbaySubCountQuery, useShopifySubCountQuery } from "store/api/quickSettingsApi";
import { onSelectPlan } from "store/slices/planSlice";
import { gtmOnboardingPaymentSubmit, gtmOnboardingPaypalClick } from "utils/googleTagManager";
import { getPlanDetails, getPlanName, getPlanSuffix } from "utils/planUtils";
interface Props {
}


const SubscriptionPurchasePage = () => {
    const [coupon, setCoupon] = useState("");
    const { data: shopifySubCount } = useShopifySubCountQuery()
    const { data: ebaySubCount } = useEbaySubCountQuery()
    const { selectedPlan } = useAppSelector((state) => state.planSlice);
    const [searchParams] = useSearchParams()
    const { search } = useLocation();
    const dispatch = useAppDispatch();
    const isShopify = search.includes("isShopify");
    const noEbayPlan = ebaySubCount?.result === 0;
    const hasNoShopify = shopifySubCount?.result === 0;
    const isUpgrade = isShopify ? !hasNoShopify : !noEbayPlan
    const [method, setMethod] = useState("");
    const dataFetchedRef = useRef(false);
    const [couponUsed, setCouponUsed] = useState(false);
    const [isCCTAlreadyTrial, setIsCCAlreadyTrial] = useState(false);
    const [ccPaymentLoading, setCCPaymentLoading] = useState(false);
    const [couponApplying, setCouponApplying] = useState(true);
    const changeCCPaymentLoading = (isLoading: boolean) => setCCPaymentLoading(isLoading);
    const handleCCAlreadyTrial = (isTrial: boolean) => { setIsCCAlreadyTrial(isTrial); setCouponUsed(false) };
    const changeCouponApplying = (applying: boolean) => setCouponApplying(applying);
    const changeCouponUsed = (isUsed: boolean) => setCouponUsed(isUsed)
    const handleMethod = (type: string) => setMethod(type);
    const [paypalPurchase, { isFetching: paypalFetching }] = useLazyPaypalPurchaseQuery();
    const [paypalRegisterPurchase, { isFetching: paypalRegisterFetching }] = useLazyPaypalRegistrationPurchaseQuery();
    const [payoneerPurchase, { isFetching: payoneerFetching }] = useLazyPayoneerPurchaseQuery();
    const handleApplyCoupon = (newVal: string) => setCoupon(newVal);
    const { data: defaultCoupon, isFetching: couponLoading } = useGetDefaultCouponQuery(selectedPlan.type, { skip: selectedPlan.type === "", refetchOnMountOrArgChange: true });
    const [, { isFetching: couponValidating }] = useLazyValidateCouponQuery();
    const [checkRegister, { data: registerData, isFetching: checkRegisterLoading, isUninitialized: checkRegisterUninitialized }] = useLazyCheckRegistrationQuery();
    const [checkUpgrade, { data: upgradeData, isFetching: checkUpgradeLoading, isUninitialized: checkUpdateUninitialized }] = useLazyCheckUpgradeQuery();
    const data = isUpgrade ? upgradeData : registerData;
    const getDataUninitialized = isUpgrade ? checkUpdateUninitialized : checkRegisterUninitialized
    const [getPfToken] = useLazyGetPfTokenQuery();
    useExternalScript(BLUESNAP_SCRIPT, "");

    useEffect(() => {
        getPfToken(null)
            .unwrap()
            .then((data) => {
                window.gpfToken = data.message;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheck = () => {
        const body = {
            package: selectedPlan.type,
            coupon: coupon || "",
        }
        if (isUpgrade) {
            checkUpgrade(body).unwrap()
                .then()
                .catch((error) => toast.error(error.data.message));
        } else {
            checkRegister({
                ...body,
                skipTrial: selectedPlan.skip
            })
                .unwrap()
                .then()
                .catch((error) => toast.error(error.data?.message));
        }
    }
    useEffect(() => {
        if (searchParams.get("type")) {
            const planType = searchParams.get("type") || ""
            const plan = getPlanDetails(planType);
            const skip = searchParams.get("skip") || false
            dispatch(onSelectPlan({
                type: planType,
                planName: plan?.planName || "",
                planPrice: plan?.planPrice || 0,
                skip
            }))
        }
    }, [searchParams]);

    useEffect(() => {
        if (searchParams.get("Retry") && !dataFetchedRef.current) {
            toast.error("There was a problem processing your transaction. Please try a different payment method or contact your bank for assistance");
            dataFetchedRef.current = true;
        }
    }, [searchParams])

    useEffect(() => {
        if (!couponLoading && defaultCoupon?.message === "") {
            changeCouponApplying(false)
        }
    }, [defaultCoupon, couponLoading])

    useEffect(() => {
        if (selectedPlan.type && !couponValidating && !couponApplying) {
            handleCheck()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coupon, selectedPlan, couponValidating, couponApplying]);

    const handlePurchase = () => {
        if (method === "") {
            toast.error("Please, select a payment method.");

            return;
        }

        if (method === "pp") {
            if (isUpgrade) {
                paypalPurchase({
                    couponName: coupon,
                    requestedSubscriptionType: selectedPlan.type,
                })
                    .unwrap()
                    .then((data) => window.location.replace(data.message));
            } else {
                gtmOnboardingPaypalClick()
                paypalRegisterPurchase({
                    couponName: coupon || "",
                    requestedSubscriptionType: selectedPlan.type,
                    skipTrial: selectedPlan.skip
                })
                    .unwrap()
                    .then((data) => {
                        gtmOnboardingPaymentSubmit();
                        window.location.replace(data.message);
                    }).catch(() => { });
            }
        }

        if (method === "py") {
            payoneerPurchase({
                couponName: coupon,
                type: selectedPlan.type,
                skipTrial: selectedPlan.skip
            })
                .unwrap()
                .then((data) => window.location.replace(data.message));
        }
    };


    const handleVatNumberUpdate = () => {
        if (selectedPlan?.type != null) {
            handleCheck()
        }
    }

    const isLifeTime = selectedPlan.type.includes("LT")
    const isEnterprise = selectedPlan.type.toLowerCase().includes("enterprise")
    const cardStyles = "w-full dark:text-grey100 border border-solid border-secondary dark:border-grey700"
    return (
        <div>
            <ShopifyBillingHeader />
            <div className="flex gap-5 max-lg:flex-col w-full">
                <Card className={`p-8 max-sm:p-4  ${cardStyles}`} shadow={false}>
                    <div className="flex items-center justify-between mb-8">
                        <div className="font-medium">Billing Details</div>
                        {isShopify ? <ShopifyMarketplaceIcon /> : <EbayMarketplaceIcon />}

                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-[29px]">
                        <div className={"h-[100px] px-6 w-full dark:bg-grey100 dark:text-deepSpace900 bg-neutral2 rounded-[15px] flex justify-center flex-col gap-2.5"}>
                            <div className="flex items-center text-[20px] max-sm:text-base">
                                <span className={`font-bold ${isShopify ? "capitalize" : "uppercase"}`}>
                                    {isShopify ? selectedPlan.planName : getPlanName(selectedPlan.type)}
                                </span>
                                {isShopify ? "" : (
                                    <span className="font-medium ml-2">
                                        {isLifeTime && !isEnterprise ? " /life" : getPlanSuffix(selectedPlan.type)}
                                    </span>
                                )}
                            </div>
                            {isLifeTime && isEnterprise ?
                                <span className="font-medium">
                                    Lifetime
                                </span>
                                : ""}
                            <div className="text-[14px] font-medium">Selected Plan</div>
                        </div>
                    </div>
                    <PaymentMethod
                        alreadyTrial={isCCTAlreadyTrial}
                        className="mt-[18px] mb-[26px]"
                        handleMethod={handleMethod}
                        handleApplyCoupon={handleApplyCoupon}
                        onCCPaymentLoading={changeCCPaymentLoading}
                        selectedPlan={selectedPlan.type}
                        coupon={coupon}
                        isShopify={isShopify}
                        skip={selectedPlan.skip}
                        handleAlreadyTrial={handleCCAlreadyTrial}
                        fromRegistration={couponUsed || isCCTAlreadyTrial}
                        amount={data?.result.totalToday}
                    />

                    {(method === "pp" || method === "py") && (
                        <Button
                            className="mb-8 w-full mx-auto bg-darkBlue  hover:bg-smoothGreen hover:border-smoothGreen  dark:bg-lilac400 dark:border-lilac400 dark:hover:!bg-paradiseBlue dark:hover:!border-paradiseBlue"
                            titleClassName="!text-base !font-bold text-smoothGreen group-hover:text-darkBlue dark:text-darkBlue"
                            height="h-[56px]"
                            title="Subscribe"
                            loading={paypalFetching || paypalRegisterFetching || payoneerFetching || ccPaymentLoading}
                            handleClick={() => {
                                handlePurchase();
                            }}
                        />
                    )}
                    {!couponLoading && (
                        <CheckoutCouponInput
                            handleApplyCoupon={handleApplyCoupon}
                            selectedPlan={selectedPlan.type}
                            preloadCoupon={defaultCoupon?.message}
                            changeCouponUsed={changeCouponUsed}
                            couponValidating={changeCouponApplying}
                        />
                    )}
                </Card>
                <Card className={`${cardStyles}`} shadow={false}>
                    <CheckoutOrderSummary
                        wrapperStyles="!border-0 !max-w-[100%] !p-8"
                        selectedPlan={selectedPlan}
                        isLoading={checkUpgradeLoading || checkRegisterLoading || couponLoading || checkRegisterLoading || couponApplying || getDataUninitialized}
                        data={data}
                        isCreditCard={method === "cc"}
                        hideAlreadyTrialWarning={false}
                        isDollarTrial={!isUpgrade}
                        isShopify={isShopify}
                        handleVatNumberUpdate={handleVatNumberUpdate}
                        alreadyTrial={isCCTAlreadyTrial}
                        handleAlreadyTrialChange={handleCCAlreadyTrial}
                        couponUsed={couponUsed}
                    />
                </Card>
                <Toaster />
            </div>
        </div>

    );
};

export default SubscriptionPurchasePage;
