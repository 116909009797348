import { useEffect } from "react";
import { PATH } from "routes/path";
import { queryTags } from "store/api/queryTags";
import { quickSettingsApi } from "store/api/quickSettingsApi";
import {
  GetIntegrationFlag,
  RemoveIntegrationFlag,
  SetAutoDsPage,
  SetIntegrationFlag,
} from "utils/localStorage";
import { useAppDispatch } from "./useAppDispatch";

const useAutoDsConnectFromItemFinder = (onReturn: () => void) => {
  const dispatch = useAppDispatch();
  const onAutoDsConnect = () => {
    SetAutoDsPage(window.location.pathname + "?isItemFinder=true");
    SetIntegrationFlag("false");
    window.open(PATH.auth.connectautods, "_blank");
  };

  useEffect(() => {
    const handleStorageChange = () => {
      if (GetIntegrationFlag() === "true") {
        onReturn();
        dispatch(
          quickSettingsApi.util.invalidateTags([
            queryTags.quickSettings.tokenList,
          ])
        );
        RemoveIntegrationFlag();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return onAutoDsConnect;
};

export default useAutoDsConnectFromItemFinder;
