import { helpVideos } from "assets/data/helpVideos";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import HeaderHelpVideo from "components/Header/HelpVideo";
import useModal from "hooks/useModal";
import { FC, ReactNode } from "react";

interface Props {
  helpers: ReturnType<useModal>;
  children: ReactNode;
  top?: string;
  videoIcon?: boolean;
  className?: string;
  wrapperClassName?: string;
  headerWrapperClassName?: string;
  maxWidth?: string;
  onHandleClose?: () => void;
}

const Drawer: FC<Props> = ({
  helpers,
  children,
  className,
  wrapperClassName,
  headerWrapperClassName,
  top,
  maxWidth,
  videoIcon,
  onHandleClose
}) => {
  const close = () => {
    onHandleClose?.(); helpers.close();
  }
  const { isOpen } = helpers

  return (
    <div className={isOpen ? "fixed z-50 right-0 left-0 top-0 w-full h-full" : ""}>
      {isOpen && (
        <div className="absolute w-full h-full bg-grey700/10 dark:bg-grey800/30" onClick={close} />
      )}
      <div
        className={`h-full z-50 fixed right-0 bg-white dark:bg-deepSpace800 shadow transition-maxWidth duration-500 pt-2.5 pb-12 w-full ${isOpen ? (maxWidth || "max-w-[615px]") : "max-w-[0px]"
          } ${className || ""} ${top || "top-0"}`}
      >
        {isOpen ? (
          <div className={`flex justify-between items-center mb-9 px-4.5 ${headerWrapperClassName}`}>
            <button
              onClick={close}
              className={
                "w-8 h-8 bg-neutral2 rounded-full flex items-center justify-center hover:bg-primaryPurple dark:hover:bg-purple500 dark:bg-deepSpace800 group"
              }
            >
              <LeftArrow
                className="rotate-180 group-hover:fill-white fill-darkBlue dark:fill-lilac400"
              />
            </button>
            {videoIcon ? (
              <HeaderHelpVideo videos={helpVideos.manualScanner} />

            ) : (
              ""
            )}
          </div>
        ) : ""}

        {isOpen ? (
          <div
            className={`scrollbar pl-4.5 pr-3.5 mr-1 h-full pb-4  ${wrapperClassName || ""}`}
          >
            {children}
          </div>
        ) : ""}
      </div>
    </div>
  )
};

export default Drawer;
