import { useEffect, useState } from "react";
import findKeywords from "utils/findKeywords";

export enum ITEM_TYPE {
  dropshippersOnly = "dropshippers-only",
  excludeVero = "exclude-vero",
}

export const conditionList = [
  {
    text: "Dropshippers Only",
    value: ITEM_TYPE.dropshippersOnly,
  },
  { text: "Exclude VeRO", value: ITEM_TYPE.excludeVero },
];

type List<T> = {
  isAliDropship: boolean;
  isAmzDropshipUK: boolean;
  isAmzUSDropship: boolean;
  isWalmartDropship: boolean;
} & T;

const useShowByField = <T>(initialList: List<T>[], titleField: keyof T) => {
  const [filteredList, setFilteredList] = useState<List<T>[]>([]);
  const [itemType, setItemType] = useState<string[]>([]);

  useEffect(() => {
    if (initialList) {
      setFilteredList(initialList);
    }
  }, [initialList]);
  const filter = (type: "both" | "exclude" | "dropshippers") => {
    const newList = filteredList.filter((item) => {
      const dropshippers =
        item.isAliDropship ||
        item.isAmzDropshipUK ||
        item.isAmzUSDropship ||
        item.isWalmartDropship;
      const exclude =
        findKeywords(item[titleField] as string, true).vero.length < 1;

      if (type === "both") {
        return exclude && dropshippers;
      } else if (type === "exclude") {
        return exclude;
      } else if (type === "dropshippers") {
        return dropshippers;
      } else {
        return true;
      }
    });

    setFilteredList(newList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (
      itemType.includes(ITEM_TYPE.dropshippersOnly) &&
      itemType.includes(ITEM_TYPE.excludeVero)
    ) {
      filter("both");
    } else if (itemType.includes(ITEM_TYPE.dropshippersOnly)) {
      filter("dropshippers");
    } else if (itemType.includes(ITEM_TYPE.excludeVero)) {
      filter("exclude");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemType]);

  const changeItemType = (newValue: string) => {
    const find = itemType.includes(newValue);
    const remove = itemType.filter((item) => item !== newValue);
    setItemType((prev) => (find ? remove : [...prev, newValue]));
    setFilteredList(initialList);
  };

  return {
    filteredList,
    itemType,
    changeItemType,
    options: conditionList,
    setItemType,
  };
};

export default useShowByField;
