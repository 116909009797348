
import Drawer from "components/Drawer";
import { ErrorCodes, ErrorMsgs } from "config/errorMsgs";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { foldersApi } from "store/api/foldersApi";
import { useSaveItemMutation } from "store/api/iconFinderApi";
import { queryTags } from "store/api/queryTags";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { Option } from "types/common";
import {
    IconFinderItem
} from "types/services/iconFinder";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { getCountryFromMarketplace } from "utils/settings";
import ChooseFolderRow from "./ChooseFolderRow";
import ItemFinderSelectedItem from "./SelectedItem";
import SimilarItems from "./SimilarItems";
import Tabs from "./Tabs";


export type ItemFinderMarketplace = "US" | "Ali" | "WAL" | "Cjd" | "Baba"
interface Props {
    helpers: ReturnType<useModal>;
    item: IconFinderItem;
    marketplace?: string;
    currency?: CURRENCY_SIGNS;
    isFromMarketInsights?: boolean
    sourcePlace?: string;
    fromPE?: boolean;
    showCheckmark?: boolean;
    productNotFound?: boolean;
    amazonPESite?: string;
    onHandleClose?: () => void;
}

export interface SaveItem {
    asin: string;
    title: string;
    price: number;
    pictureUrl: string;
    profit: string | number;
}

const ItemFinder: FC<Props> = ({ helpers, item, currency, onHandleClose, amazonPESite, marketplace, showCheckmark, productNotFound, isFromMarketInsights, fromPE, sourcePlace = "" }) => {
    const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);
    const [similarLoading, setSimilarLoading] = useState(false);
    const [selectedMarketplace, setSelectedMarketplace] = useState<ItemFinderMarketplace>("US");
    const { data: settings } = useGetSettingsQuery();
    const [saveItem, { isLoading: saveLoading }] = useSaveItemMutation();
    const dispatch = useAppDispatch();
    const site = marketplace || settings?.result.amazonsite;
    const amazonDomain = isFromMarketInsights ? getCountryFromMarketplace(sourcePlace) : site;

    const onSelectedMarketplaceChange = (market: ItemFinderMarketplace) => {
        setSelectedMarketplace(market);
    };

    const onFolderSelect = (newVal: Option | null) => setSelectedFolder(newVal);
    const changeSimilarLoading = (load: boolean) => {
        setSimilarLoading(load)
    }

    useEffect(() => {
        if (site) {
            const newSite = (site === "Ali" || site === "Baba" || site === "WAL" || site === "Cjd") ? site : "US"
            setSelectedMarketplace(newSite as ItemFinderMarketplace);
        }
    }, [site]);

    const onSaveItem = async ({
        asin,
        title,
        price,
        pictureUrl,
        profit,
    }: SaveItem) => {
        if (
            Boolean(Number(selectedFolder?.value)) === false ||
            selectedFolder === null
        ) {
            toast.error("Please Select Folder")
        } else {
            await saveItem({
                sendBulkItems: [
                    {
                        ebayTitle: item.title,
                        ebayPrice: item.price.toString(),
                        ebayItemId: item.id.toString(),
                        amazonTitle: title,
                        amazonPrice: price,
                        amazonImageUrl: pictureUrl,
                        asin,
                        profit,
                        sales: item.sold,
                    },
                ],
                listid: selectedFolder?.value || "",
                requestId: item.id.toString(),
                userID: 0,
                type: "Ebay",
                sellerName: item.sellerName
            }).unwrap().then((data) => {
                if (data.message === ErrorCodes.itemAlreadyAdded) {
                    toast.error(ErrorMsgs.ItemAlreadyAdded(selectedFolder?.text || ""));
                } else {
                    dispatch(
                        foldersApi.util.invalidateTags([queryTags.folders.folders, queryTags.folders.savedProducts])
                    )
                    toast.success("Item Saved!");
                }
            }).catch((error) => {
                if (error?.data === ErrorCodes.itemAlreadyAdded) {
                    toast.error(ErrorMsgs.ItemAlreadyAdded(selectedFolder?.text || ""));
                }
            });
        }
    };
    const isSimilarProduct = isFromMarketInsights ? false : fromPE ? !showCheckmark : item.isSimilar;
    const noProduct = isFromMarketInsights ? false : fromPE ? productNotFound : !item.isGreen;
    const productType = isSimilarProduct ? "similar" : noProduct ? "noProduct" : "exact"

    return (
        <Drawer
            helpers={helpers}
            maxWidth={"max-w-[1064px]"}
            videoIcon
            onHandleClose={onHandleClose}
            headerWrapperClassName="!mb-6"
            className={`pb-[190px] !pt-[15px]`}
        >
            <div className="w-full flex gap-4 dark:text-grey100">
                <div className="bg-neutral1 dark:bg-grey700 h-[calc(100vh-104px)] w-full max-w-[300px] rounded-[10px] p-4 ">
                    <ItemFinderSelectedItem item={item} currency={currency} type="ebay" amazonDomain={amazonDomain} marketplace={selectedMarketplace} similarLoading={similarLoading} />
                </div>

                <div className="w-full dark:text-grey100">
                    {(selectedMarketplace === 'Ali' || selectedMarketplace === 'WAL' || selectedMarketplace === 'US') ?
                        <ChooseFolderRow
                            isOpen={helpers.isOpen}
                            selectedFolder={selectedFolder}
                            onFolderSelect={onFolderSelect}
                            type={selectedMarketplace === "Ali" ? "aliexpress" : "eBay"}
                        />
                        : <div className="h-[44px]" />}
                    <Tabs
                        active={selectedMarketplace}
                        onChange={onSelectedMarketplaceChange}
                        productType={productType}
                    />
                    {helpers.isOpen && (
                        <SimilarItems
                            ebayTitle={item.title}
                            amazonPESite={amazonPESite}
                            isFromMarketInsights={isFromMarketInsights}
                            selectedItem={item}
                            changeSimilarLoading={changeSimilarLoading}
                            saveLoading={saveLoading}
                            fromPE={fromPE}
                            onSaveItem={onSaveItem}
                            selectedFolderId={selectedFolder ? selectedFolder.value.toString() : null}
                            marketplace={selectedMarketplace}
                            currency={currency}
                        />
                    )}
                </div>
            </div>
        </Drawer>
    );
};

export default ItemFinder;
