import HoveredIcon from "components/HoveredIcon";

//icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import InfoTooltip from "components/Card/InfoTooltip";
import TableCheckbox from "components/Table/TableCheckbox";
import { TokenList } from "types/services/quickSettings";

export const tokenCols = (
  handleDelete: (id: string) => void,
  updateLoading?: boolean,
  selectedItem?: string
): ColumnDef<TokenList>[] => [
    {
      header: "Tokens",
      accessorKey: "token",
      cell: ({ row: { original } }) => (
        <p className="min-w-[115px]">
          {"************************" +
            original.token.substring(original.token.length - 4)}
        </p>
      ),
      meta: {
        tooltipTitle: "A digital token represents a specific amount of digital resources you can own, assign to another, or redeem later. "
      }
    },
    {
      header: "Store Name",
      accessorKey: "storeName",
      cell: (storeName) => String(storeName.getValue()),
    },
    {
      header: () => "Type",
      accessorKey: "type",
      cell: (type) => (Number(type.getValue()) === 0 ? "Auto DS" : Number(type.getValue()) === 1 ? "Sale Freaks" : ""),
    },
    {
      accessorKey: "bulkStoreId",

      header: () => <Trash className="fill-darkBlue dark:fill-grey100" />,
      minSize: 50,
      cell: ({ row: { original } }) => (
        <HoveredIcon
          variant="outlined"
          Icon={Trash}
          width="w-6"
          height="h-6"
          isLoading={
            updateLoading && selectedItem === String(original.bulkStoreId)
          }
          handleClick={() => handleDelete(String(original.bulkStoreId))}
          iconClassName={"w-[10px]"}
        />
      ),
      meta: {
        tooltipTitle: "Delete",
        alignHead: "justify-center"
      },
    },
  ];


export const tokenColsWithRadio = (
  handleDelete: (id: string) => void,
  onSelectBulkStore: (id: number) => void,
  errorType?: "401" | "400" | false,
  updateLoading?: boolean,
  selectedItem?: string,
): ColumnDef<TokenList>[] => [
    {
      header: "Selected",
      accessorKey: "selected",
      cell: ({ row }) => {
        const checked = row.getIsSelected();
        const isError = errorType && row.original.type === 0;

        return (
          <div className="flex items-center gap-2">
            <TableCheckbox
              {...{
                checked,
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: checked ? () => { } : row.getToggleSelectedHandler(),
              }}
              onWrapperClick={() => {
                if (!checked) {
                  onSelectBulkStore(row.original.bulkStoreId)
                }
              }}
              wrapperClassName="!h-4"
              checkboxClassName={`!w-4 !h-4 !p-[1.5px] checked:border-[1.5px] border-[1px] 
              ${isError ? "hover:checked:border-errorRed checked:!border-errorRed dark:checked:border-errorRed dark:hover:checked:border-errorRed" :
                  "hover:checked:border-smoothGreen checked:border-smoothGreen dark:hover:checked:border-smoothGreen dark:checked:border-smoothGreen"}
              checked:bg-transparent dark:checked:bg-transparent
              ${checked ? "" : "hover:bg-primaryPurple dark:hover:bg-purple500"}`}
              checkmarkClassName="hidden"
            >
              {checked ? <div className={`w-2.5 h-2.5 aspect-square absolute rounded-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
              ${isError ? "bg-errorRed" : "bg-smoothGreen"}`} /> : ""}
            </TableCheckbox>
            <p className={`${checked ? "" : "invisible"} ${isError ? "text-errorRed" : "text-[#16C5B0]"}`}>{isError ? "Error" : "Active"}</p>
          </div>)
      },
      meta: {
      }
    },
    {
      header: "Tokens",
      accessorKey: "token",
      cell: ({ row: { original } }) => (
        <p className="min-w-[115px]">
          {"************************" +
            original.token.substring(original.token.length - 4)}
        </p>
      ),
      meta: {
        tooltipTitle: "A digital token represents a specific amount of digital resources you can own, assign to another, or redeem later. "
      }
    },
    {
      header: "Store Name",
      accessorKey: "storeName",
      cell: (storeName) => String(storeName.getValue()),
    },
    {
      header: () => "Type",
      accessorKey: "type",
      cell: (type) => (Number(type.getValue()) === 0 ? "Auto DS" : Number(type.getValue()) === 1 ? "Sale Freaks" : ""),
    },
    {
      accessorKey: "bulkStoreId",

      header: () => <Trash className="fill-darkBlue dark:fill-grey100" />,
      minSize: 50,
      cell: ({ row: { original }, row }) => {
        const isError = errorType && row.original.type === 0;
        const tooltipTitle = errorType === "400" ?
          "Please re-integrate your AutoDs account or try again later." :
          errorType === "401" ? "Please connect the store in your AutoDs account." : ""
        return (
          <div className="relative w-full h-full items-center flex justify-center">
            <HoveredIcon
              variant="outlined"
              Icon={Trash}
              width="w-6"
              height="h-6"
              isLoading={
                updateLoading && selectedItem === String(original.bulkStoreId)
              }
              handleClick={() => handleDelete(String(original.bulkStoreId))}
              iconClassName={"w-[10px]"}
            />
            {isError ? (
              <InfoTooltip title={tooltipTitle} className="absolute top-[0px] right-[0px]" iconClassName="w-[11px] h-[11px] !fill-errorRed !stroke-errorRed" />
            ) : ""}
          </div>
        )
      },
      meta: {
        tooltipTitle: "Delete",
        alignHead: "justify-center"
      },
    },
  ];