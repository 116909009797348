import Button from "components/Button";
import Loading from "components/Loading";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useProPlusLimitsQuery } from "store/api/zikProApi";
import { onSelectPlan } from "store/slices/planSlice";
import { PLAN_TYPE } from "types/plans";
import { makeQueryParams } from "utils/makeQueryParams";
import { getPlanDetails } from "utils/planUtils";
interface Props {
    type: "AutoPilot" | "TurboScanner"
}

const LimitReachedInfo: FC<Props> = ({ type }) => {
    const navigate = useNavigate();
    const userDetails = useDecodeToken();
    const dispatch = useAppDispatch();
    const plan = userDetails.package;
    const showLimitTxt = plan === PLAN_TYPE.PRO_PLUS;
    const isFreeTrial = userDetails.free_trial === "True"
    const upgradePlan = userDetails.package_name === "StandardM" ? "EnterpriseM" : userDetails.package_name === "StandardY" ? "EnterpriseY" : "EnterpriseLT"
    const { data: limitsData, isFetching } = useProPlusLimitsQuery(type, { skip: !showLimitTxt });
    const onUpgrade = () => {
        const plan = getPlanDetails(upgradePlan);
        dispatch(onSelectPlan({
            type: upgradePlan,
            planName: plan?.planName || "",
            planPrice: plan?.planPrice || 0,
            skip: false
        }))
        navigate(makeQueryParams(PATH.managementPanel[isFreeTrial ? "subscriptionPurchase" : "subscriptionUpgrade"], { type: upgradePlan, from: type }))
    }
    const scansLeft = limitsData?.result.scansLeft || 0;
    const limit = limitsData?.result.limit

    return (
        <div className={`flex items-center gap-4 w-full ${showLimitTxt ? "" : "invisible"}`}>
            {isFetching ? (
                <div className="min-w-[120px] flex justify-center">
                    <Loading fill="fill-primaryPurple" />
                </div>
            ) : (
                <div className={`${scansLeft >= 5 ? "bg-[#9BFBCA]" : scansLeft <= 1 ? "bg-[#FF8383]" : "bg-[#F7DA63]"} text-sm p-2 rounded-[8px] font-medium`}>
                    Scans left: {scansLeft} {scansLeft !== 0 ? ` of ${limit}` : ""}
                </div>
            )}
            <Button
                title="Upgrade for Unlimited Scans!"
                handleClick={onUpgrade}
                color="smoothGreen"
                height="h-9"
                titleClassName={`${scansLeft <= 1 ? "!font-bold group-hover:text-darkBlue dark:text-darkBlue" : ""}`}
                className={`max-w-[266px] dark:bg-deepSpace900 ${scansLeft <= 1 ?
                    "bg-darkBlue text-smoothGreen !border-darkBlue hover:!border-smoothGreen dark:bg-lilac400 dark:!border-lilac400 dark:hover:!border-paradiseBlue" : ""}`}
            />
        </div>
    )
}

export default LimitReachedInfo;