import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import Toaster from "components/Toast";
import { LANDING_APP_URL } from "config/config";
import { COOKIE_DOMAIN, GOOGLE_CAPTCHA_KEY } from "config/constants";
import RegistrationFunnelNavbar from "pages/RegistrationFunnel/components/Navbar";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useRegisterUserMutation, useSavePotentialUserMutation } from "store/api/onboardingApi";
import { setUserAccessToken } from "store/slices/userSlice";
import getSessionID from "utils/getSessionID";
import { gtmRegisterSubmit, gtmRegisterSubmitAttempt } from "utils/googleTagManager";
import { SetAccessToken, SetUserDetails } from "utils/localStorage";
import validator from 'validator';
import { object, string } from "yup";
import RegistrationRightContent from "./components/RegistrationRightContent";

interface FormValues {
  fullName?: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  IsAffiliate: boolean;
  refferId: number;
  campaignId: string;
  Ga: string;
  clickidgclid: string;
  gclid: string;
  fbc: string;
  fbp: string;
  ampDeviceId: string;
  ampGetSessionId: string;
}

let registerSchema = object({
  fullName: string().required("Full Name is Required").max(400, "Full Name is too long!"),
  email: string().required("Email is Required").max(200, "Email is invalid").email("Email is invalid"),
  password: string()
    .required("Password is Required")
    .min(5, "Password must be between 5 and 20 characters!")
    .max(20, "Password must be between 5 and 20 characters!")
});

const Registration = () => {
  const [cookies, , removeCookie] = useCookies(['rege', 'ReferrerId', 'CampaignId', '_ga', 'clickid_gclid', '_gclid', '_fbc', '_fbp', 'amp_deviceId', 'amp_getSessionId', 'step', 'introStep']);
  const { pathname } = useLocation();
  const isTestB = pathname.includes("registration-b");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(registerSchema),
  });
  const email = cookies.rege
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const referredId = cookies.ReferrerId;
  const campaignId = cookies.CampaignId;
  const ga = cookies._ga;
  const { clickid_gclid } = cookies;
  const gclid = cookies._gclid;
  const fbc = cookies._fbc;
  const fbp = cookies._fbp;
  const { amp_deviceId } = cookies;
  const { amp_getSessionId } = cookies;

  useEffect(() => {
    removeCookie('step', COOKIE_DOMAIN);
    removeCookie('introStep', COOKIE_DOMAIN);
  }, []);

  useEffect(() => {
    if (email) {
      setValue("email", email)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const [registerUser, { isLoading, error }] = useRegisterUserMutation();
  const [savePotentialUser, { isLoading: savePotUserLoading }] = useSavePotentialUserMutation();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaErr, setRecatchaErr] = useState("");
  useEffect(() => {
    if (error) {
      // @ts-ignore
      if (error?.status === 400) {
        toast.error("Wrong Email or Password!");
        reset();
      }
      else {
        toast.error("An error occured!");
        reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const saveUser = async (values: FormValues) => {
    //some extra validations
    var unsafeName = validator.isEmail(values.firstName) || validator.isURL(values.firstName);
    if (unsafeName) {
      toast.error("First Name is not valid");
      return;
    }
    var unsafeLastName = validator.isEmail(values.lastName) || validator.isURL(values.lastName);
    if (unsafeLastName) {
      toast.error("Last Name is not valid");
      return;
    }

    registerUser({ ...values, sessionID: getSessionID() })
      .unwrap()
      .then((data) => {
        if (data.result.tokens.accessToken) {
          gtmRegisterSubmit(data.result.userInfo.email, data.result.userId);
          navigate(PATH.regFunnel[isTestB ? "b" : "root"]);
          SetAccessToken(data.result.tokens.accessToken);
          SetUserDetails(data.result.userInfo);
          dispatch(setUserAccessToken(data.result.tokens.accessToken));
        }
      }).catch((error) => {
        toast.error(error.data);
      })
  }

  const onCaptchaChange = (value: string | null) => {
    if (value !== "" || value !== null) {
      setRecatchaErr("");
    }
  }

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const recaptchaValue = recaptchaRef.current?.getValue();
    if (recaptchaValue === "" || recaptchaValue === null) {
      setRecatchaErr("Captcha is required");

      return;
    }
    values.IsAffiliate = isAffiliate === true;
    values.refferId = referredId || 0;
    values.campaignId = campaignId || "";
    values.Ga = ga || "";
    values.clickidgclid = clickid_gclid || "";
    values.ampDeviceId = amp_deviceId || "";
    values.ampGetSessionId = amp_getSessionId || "";
    values.fbc = fbc || "";
    values.fbp = fbp || "";
    values.gclid = gclid || "";
    const { fullName, ...remaining } = values;
    const splittedName = fullName?.split(" ")
    const payload = {
      ...remaining,
      firstName: splittedName?.[0] || "",
      lastName: splittedName?.[1] || "",
    }

    if (cookies.rege == null) {
      const savePotentialData = {
        email: values.email,
        Ga: values.Ga,
        clickidgclid: values.clickidgclid,
        gclid: values.gclid,
        fbc: values.fbc,
        fbp: values.fbp,
        ampDeviceId: values.ampDeviceId,
        ampGetSessionId: values.ampGetSessionId,
        sessionID: getSessionID()
      };

      await savePotentialUser(savePotentialData).unwrap()
        .then(async () => {
          await saveUser(payload);
        }).catch((error) => {
          toast.error(error.data);
        })
    }
    else {
      await saveUser(payload);
    }
  };
  const isEmailReadOnly = !email || email === null || email?.trim() === "" ? false : true
  const inputClassName = `border-2 py-[3px] pr-[15px] rounded-[7px] bg-white text-base h-[56px] pl-1.5 max-sm:h-[48px]`;
  const inputContClassName = `flex flex-col gap-[5px]`
  const labelStyles = `font-medium`

  return (
    <div className={`h-screen overflow-y-auto max-lg:bg-white  max-lg:pb-[88.5px] max-sm:pb-4`}>
      <div className="bg-white px-[30px] sm:px-[60px] lg:hidden w-full left-0">
        <RegistrationFunnelNavbar handleBack={() => { }} isRegistration />
      </div>
      <div className={`flex max-lg:flex-col max-lg:bg-white max-lg:mt-[41.55px] max-sm:mt-4`}>
        <div className="w-[52%] flex lg:justify-center lg:items-center lg:h-[100vh] max-lg:h-fit max-lg:w-full my-auto max-lg:pb-8 lg:py-10 max-sm:py-0">
          <div className={`mx-auto  max-sm:max-w-[330px] max-w-[390px] max-lg:max-w-[456px]`}>
            <Logo className="w-[31px] h-[31px] mb-2 max-lg:hidden" />

            <h5 className={`font-medium mb-4 text-[24px] max-sm:text-lg`}>
              Start with ZIK Analytics!
            </h5>
            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className={`flex flex-col max-lg:max-w-full gap-6 max-sm:gap-4`}
            >
              <div className={inputContClassName}>
                <label htmlFor="fullName" className={labelStyles}>Full Name</label>
                <ControlledInput<FormValues>
                  className={inputClassName}
                  fontSize="text-base"
                  placeholder="Type your full Name"
                  name="fullName"
                  register={register}
                  type="text"
                  variant="contained"
                  inputClassName="autofill:bg-transparent"
                  errors={errors}
                />
              </div>
              <div className={inputContClassName}>
                <label htmlFor="email" className={labelStyles}>Email</label>
                <ControlledInput<FormValues>
                  className={`${inputClassName} ${isEmailReadOnly ? "!bg-[#F6F6F6] !border-[#CACACA]" : ""}`}
                  placeholder="Enter your Email"
                  name="email"
                  fontSize="text-base"
                  register={register}
                  type="text"
                  variant="contained"
                  inputClassName="autofill:bg-transparent"
                  errors={errors}
                  defaultValue={email}
                  readOnly={isEmailReadOnly}
                />
              </div>

              <div className={inputContClassName}>
                <label htmlFor="password" className={labelStyles}>Password</label>
                <ControlledInput
                  type="password"
                  className={`${inputClassName}`}
                  placeholder="Password"
                  name="password"
                  fontSize="text-base"
                  register={register}
                  variant="contained"
                  togglePassword
                  inputClassName="autofill:bg-transparent"
                  errors={errors}
                />
              </div>

              <div className="flex flex-col justify-center items-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={GOOGLE_CAPTCHA_KEY}
                  onChange={onCaptchaChange}
                />
                {recaptchaErr !== "" && <div className="text-errorRed text-xs mt-2">{recaptchaErr}</div>}
              </div>
              <div className={`text-[14px] sm:hidden leading-[20px] text-neutral3 w-full font-normal`}>
                By clicking “Sign Up” you agree to our <Link className="text-electricBlue underline" to={`${LANDING_APP_URL}terms-and-conditions/`}>Terms of Service</Link>  & <Link className="text-electricBlue underline" to={`${LANDING_APP_URL}/privacy-policy/`}>Privacy Policy</Link>
              </div>

              <Button
                type="submit"
                title={isAffiliate === true ? "Enroll for free" : "Next"}
                loading={isLoading || savePotUserLoading}
                color="smoothGreen"
                variant="outlined"
                titleClassName="!text-base font-bold text-smoothGreen group-hover:text-darkBlue"
                height="h-[64px] max-sm:h-[56px]"
                className={`w-full mx-auto group bg-darkBlue !border-darkBlue hover:!border-smoothGreen text-bold`}
                handleClick={gtmRegisterSubmitAttempt}
              />
            </form>
            <div className={`text-[14px] max-sm:hidden leading-[20px] text-neutral3 w-full font-normal mt-4`}>
              By clicking “Sign Up” you agree to our <Link className="text-electricBlue underline" to={`${LANDING_APP_URL}terms-and-conditions/`}>Terms of Service</Link>  & <Link className="text-electricBlue underline" to={`${LANDING_APP_URL}/privacy-policy/`}>Privacy Policy</Link>
            </div>
          </div>
        </div>

        <RegistrationRightContent />

      </div>
      <Toaster />
    </div>
  );
};
export default Registration;
