import { useEffect } from 'react';
import removeLocalStorageItemsOnLogout from 'utils/removeLocStoreItemsOnLogout';
import LoadingPage from './Loading';

const Logout = () => {
        useEffect(() => {
                removeLocalStorageItemsOnLogout()
                window.location.href = '/login';
        }, []);

        return <LoadingPage loading={true}> </LoadingPage>;
};

export default Logout;