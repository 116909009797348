import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Save } from "assets/icons/save.svg";
import HoveredIcon from "components/HoveredIcon";
import { formatCurrency, formatNumber } from "utils/formatCurrency";


export interface AnalyseAliexpressItem {
  title: string;
  price: number;
  feedback: number;
  totalSales: number;
  rating: number;
  img: string;
}
const cols = (onSaveClick: (item: AnalyseAliexpressItem) => void, isLoading: boolean, isAliGrowth?: boolean): ColumnDef<AnalyseAliexpressItem>[] => {
  const columns: ColumnDef<AnalyseAliexpressItem>[] = [
    {
      header: "",
      accessorKey: "title",
      cell: () => (
        <div className="text-center mx-2">
          <p>Shipping</p>
          <p className="font-bold">Standard</p>
        </div>
      ),
    },
    {
      header: "",
      accessorKey: "rating",
      cell: (rating) => (
        <div className="text-center mx-2">
          <p>Rating</p>
          <p className="font-bold">{formatNumber(String(rating.getValue()))}</p>
        </div>
      ),
    },
    {
      header: "",
      accessorKey: "feedback",
      cell: (feedback) => (
        <div className="text-center mx-2">
          <p>{isAliGrowth ? 'Reviews' : 'Votes'}</p>
          <p className="font-bold">{formatNumber(String(feedback.getValue()))}</p>
        </div>
      ),
    },
    {
      header: "",
      accessorKey: "totalSales",
      cell: (sales) => (
        <div className="text-center mx-2">
          <p>Sales</p>
          <p className="font-bold">{formatNumber(String(sales.getValue()))}</p>
        </div>
      ),
    },
    {
      header: "",
      accessorKey: "price",
      cell: (price) => (
        <div className="text-center mx-2">
          <p>Price</p>
          <p className="font-bold">{formatCurrency(String(price.getValue()))}</p>
        </div>
      ),
    },
  ];

  if (!isAliGrowth) {

    columns.push({
      header: "",
      accessorKey: "id",
      cell: ({ row: { original } }) => (
        <HoveredIcon
          Icon={Save}
          handleClick={() => onSaveClick(original)}
          isLoading={isLoading}
          title="Save to Folder"
          anchorId="analyse_ali_anchor_id"
          width="w-6"
          height="h-6"
          iconClassName="w-3 h-1.5"
          className="mx-3"
          tooltipClassName="dark:!bg-deepSpace900"
        />
      ),
    },);
  }

  return columns;
};
export const analyzeAliexpressCols = (onSaveClick: (item: AnalyseAliexpressItem) => void, isLoading: boolean, isAliGrowth?: boolean): ColumnDef<AnalyseAliexpressItem>[] => cols(onSaveClick, isLoading, isAliGrowth);
