import HoveredIcon from "components/HoveredIcon";
import { FC } from "react";

import { ReactComponent as AliexpressHovered } from "assets/icons/boxes/aliexpress-hovered.svg";
import { ReactComponent as Aliexpress } from "assets/icons/boxes/aliexpress.svg";

import { ReactComponent as AlibabaHovered } from "assets/icons/boxes/alibaba-hovered.svg";
import { ReactComponent as Alibaba } from "assets/icons/boxes/alibaba.svg";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";

interface Props {
    handleClick: () => void;
}

const iconDim = "w-[23px] h-[24.5px]";

export const itemFinderLogo = {
    aliexpress: {
        title: "AliExpress",
        icon: Aliexpress,
        iconHovered: AliexpressHovered,
    },
    alibaba: {
        title: "Alibaba",
        icon: Alibaba,
        iconHovered: AlibabaHovered,
    },

};

const iconColor = "dark:fill-grey100 fill-darkBlue";


const ShopifyItemFinderBox: FC<Props> = ({ handleClick }) => {
    const { data: settings } = useGetSettingsQuery();
    const type = settings?.result.amazonsite === "Baba" ? "alibaba" : "aliexpress"

    return (
        <HoveredIcon
            Icon={itemFinderLogo[type].icon}
            className="w-9 h-9"
            HoveredIcon={itemFinderLogo[type].iconHovered}
            iconClassName={iconDim}
            hoveredIconClassName={iconDim}
            iconColor={iconColor}
            handleClick={handleClick}
            anchorId={"shopify-item-finder-box"}
            title={type === "aliexpress" ? "Find product by Image on AliExpress" : "Find product by Image on Alibaba"}

        />
    )
}

export default ShopifyItemFinderBox