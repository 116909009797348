import { FC } from "react";
import { formatCurrency } from "utils/formatCurrency";

type Period = "monthly" | "yearly"

interface Props {
    isShopify?: boolean;
    selected: Period;
    onSelect: (per: Period) => void;
}

interface PeriodProps {
    value: Period;
    isChecked: boolean;
    label: string;
    onSelect: (val: Period) => void;
    saveAmount?: number;
};

const Period: FC<PeriodProps> = ({ value, isChecked, label, onSelect, saveAmount }) => (
    <label
        htmlFor={value.toString()}
        className={` w-full flex items-center gap-2 px-3.5
           border-2 rounded-[8px] h-10 hover:scale-105 transition-all cursor-pointer group
          ${isChecked ? "border-smoothGreen hover:border-smoothGreen bg-neutral2" : "border-secondary hover:border-smoothGreen bg-neutral1"}`}
    >
        <div className={`w-4 h-4 rounded-full flex items-center justify-center
        ${isChecked ? "border-smoothGreen border-[1.5px]" : "border-darkBlue border group-hover:border-smoothGreen"}`}>
            {isChecked ? (
                <div className="w-2.5 h-2.5 bg-smoothGreen rounded-full" />
            ) : ""}
        </div>
        <input type="radio" checked={isChecked} onChange={() => onSelect(value)} value={value} id={value} className="hidden" />
        <p className="font-medium flex items-center">
            <span>
                {label}
            </span>
            {saveAmount ? (
                <span className="ml-2 text-xs text-primaryPurple bg-grey100 leading-[24px] block h-6 px-2 my-[auto] rounded-[4px]">
                    Save {formatCurrency(saveAmount)}
                </span>
            ) : ""}
        </p>
    </label>
)


const PeriodSelect: FC<Props> = ({ isShopify, selected, onSelect }) => {
    const periods = [{
        value: "monthly" as Period,
        label: "Monthly"
    }, {
        value: "yearly" as Period,
        label: "Yearly",
        saveAmount: isShopify ? 100 : 500
    }];

    return (
        <div className="mb-4 flex gap-4 w-full">{periods.map((period) => (
            <Period key={period.value} {...period} onSelect={onSelect} isChecked={selected === period.value} />
        ))}</div>
    )
}

export default PeriodSelect;