import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { tableSearch } from "components/Table/tableFilters";
import { useEffect, useMemo, useState } from "react";

interface Props<T> {
  data: T[];
  columns: ColumnDef<T>[];
  pagination?: boolean;
  search?: boolean;
  minMax?: boolean;
  initialPageSize?: number;
  sort?: boolean;
  initialSortBy?: keyof T;
  desc?: boolean;
  enableRowSelection?: boolean;
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  enableMultiRowSelection?: boolean;
  columnDeps?: any;
  dataDeps?: any;
  getRowId?: (row: any, relativeIndex: any, parent: any) => any;
}
const useTable = <T>({
  data,
  columns,
  pagination,
  search,
  initialPageSize,
  initialSortBy,
  desc,
  sort,
  columnDeps,
  rowSelection,
  enableRowSelection,
  onRowSelectionChange,
  enableMultiRowSelection = true,
  dataDeps,
  minMax,
  getRowId,
}: Props<T>) => {
  const [sorting, setSorting] = useState<SortingState>(
    initialSortBy ? [{ id: initialSortBy as string, desc: desc || false }] : []
  );

  const [globalFilter, setGlobalFilter] = useState("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCols = useMemo<ColumnDef<T>[]>(() => columns, columnDeps || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedData = useMemo<T[]>(() => data, dataDeps || []);

  const table = useReactTable({
    data: memoizedData,
    columns: memoizedCols,
    enableSorting: sort || false,
    enableRowSelection: enableRowSelection || false,
    enableMultiRowSelection,
    onRowSelectionChange,
    state: {
      ...(search && { globalFilter }),
      ...(sort && { sorting }),
      ...(rowSelection && { rowSelection }),
    },
    filterFns: {
      ...(search && { fuzzy: tableSearch }),
    },
    // @ts-ignore
    initialState: {
      ...(initialSortBy && {
        sortBy: [
          {
            id: "",
            desc: false,
          },
        ],
      }),
    },
    globalFilterFn: tableSearch,
    ...(sort && {
      onSortingChange: setSorting,
      enableSortingRemoval: false,
      getSortedRowModel: getSortedRowModel(),
    }),
    ...(search && { onGlobalFilterChange: setGlobalFilter }),
    ...(pagination && { getPaginationRowModel: getPaginationRowModel() }),
    ...((minMax || search) && {
      getFilteredRowModel: getFilteredRowModel(),
    }),
    getCoreRowModel: getCoreRowModel(),
    getRowId,
  });

  const selectedCol = (col: keyof T) => table.getColumn(col.toString());
  const columnFilterValue = (col: keyof T) =>
    selectedCol(col)?.getFilterValue();

  const minMaxFilter = (col: keyof T) => ({
    min: (columnFilterValue(col) as [number, number])?.[0],
    max: (columnFilterValue(col) as [number, number])?.[1],
    onChange: (newVal: number | string, type: "min" | "max") =>
      selectedCol(col)?.setFilterValue((prev: [number, number]) =>
        type === "min"
          ? [newVal || undefined, prev?.[1]]
          : [prev?.[0], newVal || undefined]
      ),
  });

  useEffect(() => {
    if (initialPageSize) {
      table.setPageSize(initialPageSize);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { table, minMaxFilter, setSorting };
};

export default useTable;
