import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import InfoTooltip from "components/Card/InfoTooltip";
import useHover from "hooks/useHover";
import useModal from "hooks/useModal";
import useOutsideClick from "hooks/useOutsideClick";
import { FC, useEffect } from "react";
import { To, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import handleEnterClick from "utils/handleEnterClick";
import Button from "../Button";
import SearchHistory from "./SearchHistory";

interface Props {
  handleChange: (newValue: string) => void;
  queryParams?: { [key: string]: string };
  className?: string;
  searchIconClassName?: string;
  inputClassName?: string;
  searchIconWrapper?: string;
  borderColor?: string;
  search: string;
  searchButton?: boolean;
  labelClassName?: string;
  handleSearchClick?: () => void;
  maxWidth?: string;
  redirect?: To;
  required?: boolean;
  loading?: boolean;
  maxLimit?: number;
  hideClear?: boolean;
  searchHistory?: any;
  placeholder?: string;
  size?: "sm" | "md" | "lg";
  htmlFor?: string;
  btnMaxWidth?: string;
  toolTip?: string;
}

const Search: FC<Props> = ({
  handleChange,
  className,
  inputClassName,
  borderColor,
  size = "sm",
  search,
  searchButton,
  maxLimit,
  loading,
  placeholder,
  handleSearchClick,
  queryParams,
  required,
  searchHistory,
  redirect,
  searchIconClassName,
  searchIconWrapper,
  maxWidth,
  labelClassName,
  btnMaxWidth,
  hideClear,
  htmlFor = "search",
  toolTip,
}) => {
  const { isOpen, open, close } = useModal();
  const [focusRef, isFocused] = useHover<HTMLInputElement>("focus");
  const [, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [ref] = useOutsideClick<HTMLDivElement>(close);

  const handleClick = () => {
    if (required && search.length < 1) {
      toast.warning("Please enter the search term!");
    } else {
      queryParams && setSearchParams({ search, ...queryParams });
      redirect && navigate(redirect);
      handleSearchClick && handleSearchClick();
    }
  };
  useEffect(() => {
    if (isFocused && searchHistory?.history.length > 0) {
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <div
      ref={ref}
      className={`w-full ${searchButton ? "flex items-center gap-4 max-lg:justify-center" : ""
        } ${className || ""} `}
      style={{ maxWidth }}
    >
      <div
        className="w-full relative"
        onKeyUp={(e) => handleEnterClick(e, handleClick)}
      >
        <label
          className={`flex items-center w-full pl-[18px] pr-[17px] py-[5px] ${size === "sm" ? "h-9" : "h-[3.25rem]"
            } border-2 rounded-[6.25rem] ${isFocused
              ? "border-primaryPurple dark:border-purple500"
              : borderColor || "border-darkBlue dark:border-grey100"
            } dark:bg-deepSpace900 ${labelClassName}`}
          htmlFor={htmlFor}
        >
          <div className={searchIconWrapper}>
            <SearchIcon
              className={`${isFocused ? "fill-primaryPurple dark:fill-purple500" : "fill-neutral3 dark:fill-grey100"
                } w-[16px] h-[16px] ${searchIconClassName}`}
            />
          </div>
          <input
            className={`h-full focus:outline-none pl-2 placeholder:text-neutral3 placeholder:font-normal text-sm bg-transparent dark:text-grey100 w-full ${inputClassName}`}
            ref={focusRef}
            placeholder={placeholder || "Search"}
            value={search}
            autoComplete="off"
            onChange={(e) => {
              if (maxLimit) {
                if (e.target.value.length < maxLimit) {
                  handleChange(e.target.value)
                }
              } else {
                handleChange(e.target.value)
              }
            }}
            name="search"
            id={htmlFor}
          />
          {toolTip &&
            <div
              className="absolute -top-[10px] -right-[10px] z-0"
            >
              <InfoTooltip title={toolTip} />
            </div>}
          {search.length > 0 && !hideClear ? (
            <button
              className="p-1 cursor-pointer group"
              onClick={() => handleChange("")}
            >
              <Clear className=" group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-neutral3 dark:fill-grey100" />
            </button>
          ) : (
            ""
          )}
        </label>
        {isOpen && searchHistory?.history.length > 0 ? (
          <SearchHistory
            searchHistory={searchHistory}
            handleChange={handleChange}
          />
        ) : (
          ""
        )}
      </div>
      {searchButton && (
        <Button
          title="Search"
          loading={loading}
          color="primaryPurple"
          height={`${size === "sm" ? "h-9" : ""}`}
          className={`${btnMaxWidth || "max-w-[8.75rem]"} dark:bg-deepSpace900`}
          handleClick={handleClick}
        />
      )}
    </div>
  );
};

export default Search;
