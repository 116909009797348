import { ReactComponent as Tokens } from "assets/icons/tokens.svg";
import { tokenColsWithRadio } from "assets/tableColumns/tokens";
import Select from "components/Select";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteStoreMutation, useGetSelectedBulkStoreQuery, useUpdateSelectedBulkStoreMutation } from "store/api/quickSettingsApi";
import { IntegrationStepTypes } from "./Index";

const tableStyles: TableStyles = {
    style: `max-h-[270px] overflow-y-auto scrollbar`,
    px: "px-0 pr-2",
    body: {
        rowHeight: "h-[49px]",
        contHeight: "h-[37px]",
        selected: {
            outlineBorderColor: "border-paradiseBlue/50 dark:border-[#22C9B5]",
            dividerBorderColor: "border-secondary dark:border-[#22C9B5]",
            bg: "bg-[#F0FFFD] dark:bg-[#14AC9B]/50"
        },
        disabled: {
            outlineBorderColor: "border-errorRed",
            dividerBorderColor: "border-neutral2 dark:border-errorRed",
            bg: "bg-[#d54c4c]/50"
        }
    },
    head: {
        rowHeight: "h-[49px]",
        contHeight: "h-[37px]",
        outlineBorderColor: "border-darkBlue dark:border-grey700"
    },
    empty: {
        rowHeight: "h-[49px]",
        contHeight: "h-[37px]",
    }
};

const integrations = [
    {
        text: "Sale Freaks",
        value: "1",
    },
    {
        text: "AutoDS",
        value: "0",
    },
];
interface Props {
    onIntegrationSelect: (opt: IntegrationStepTypes) => void;
}

const ActiveIntegrations: FC<Props> = ({ onIntegrationSelect }) => {
    const [selectedItem, setSelectedItem] = useState<string>("");
    const [rowSelection, setRowSelection] = useState({});
    const onChange = (val: string) => {
        onIntegrationSelect(val === "0" ? "autoDS" : "saleFreaks")
    };
    const [updateBulkStore] = useUpdateSelectedBulkStoreMutation();
    const { data, isFetching } = useGetSelectedBulkStoreQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const errorMsg = data?.result.errorMessage;
    const errorType = errorMsg === "error_401" ? "401" : errorMsg === "error_400" ? "400" : false
    const bulkStoreList = data?.result.bulkStores || []
    const [deleteStore, { isLoading: deleteLoading }] = useDeleteStoreMutation();
    const { t } = useTranslation(['home']);
    const onDelete = (id: string) => {
        setSelectedItem(id);
        deleteStore(id);
    };



    const onSelectBulkStore = (bulkStoreId: number) => updateBulkStore(bulkStoreId)
    const { table } = useTable({
        columns: tokenColsWithRadio(onDelete, onSelectBulkStore, errorType, (deleteLoading || isFetching), selectedItem),
        dataDeps: [bulkStoreList],
        enableRowSelection: true,
        rowSelection,
        enableMultiRowSelection: false,
        onRowSelectionChange: setRowSelection,
        columnDeps: [deleteLoading, selectedItem, errorType],
        data: bulkStoreList,
    });
    useEffect(() => {
        if (data?.result) {
            table.getRowModel().rows.map((row, idx) => {
                if (row.original.isSelected) {
                    setRowSelection({ [idx]: true });
                }
            })
        }
    }, [data?.result])
    return (
        <div className="max-w-[671px] w-full mx-auto">
            <div className="flex font-bold w-full justify-center items-center gap-[14px] mb-2">
                <Tokens className="fill-primaryPurple dark:fill-purple50" />
                <p className="text-[20px]">Active Integrations</p>
            </div>
            <p className="text-center mb-6">See your Active Integrations or new ones.</p>
            <div className="flex justify-center mx-auto w-full sidebar mb-4">
                {(bulkStoreList.length > 0 || isFetching) ? (
                    <Table table={table} isLoading={isFetching} styles={tableStyles}
                        disabled={errorType ? {
                            key: "type",
                            value: 0
                        } : undefined}
                        loadingDim={24} />
                ) : (
                    <div className="text-sm flex justify-center items-center w-full h-full min-h-[150px]">
                        <p className="-mt-6">{t('NoTokensAdded')}.</p>
                    </div>
                )}
            </div>
            <div className="flex w-full justify-between items-center">
                <div className="flex flex-col">
                    <h6 className="mb-2 font-bold text-[20px]">Add New Integration</h6>
                    <Select
                        options={integrations}
                        value=""
                        handleChange={onChange}
                        title="Choose Lister"
                        className="max-w-[200px] w-full"
                        btnClassName="w-full !h-9 -top-10"
                        listWrapperClassName="!top-11"
                    />
                </div>
            </div>
        </div>
    )
}

export default ActiveIntegrations;