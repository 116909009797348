import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { FC } from "react";
import { formatCurrency } from "utils/formatCurrency";

const ebayBulletPoints = (isMonthly: boolean) => [
    `Save Up to ${formatCurrency(isMonthly ? 44.99 : 500)}!`,
    "Find Profitable Products!",
    "Spy on your Competitors!",
    "Grow your Sales effortlessly!"
];
const shopifyBulletPoints = (isMonthly: boolean) => [
    `Save ${formatCurrency(isMonthly ? 15 : 100)} on the 1st Month!`,
    "Get Unlimited Access to all ZIK tools!",
    "Find Winning Products in clicks!",
    "Spy on any Shopify store!",
    "Grow your Sales effortlessly!"
];
interface Props {
    isMonthly: boolean;
    isShopify?: boolean;
}
const BulletPoints: FC<Props> = ({ isShopify, isMonthly }) => {
    const points = isShopify ? shopifyBulletPoints(isMonthly) : ebayBulletPoints(isMonthly)

    return (
        <div className="flex flex-col gap-2">
            {points.map((point, i) => (
                <div className="flex items-center gap-2.5" key={i}>
                    <div className="w-6 h-6 flex items-center justify-center">
                        <Checkmark className="fill-darkBlue w-3 h-[9px]" />
                    </div>
                    <p className={`text-lg leading-[28px] ${i === 0 ? "font-bold" : "font-medium"}`}>
                        {point}
                    </p>
                </div>
            ))}
        </div>
    )
}

export default BulletPoints;