import Tooltip from "components/Tooltip";
import { FindKeywords } from "hooks/useFindKeywords";
import { useCallback, useEffect, useId, useState } from "react";
import reactStringReplace from "react-string-replace";
import cutTitle from "utils/cutLongTitle";
import HighlightedKeyword from "./HighlightedKeyword";
interface Props {
  name: string;
  checkKeywords?: boolean;
  keywords: ReturnType<FindKeywords>;
  cutLongTitle?: number
}
const ProductTitle = ({ name, checkKeywords, keywords, cutLongTitle }: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [title, setTitle] = useState(cutLongTitle ? cutTitle(name, cutLongTitle) : name);
  const id = useId()
  const handleTitleChange = useCallback(
    (n: string) => {
      if (name) {
        setTitle(cutLongTitle ? cutTitle(n, cutLongTitle) : n);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name]
  );
  const onHover = (hov: boolean) => setIsHovered(hov)
  useEffect(() => {
    handleTitleChange(name);
  }, [handleTitleChange, name]);

  useEffect(() => {
    if (checkKeywords && !keywords.noKeywords) {
      let newTitle = title;
      for (let i = 0; i < keywords.risky.length; i++) {
        // @ts-ignore
        newTitle = reactStringReplace(
          newTitle,
          keywords.risky[i],
          (match, j) => (
            <HighlightedKeyword type="risky" word={match} key={match + j + i} onHover={onHover} />
          )
        );
      }
      for (let i = 0; i < keywords.vero.length; i++) {
        // @ts-ignore
        newTitle = reactStringReplace(
          newTitle,
          keywords.vero[i],
          (match, j) => (
            <HighlightedKeyword type="vero" word={match} key={match + j + i} onHover={onHover} />
          )
        );
      }
      setTitle(newTitle);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <span data-tooltip-id={isHovered ? "" : id}>
      {cutLongTitle ? cutTitle(title, cutLongTitle) : title}
    </span>
    {cutLongTitle && name.length > cutLongTitle && !isHovered ? (<Tooltip title={name} anchorId={id} />) : ""}</>;
};

export default ProductTitle;
