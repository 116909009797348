import { lifetime, monthly, yearly } from "assets/data/plans/plans";
import Button from "components/Button";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useGetDefaultCouponQuery, useLazyCheckUpgradeQuery, useLazyUpgradeQuery } from "store/api/managePaymentApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { TSelectedPlan } from "types/plans";
import { SetAccessToken } from "utils/localStorage";
import { makeQueryParams } from "utils/makeQueryParams";
import Modal from ".";

interface Props {
    helpers: ReturnType<useModal>
}
const TrialLimit: FC<Props> = ({ helpers }) => {
    const user = useDecodeToken();
    const dispatch = useAppDispatch();
    const currentPlan = user.package_name || "";
    const plan = [...monthly.planList, ...yearly(false).planList, ...lifetime.planList].find((item) => item.type === currentPlan);
    const selectedPlan: TSelectedPlan = {
        type: plan?.type || "",
        planName: plan?.planName || "",
        planPrice: plan?.planPrice || 0,
        skip: false,
    }
    const { data: defaultCoupon, isFetching: couponLoading } =
        useGetDefaultCouponQuery(selectedPlan.type, {
            refetchOnMountOrArgChange: true,
            skip: !helpers.isOpen || selectedPlan.type === ""
        });
    const navigate = useNavigate();
    const [makeUpgrade, { isFetching: upgradeFetching }] = useLazyUpgradeQuery();
    const [checkUpgrade, { data, isFetching }] = useLazyCheckUpgradeQuery();

    const handleCheck = () => {
        const body = {
            package: currentPlan,
            coupon: defaultCoupon?.message || "",
        }
        checkUpgrade(body)
            .unwrap()
            .then()
            .catch((error) => toast.error(error.data.message));
    }

    const handleUpgrade = () => {
        makeUpgrade({
            type: selectedPlan.type,
            couponName: defaultCoupon?.message || "",
        })
            .unwrap()
            .then((data) => {
                if (data.result.token != null && data.result.token != "") {
                    SetAccessToken(data.result.token);
                    dispatch(setUserAccessToken(data.result.token));
                }
                if (data.result.trial) {
                    navigate(PATH.purchaseComplete.changed);
                } else if (data.result.message === "retry") {
                    navigate(makeQueryParams(PATH.managementPanel.subscriptionPurchase, { type: selectedPlan.type, Retry: true }));
                }
                else {
                    navigate(PATH.purchaseComplete.upgrade);
                }
            })
            .catch((error) => toast.error(error.data));
    };


    useEffect(() => {
        if (defaultCoupon) {
            handleCheck();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCoupon]);


    const handleVatNumberUpdate = () => {
        if (selectedPlan?.type != null) {
            handleCheck()
        }
    }

    return (
        <div>
            <Modal
                helpers={helpers}
                cardClassName="pb-6 pt-[48px] px-6 !border-primaryPurple dark:border-purple500 rounded-[16px]"
                overlay
                buttonCloseClassName="w-6 h-6 top-[24px] right-[24px]"
                maxWidth={"max-w-[698px]"}
            >
                <div className="text-center mb-[23px]">
                    <h5 className="font-medium text-[18px]">Oops!</h5>
                    <h6 className="font-bold text-[20px]">
                        Trial Limit Reached
                    </h6>
                </div>
                <p className={"text-left text-base  mb-[23px] font-medium"}>
                    Dear Trial member you have reached your usage limits.<br />
                    To continue enjoying the full access to ZIK, please Upgrade to a paid plan.
                </p>
                <CheckoutOrderSummary
                    selectedPlan={selectedPlan}
                    isTrialLimit
                    isLoading={isFetching || couponLoading}
                    data={data}
                    handleVatNumberUpdate={handleVatNumberUpdate}
                    wrapperStyles="!border w-full !max-w-[100%] mb-[23px] py-8 !px-8"
                    titleStyles="text-center font-medium"
                    promoOrGiftStyles="!font-bold"
                />
                <div className="flex justify-center">
                    <Button
                        title="Upgrade Now"
                        handleClick={handleUpgrade}
                        loading={upgradeFetching}
                        className={`max-w-[234px] bg-darkBlue text-smoothGreen hover:bg-smoothGreen 
                                hover:border-smoothGreen hover:!text-darkBlue dark:bg-lilac400 dark:!text-darkBlue dark:hover:!bg-paradiseBlue dark:hover:!border-paradiseBlue`}
                        titleClassName="!text-base !font-bold"
                        height="h-[64px]"
                    />
                </div>
            </Modal>
        </div>
    )
}

export default TrialLimit