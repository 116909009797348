import Switch from "components/Input/Switch";
import { HIDE_YEARLY_PERIOD_TITLE } from "config/campaign-settings";
import { FC } from "react";


interface Props {
    selectedPeriod: string;
    handleClick: (tab: string) => void;
    wrapperStyles?: string;
    showTwoPlans?: boolean;
}
const EbayPlanPeriodSelect: FC<Props> = ({ selectedPeriod, handleClick, wrapperStyles, showTwoPlans }) => {
    const cmnTabStyles = (period: "yearly" | "monthly") => `text-lg max-sm:text-base transition-all text-center ${period === "monthly" ? "w-[77px]" : "w-[55px]"}
    ${selectedPeriod === period ? "font-bold text-primaryPurple dark:text-purple500" : "font-medium text-grey300 dark:text-grey100"}`

    return (
        <div className={`flex w-full items-center justify-end gap-2 mx-auto  max-sm:justify-center  max-sm:flex-col py-2 max-lg:px-[60px] max-sm:px-[30px] max-w-[920px] ${wrapperStyles}`}>
            <div className="flex items-center gap-2">
                <button className={cmnTabStyles("monthly")} onClick={() => handleClick("monthly")}>Monthly</button>
                <Switch isChecked={selectedPeriod === "yearly"} handleChange={() => handleClick(selectedPeriod === "monthly" ? "yearly" : "monthly")} noCheckmarkIcon
                    wrapperClassName="transition-all !bg-primaryPurple dark:!bg-purple500" />
                <button className={cmnTabStyles("yearly")} onClick={() => handleClick("yearly")}>Yearly </button>
            </div>
            {HIDE_YEARLY_PERIOD_TITLE ? "" : (
                <div className="w-fit flex  justify-end max-sm:justify-center">
                    <div className="py-1 px-2 text-primaryPurple dark:text-purple500 block w-fit text-sm font-medium bg-grey100 rounded-[4px] max-sm:text-sm max-sm:px-1 max-sm:text-center">
                        Switch to Yearly Plan
                        <br />
                        and Save Big!
                    </div>
                </div>
            )}
        </div>
    )
}

export default EbayPlanPeriodSelect;