import { shopifyPlans } from "assets/data/plans/shopifyPlans";
import Button from "components/Button";
import Card from "components/Card";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import CheckoutCouponInput from "components/Input/CheckoutCouponInput";
import Loading from "components/Loading";
import ShopifyMarketplaceIcon from "components/MarketplaceIcon/Shopify";
import PaymentMethod from "components/PaymentMethod/Index";
import Toaster from "components/Toast";
import useDecodeToken from "hooks/useDecodeToken";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetDefaultCouponQuery, useLazyCheckUpgradeQuery, useLazyPayoneerPurchaseQuery, useLazyPaypalPurchaseQuery } from "store/api/managePaymentApi";
import BillingDetails from "./components/BillingDetails";
import ShopifyBillingHeader from "./components/BillingHeader";

const ShopifyUpgrade = () => {
    const [isCCTAlreadyTrial, setIsCCAlreadyTrial] = useState(false);
    const [ccPaymentLoading, setCCPaymentLoading] = useState(false);
    const [method, setMethod] = useState("");
    const [checkUpgrade, { data, isFetching, isUninitialized }] = useLazyCheckUpgradeQuery();
    const [couponUsed, setCouponUsed] = useState(false);
    const { search } = useLocation();
    const isCurrentTrial = search.includes("isCurrentTrial=true")
    const user = useDecodeToken();
    const [couponValidating, setCouponValidating] = useState(true);
    const findPlan = shopifyPlans.find((item) => isCurrentTrial ? item.type === user.shopify_package_name : item.type !== user.shopify_package_name);
    const selectedPlan = {
        type: findPlan?.type || "",
        planName: findPlan?.name || "",
        planPrice: findPlan?.price || 0,
        skip: false
    }
    const [coupon, setCoupon] = useState("");
    const [paypalPurchase, { isFetching: paypalFetching }] = useLazyPaypalPurchaseQuery();
    const [payoneerPurchase, { isFetching: payoneerFetching }] = useLazyPayoneerPurchaseQuery();
    const { data: defaultCoupon, isFetching: couponLoading } =
        useGetDefaultCouponQuery(selectedPlan.type, { skip: selectedPlan.type === "" });
    const handleCCAlreadyTrial = (isTrial: boolean) => { setIsCCAlreadyTrial(isTrial); setCouponUsed(false) };
    const isTrial = data?.result.isActiveTrial;
    const isFreeTrial = user.shopify_free_trial === "True";
    const showPaymentOptions = !isTrial && isFreeTrial;
    const summaryLoading = isFetching || couponLoading || couponValidating;
    const changeCCPaymentLoading = (isLoading: boolean) => setCCPaymentLoading(isLoading);
    const handleMethod = (type: string) => setMethod(type);
    const changeCouponValidation = (isLoading: boolean) => setCouponValidating(isLoading);
    const handleApplyCoupon = (newVal: string) => {
        setCoupon(newVal);
    };

    const handlePurchase = () => {
        if (method === "") {
            toast.error("Please, select a payment method.");

            return;
        }

        if (method === "pp") {
            paypalPurchase({
                couponName: coupon,
                requestedSubscriptionType: selectedPlan.type,
            })
                .unwrap()
                .then((data) => window.location.replace(data.message));
        }

        if (method === "py") {
            payoneerPurchase({
                couponName: coupon,
                type: selectedPlan.type,
                skipTrial: selectedPlan.skip
            })
                .unwrap()
                .then((data) => window.location.replace(data.message));
        }
    };

    useEffect(() => {
        if (!couponLoading) {
            if (defaultCoupon?.message === "") {
                changeCouponValidation(false)
            }
        }
    }, [defaultCoupon])
    const handleRefetchUpgrade = () => {
        checkUpgrade({
            package: selectedPlan.type,
            coupon: coupon,
        })
            .unwrap()
            .then()
            .catch((error) => {
                toast.error(error.data.message);
            });

    }
    useEffect(() => {
        if (!couponLoading && !couponValidating) {
            handleRefetchUpgrade();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponLoading, couponValidating]);

    return (
        <div>
            <ShopifyBillingHeader />
            <div className="flex gap-5 max-lg:flex-col">
                <Card className="w-full p-8 border-[1px] border-solid border-secondary flex flex-col gap-8 dark:text-grey100 dark:border-grey700">
                    <div className="flex items-center justify-between">
                        <h5 className="font-medium">Billing Details</h5>
                        <ShopifyMarketplaceIcon />
                    </div>
                    {summaryLoading || isUninitialized ? (
                        <div className="w-full h-10 flex justify-center items-center">
                            <Loading fill="fill-primaryPurple" />
                        </div>
                    ) : showPaymentOptions ? (
                        <div>
                            <div className="flex flex-col md:flex-row items-center gap-[29px]">
                                <div className={"h-[100px] px-6 w-full dark:bg-grey100 dark:text-deepSpace900 bg-neutral2 rounded-[15px] flex justify-center flex-col gap-2.5"}>
                                    <div className="flex items-center text-[20px] max-sm:text-base">
                                        <span className={`font-bold capitalize`}>
                                            {selectedPlan.planName}
                                        </span>
                                    </div>
                                    <div className="text-[14px] font-medium">Selected Plan</div>
                                </div>
                            </div>
                            <PaymentMethod
                                alreadyTrial={isCCTAlreadyTrial}
                                className="mt-[18px] mb-[26px]"
                                handleMethod={handleMethod}
                                handleApplyCoupon={handleApplyCoupon}
                                onCCPaymentLoading={changeCCPaymentLoading}
                                selectedPlan={selectedPlan.type}
                                coupon={coupon}
                                isShopify={true}
                                skip={selectedPlan.skip}
                                handleAlreadyTrial={handleCCAlreadyTrial}
                                fromRegistration={couponUsed || isCCTAlreadyTrial}
                                amount={data?.result.totalToday}
                            />
                            {(method === "pp" || method === "py") && (
                                <Button
                                    className="mb-8 w-full mx-auto bg-darkBlue  hover:bg-smoothGreen hover:border-smoothGreen  dark:bg-lilac400 dark:border-lilac400 dark:hover:!bg-paradiseBlue dark:hover:!border-paradiseBlue"
                                    titleClassName="!text-base !font-bold text-smoothGreen group-hover:text-darkBlue dark:text-darkBlue"
                                    height="h-[56px]"
                                    title="Subscribe"
                                    loading={paypalFetching || payoneerFetching || ccPaymentLoading}
                                    handleClick={() => {
                                        handlePurchase();
                                    }}
                                />
                            )}
                        </div>
                    ) : (
                        <BillingDetails selectedPlanType={selectedPlan.type} coupon={coupon} />
                    )}
                    {!couponLoading && (
                        <CheckoutCouponInput
                            couponValidating={changeCouponValidation}
                            handleApplyCoupon={handleApplyCoupon}
                            selectedPlan={selectedPlan.type}
                            handleRefetchUpgrade={handleRefetchUpgrade}
                            preloadCoupon={defaultCoupon?.message}
                        />
                    )}
                </Card>
                <Card className="w-full dark:text-grey100">
                    <CheckoutOrderSummary
                        selectedPlan={selectedPlan}
                        isLoading={summaryLoading}
                        data={data}
                        isShopify
                        wrapperStyles="max-w-full w-full h-full"
                    />
                </Card>

            </div>
            <Toaster />
        </div>
    )
}

export default ShopifyUpgrade;