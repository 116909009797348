const YEAR = 2025;
const MONTH = 1;
const DAY = 31;
const HOUR = 10;

export const DEFAULT_PLAN_PERIOD = "monthly";
export const HIDE_DAYS_COUNTER = true;
export const NO_CAMPAIGN = true;
export const YEARLY_HAS_DISCOUNT = false;
export const MONTHLY_HAS_DISCOUNT = true;
export const LIFETIME_HAS_DISCOUNT = true;
export const HIDE_YEARLY_PERIOD_TITLE = true;

export const MY_SUBSCRIPTION_CAMPAIGN_BG =
  "bg-[linear-gradient(90deg,_#0E0C87_0%,_#180C57_39.5%,_#0E0C87_100%)]";
export const MY_SUBSCRIPTION_NO_CAMPAIGN_BG =
  "bg-[linear-gradient(259deg,_#9E7EF6_0.51%,_#6831FF_40.07%,_#6831FF_69.27%,_#BEA6FF_100%)]";

// do not change
export const CAMPAIGN_END_DATE = Date.UTC(YEAR, MONTH - 1, DAY, HOUR);
