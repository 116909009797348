import AutoDs from "assets/images/autoDs.png";
import SaleFreaks from "assets/images/saleFreaks.png";
import Button from "components/Button";
import useAutoDsConnectFromItemFinder from "hooks/useAutoDsConnectFromItemFinder";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import { IntegrationStepTypes } from "./Index";

interface Props {
    onIntegrationSelect: (val: IntegrationStepTypes) => void;
}
interface IntegrationProps {
    type: IntegrationStepTypes;
    onSelect: (val: IntegrationStepTypes) => void;
    isSelected?: boolean;
}
const imageStyles = {
    autoDS: "w-[75px]",
    saleFreaks: "w-[70px]"
}
const Integration: FC<IntegrationProps> = ({ type, onSelect, isSelected }) => {
    return (
        <div
            className={` 
            ${isSelected ? "bg-neutral2 border-primaryPurple dark:bg-lilac400 dark:text-purple500 dark:border-purple500" :
                    "bg-neutral1 border-neutral1 dark:bg-black900 dark:border-black900 dark:hover:border-purple500 dark:hover:bg-lilac400 dark:hover:text-purple500 hover:bg-neutral2 hover:border-primaryPurple"}
            h-[140px] max-w-[360px] w-full rounded-[16px] border-2  transition-all cursor-pointer`}
            onClick={() => onSelect(type)}
        >
            <div className="w-full flex flex-col gap-1 justify-center items-center h-full">
                <div className="rounded-full p-2 bg-white">
                    <img src={type === "autoDS" ? AutoDs : SaleFreaks} alt={`${type === "autoDS" ? "auto-ds" : "sale freaks"} image`}
                        className={`${(type === "autoDS" || type === "saleFreaks") && imageStyles[type]} h-[18px]`} />
                </div>
                <p>Connect</p>
            </div>
        </div>
    )
}

const ChooseIntegration: FC<Props> = ({ onIntegrationSelect }) => {
    const onAutoDsSuccess = () => onIntegrationSelect("success");
    const autoDsConnect = useAutoDsConnectFromItemFinder(onAutoDsSuccess);
    const [selected, setSelected] = useState<IntegrationStepTypes>(null);
    const onSelect = (val: IntegrationStepTypes) => setSelected(val);

    const onNext = () => {
        if (selected === null) {
            toast.error("Please select Integration type")
        } else {
            if (selected === "autoDS") {
                autoDsConnect();
            } else {
                onIntegrationSelect("saleFreaks")
            }
        }
    }

    return (
        <div className="w-full text-center">
            <h6 className="mb-2 text-xl font-bold">Choose your Integration</h6>
            <p className="mb-8 text-xl font-medium">Integrate with a listing tool to add products to your store</p>
            <div className="flex gap-8 w-full justify-center mb-8">
                <Integration type="autoDS" onSelect={onSelect} isSelected={selected === "autoDS"} />
                <Integration type="saleFreaks" onSelect={onSelect} isSelected={selected === "saleFreaks"} />
            </div>
            <Button title="Next" className="max-w-[232px] dark:bg-deepSpace900" variant="outlined" height="h-[60px]" titleClassName="!font-bold !text-base" handleClick={onNext} />
        </div>
    )
}

export default ChooseIntegration
