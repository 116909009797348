import { ReactComponent as IconPointer } from "assets/icons/pe-pointer.svg";
import img from "assets/images/pe-regular.png";
import Button from "components/Button";

interface Props {
    handleClick: (val: string, type: "explore" | "skip") => void
}
const PeRegularPopup = ({ handleClick }: Props) => {
    const btnSameStyles = "max-w-[180px] w-full dark:!text-darkBlue dark:hover:!text-white"

    return (
        <div className="max-w-[698px] w-full rounded-2xl outline outline-[3px]  outline-primaryPurple bg-white p-6 dark:text-darkBlue">
            <div className="flex flex-col items-center gap-6">
                <div>
                    <div className="text-xl font-medium text-center mb-2">New Product Launch:</div>
                    <div className="text-xl font-bold text-center relative">
                        eBay Product Explorer!
                        <IconPointer className="absolute left-[calc(100%+5px)] top-[50%] translate-y-[-50%] drop-shadow-[0px_12px_42px_rgba(20,23,26,0.12),0px_8px_18px_rgba(20,23,26,0.12)]" />
                    </div>
                </div>
                <img className="max-w-[566px] w-full h-auto mx-auto drop-shadow-[0px_4px_100px_rgba(96,122,154,0.30)]" src={img} />
                <div className="font-medium">Use ZIK’s <span className="font-bold">eBay Product Explorer</span> to uncover top-selling products and trending items for your store. Optimize your inventory and grow your business effortlessly!</div>
                <div className="flex items-center justify-center gap-4 w-full">
                    <Button handleClick={() => handleClick("regular", "explore")} height="h-11" title="Explore Now!" color="smoothGreen"
                        className={btnSameStyles}
                    />
                    <Button handleClick={() => handleClick("regular", "skip")} height="h-11" title="Skip for now"
                        className={`${btnSameStyles} !border-transparent dark:hover:!bg-primaryPurple`} />
                </div>
            </div>
        </div>
    )
}

export default PeRegularPopup