import { FC } from "react";

import InfoTooltip from "components/Card/InfoTooltip";
import Checkbox from "components/Input/Checkbox";
import getSymbolFromCurrency from "currency-symbol-map";

interface Props {
  options?: {
    className?: string, value: string;
    text: string;
    toolTip?: string;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    iconClassName?: string;
    checkedIconClassName?: string;
  }[];
  iconWrapperClassName?: string;
  value: string | string[] | any;
  multiselect?: boolean;
  handleChange: (newValue: string | number) => void;
  className?: string;
  optionClassName?: string;
  rectangleCheckbox?: boolean
  optionLabelClassName?: string;
  selectedCheckmarkClassName?: string;
  checkboxClassName?: string;
  checkmarkClassName?: string;
  isCurrency?: boolean;
  dividerAfter?: number;
  maxCheckedAndDisableAfter?: number;
  disabledOptionClassName?: string;
}

const FilterCheckbox: FC<Props> = ({ options, value, multiselect, selectedCheckmarkClassName, disabledOptionClassName, handleChange, rectangleCheckbox, className, optionClassName, optionLabelClassName, iconWrapperClassName, checkboxClassName, checkmarkClassName, isCurrency, dividerAfter, maxCheckedAndDisableAfter }) => (
  <div className={`flex flex-col gap-[7px] ${className || ""}`}>
    {options?.map((option, index) => {
      const isChecked = option.value === "" ? false : multiselect
        ? Boolean(value?.includes(option.value))
        : option.value === value;

      const isDisabled = (multiselect && maxCheckedAndDisableAfter && value.constructor === Array && value.length === maxCheckedAndDisableAfter && !Boolean(option.value?.includes(option.value))) || option.value === "";

      return (
        <div className={`flex items-center flex-col gap-[3px] ${option.className || ""} ${optionClassName || ""}`}
          key={option.value === "" ? option.text : option.value.toString()}
        >
          <label
            htmlFor={option.value.toString()}
            className={`${optionLabelClassName || ""} flex items-center text-sm justify-between cursor-pointer border-2 rounded-full py-[6.5px] 
            ${rectangleCheckbox ? "px-4" : "pr-2 pl-5"} w-full dark:border-transparent ${isChecked
                ? "dark:!bg-lilac400 !bg-darkBlue !border-darkBlue text-white dark:!text-deepSpace900"
                : `bg-neutral2 border-neutral2 dark:hover:bg-purple500 dark:hover:border-purple500 hover:bg-primaryPurple hover:border-primaryPurple hover:text-white max-lg:hover:bg-neutral2
              max-lg:hover:border-neutral2 max-lg:hover:text-darkBlue dark:bg-deepSpace800 dark:border-deepSpace800 dark:text-grey100`
              } ${isDisabled ? `!bg-grey100 !text-neutral3 dark:!bg-grey700  !pointer-events-none ${disabledOptionClassName}` : ""} group`}
          >
            <div className="flex items-center gap-[5px]">
              {option.icon && (
                <div className={`flex items-center justify-center w-[24px] h-[24px] ${iconWrapperClassName || ""}`}>
                  <option.icon className={`${option.iconClassName || ""} ${isChecked ? `text-white dark:text-deepSpace900 ${option.checkedIconClassName}` : ""}`} />
                </div>
              )}
              <p>{option.text}{isCurrency && ` (${option.value === "CYP" ? getSymbolFromCurrency("EUR") : getSymbolFromCurrency(option.value)})`}</p>
            </div>

            <Checkbox
              checked={isChecked}
              rectangleCheckbox={rectangleCheckbox}
              value={option.value}
              id={option.value}
              checkboxClassName={`${isChecked ? rectangleCheckbox ? "border-primaryPurple" : `hover:border-darkBlue ${selectedCheckmarkClassName}` : "dark:!bg-deepSpace800 hover:dark:border-lilac400"} ${checkboxClassName} ${isDisabled ? "!bg-grey100 !border-grey300 dark:!bg-grey700" : ""}`}
              handleChange={() => handleChange(option.value)}
              className={checkmarkClassName}
            />
          </label>
          {option.toolTip &&
            <InfoTooltip title={option.toolTip} />
          }
          {
            dividerAfter && dividerAfter - 1 === index && (
              <div className="h-[2px] bg-secondary dark:bg-grey700 w-full rounded-full my-[4px]"></div>
            )
          }
        </div>
      );
    })}
  </div>
);
export default FilterCheckbox;
