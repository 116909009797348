import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { useAddStoreMutation } from "store/api/quickSettingsApi";
import { makeQueryParams } from "utils/makeQueryParams";
import { object, string } from "yup";
import { IntegrationStepTypes } from "./Index";

interface Props {
    onIntegrationSelect: (step: IntegrationStepTypes) => void;
}
interface FormValues {
    storeName: string;
    token: string;
}

let registerSchema = object({
    storeName: string().required("First Name is Required"),
    token: string().required("Last Name is Required"),
});

const inputContClassName = "flex flex-col gap-2 w-full max-w-[365px]"
const labelStyles = "text-base font-medium leading-[24px] max-md:text-sm"
const inputClassName = "h-14 border-2 px-[17px] w-full py-[5px] dark:bg-deepSpace900 rounded-[7px] bg-white !h-11 max-md:h-11";

const SaleFreaksIntegration: FC<Props> = ({ onIntegrationSelect }) => {
    const [addStore, { isLoading: addLoading }] = useAddStoreMutation();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(registerSchema),
    })


    const onSubmit: SubmitHandler<FormValues> = async (values) => {
        addStore({
            store: values.storeName,
            token: values.token,
            type: "1",
            userId: 0,
        })
            .unwrap()
            .then(() => {
                onIntegrationSelect("success");
            });
    };

    return (
        <div className="flex flex-col items-center">
            <h6 className="mb-2 text-xl font-bold">SaleFreaks Integration</h6>
            <div className="mb-8 text-xl font-medium text-center">
                <span>Please enter your SaleFreaks token and store name.</span><br />
                <Link target="_blank" to={makeQueryParams(PATH.quickSettings.root, { tab: "integrations" })} className="underline text-electricBlue hover:text-primaryPurple dark:text-lilac400 dark:hover:text-purple500">Get your token from SaleFreaks settings</Link>
            </div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                className="flex justify-center items-center flex-col max-w-[1312px] w-full mx-auto max-md:mb-[20px]"
            >
                <div className="flex justify-center gap-6 w-full mb-8">
                    <div className={inputContClassName}>
                        <label htmlFor="storeName" className={labelStyles}>Store name</label>
                        <ControlledInput<FormValues>
                            className={inputClassName}
                            placeholder="Type your store name"
                            name="storeName"
                            register={register}
                            type="text"
                            variant="contained"
                            inputClassName="autofill:bg-transparent"
                            errors={errors}
                        />
                    </div>
                    <div className={inputContClassName}>
                        <label htmlFor="token" className={labelStyles}>Add Token</label>
                        <ControlledInput<FormValues>
                            className={inputClassName}
                            placeholder="Add your token"
                            name="token"
                            register={register}
                            type="text"
                            variant="contained"
                            inputClassName="autofill:bg-transparent"
                            errors={errors}
                        />
                    </div>
                </div>

                <Button
                    title="Connet to SaleFreaks"
                    className="max-w-[300px] dark:bg-deepSpace900"
                    variant="outlined" height="h-[60px]"
                    titleClassName="!font-bold !text-base"
                    color="smoothGreen"
                    type="submit"
                    loading={addLoading}
                />
            </form>
        </div>
    )
}

export default SaleFreaksIntegration;