import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify-text.svg";
import ProductNameCell from "components/ProductNameCell";
import { FC } from "react";
import { CURRENCY } from "types/currency";
import { IconFinderItem } from "types/services/iconFinder";
import { CURRENCY_SIGNS, formatCurrency, formatNumber } from "utils/formatCurrency";
import { ItemFinderMarketplace } from ".";
import AliItemSteps from "./AliItemSteps";
import FindItemBy from "./FindItemBy";

interface Props {
    item: IconFinderItem;
    amazonDomain?: string;
    currency?: CURRENCY_SIGNS;
    marketplace: ItemFinderMarketplace;
    similarLoading?: boolean;
    type?: "ebay" | "shopify";
}


const ItemFinderSelectedItem: FC<Props> = ({ item, currency, type, amazonDomain, marketplace, similarLoading }) => {
    const horLineStyles = "bg-secondary dark:bg-deepSpace900 w-full rounded-full h-[2px]"
    const isBaba = marketplace === "Baba";
    const isAli = marketplace === "Ali";
    const isCjd = marketplace === "Cjd"

    return (
        <div className="bg-white rounded-[10px] border-2 border-secondary dark:border-grey600 p-[14px] dark:bg-black900">
            <div className="flex flex-col items-start gap-4 mb-4">
                <div className="flex items-center gap-4 h-9">
                    {type === "shopify" ?
                        <Shopify className="fill-[#549C28] w-12 h-4" /> :
                        <Ebay className="w-10 h-[17px]" />}
                    <p className="text-xs font-medium">
                        Product Search by Image
                    </p>
                </div>
                <img src={item.img} alt={item.title} className="max-w-[183px] max-h-[200px] h-auto mx-auto shadow-shadow1 rounded-[7px]" />
            </div>
            <div>
                <ProductNameCell name={item.title} checkKeywords url={item.url} titleClassName="text-xs" className="mb-2" noIcons />
                <div className={horLineStyles} />
                <div className="flex flex-col text-sm py-2 gap-2 text-xs">
                    <div className="w-full flex justify-between">
                        <p>Price</p>
                        <p className="font-bold">
                            {formatCurrency(
                                item.price,
                                CURRENCY.none,
                                currency || "$"
                            )}
                        </p>
                    </div>
                    <div className="w-full flex justify-between">
                        <p>Sold</p>
                        <p className="font-bold">{formatNumber(String(item.sold))}</p>
                    </div>
                </div>
                <div className={`${horLineStyles} mb-4`} />
                {isAli || isBaba || isCjd ? (
                    <AliItemSteps similarLoading={similarLoading} marketplace={marketplace} />
                ) : ""}
                <FindItemBy selectedItem={item} amazonDomain={amazonDomain} />
            </div>

        </div>

    );
};

export default ItemFinderSelectedItem;
