import { Row } from "@tanstack/react-table";
import { TableBodyStyles } from "./Table";
import TableBodyCell from "./TableBodyCell";

interface Props<T extends object> {
  row: Row<T>;
  styles?: TableBodyStyles;
  alignBodyLeft?: boolean;
  isDisabled?: boolean;
}

const TableBodyRow = <T extends object>({ row, styles, isDisabled, alignBodyLeft }: Props<T>) => (
  <tr
    className={`
      ${styles?.row || ""}
    `}
  >
    {row.getVisibleCells().map((cell, i, colRow) => (
      <TableBodyCell<T>
        styles={styles}
        cell={cell}
        isDisabled={isDisabled}
        alignBodyLeft={alignBodyLeft}
        isFirstCol={i === 0}
        key={cell.id}
        isLastCol={i + 1 === colRow.length}
        previousCellExtracted={
          row?.getVisibleCells()?.[i - 1]?.column?.columnDef?.meta?.extract
        }
      />
    ))}
  </tr>
);

export default TableBodyRow;
