import { ReactComponent as Ebay } from "assets/icons/ebay.svg"
import { ReactComponent as FreeTrialCroOval } from "assets/icons/free-trial-cro-oval.svg"
import { ReactComponent as Shopify } from "assets/icons/shopify.svg"
import CroTrustpilot from "assets/images/cro-trustpilot.png"
import LiorPozin from "assets/images/lior-pozin.png"
import PaulLipsky from "assets/images/paul-lipsky.png"
import Button from "components/Button"
import Modal from "components/Modal"
import TimeCounter from "components/Plans/DaysCounter/TimeCounter"
import dayjs from "dayjs"
import { useAppSelector } from "hooks/useAppSelector"
import useDecodeToken from "hooks/useDecodeToken"
import useModal from "hooks/useModal"
import { FC, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useNavigate } from "react-router-dom"
import { PATH } from "routes/path"
import { formatCurrency } from "utils/formatCurrency"
import { GetAccessToken, GetFreeTrialPopupSeen, SetFreeTrialPopupSeen } from "utils/localStorage"
import { makeQueryParams } from "utils/makeQueryParams"
import BulletPoints from "./BulletPoints"
import PeriodSelect from "./PeriodSelect"

interface Props {
    isShopify?: boolean;
}
const shopifyComment = {
    title: "“ZIK is a game-changer for product research. Our customers have seen amazing results. I highly recommend it!”",
    author: "Lior Pozin",
    authorRole: "AutoDS Co-Founder & CEO"
}
const ebayComment = {
    title: "“I started using ZIK 4 years ago and found it to be the game changer of my eBay business.”",
    author: "Paul Lipsky",
    authorRole: "Industry Expert & eBay Seller"
}
const FreeTrialsCro: FC<Props> = ({ isShopify }) => {
    const [cookie, setCookie] = useCookies(['free-trial-cro', 'shopify-free-trial-cro'])
    const cro = cookie[isShopify ? "shopify-free-trial-cro" : "free-trial-cro"];
    const [selectedPeriod, setSelectedPeriod] = useState<"monthly" | "yearly">("monthly");
    const isMonthly = selectedPeriod === "monthly"
    const user = useDecodeToken();
    const { accessToken } = useAppSelector((state) => state.user.result);
    const token = accessToken || GetAccessToken();
    const deadline = new Date(user[isShopify ? "shopify_next_payment_date" : "next_payment_date"]);
    const difference = dayjs(deadline).diff(dayjs(), "hour");
    const left24Hours = difference < 24;
    const left3Days = difference < 72;

    const maxSaveAmount = isShopify ? 100 : 500;
    const navigate = useNavigate();
    const modal = useModal();
    const handleClick = () => {
        const attempt = cro?.attempt || 0;
        const threeDays = Date.now() + (72 * 60 * 60 * 1000);
        const cookieValue = {
            attempt: attempt + 1,
            token,
        };
        if (left24Hours) {
            SetFreeTrialPopupSeen();
        } else {
            setCookie(isShopify ? "shopify-free-trial-cro" : "free-trial-cro", JSON.stringify(cookieValue), {
                path: "/",
                expires: new Date(threeDays),
            });
        }
        modal.close();
    }

    const explorePlans = () => {
        const ebayPath = makeQueryParams(PATH.managementPanel.billing, { plan: selectedPeriod });
        const shopifyPath = makeQueryParams(PATH.managementPanel.subscriptionPurchase, { isShopify: true, type: isMonthly ? "ShopProM" : "ShopProY" })
        handleClick();
        navigate(isShopify ? shopifyPath : ebayPath);
    }
    const continueTrial = () => {
        handleClick();
    }
    useEffect(() => {
        const attempt = cro?.attempt || 0;
        const prevToken = cro?.token;
        const isFirstAttempt = attempt === 0
        const checkOncePerLogin = isFirstAttempt || (prevToken && attempt < 3 && token !== prevToken);
        const showCroModal = left24Hours ? GetFreeTrialPopupSeen() !== "true" : (left3Days && checkOncePerLogin);
        if (showCroModal) {
            setTimeout(() => {
                modal.open()
            }, 2000)
        }
    }, [difference]);

    const comment = isShopify ? shopifyComment : ebayComment
    const onSelectPeriod = (per: "monthly" | "yearly") => setSelectedPeriod(per);

    return (
        <Modal shouldCloseOnOverlayClick={false} overlay overlayColor="rgb(13 13 13 / 50%)" maxWidth="max-w-[888px] max-md:max-w-[600px]" disableDarkMode
            cardClassName="rounded-2xl border-[3px] !border-primaryPurple p-6 dark:bg-white " hideCloseIcon helpers={modal}
        >
            <div className="flex gap-6 flex h-full max-md:flex-col max-md:items-center max-md:overflow-y-auto dark:text-darkBlue">
                <div className={`max-w-[300px] h-full w-full bg-neutral2 border border-secondary rounded-[16px] pt-4 font-medium relative overflow-hidden ${isShopify ? "min-h-[380px]" : "min-h-[348px]"}`}>
                    <p className={`text-center text-lg leading-[28px] mb-2 ${left24Hours ? "text-errorRed" : ""}`}>Your Free Trial ends {left24Hours ? "today!" : "in:"}</p>
                    <div className="py-[3px] px-[11px] border border-smoothGreen rounded-[8px] bg-white w-fit mx-auto mb-[17px]">
                        <TimeCounter deadline={deadline} hideDays={left24Hours} periodAmountStyle="!font-bold !mb-[0px]" dotColor="bg-grey200" periodTitleColor="text-grey900" />
                    </div>
                    <div className="bg-white border border-primaryPurple rounded-[8px] max-w-[257px] ml-[auto] mr-2 py-[7px] px-[15px]">
                        <p className="text-xs font-normal mb-2">
                            {comment.title}
                        </p>
                        <div className="ml-[auto] text-[10px] text-right">
                            <p className="text-primaryPurple font-semibold">{comment.author}</p>
                            <p className="font-[300]">{comment.authorRole}</p>
                        </div>
                    </div>
                    <img src={CroTrustpilot} alt="cro-trastpilot" className="max-w-[153px] w-full h-[25px] !block border-smoothGreen border rounded-[5px] mt-[26px] z-[10] absolute right-[7px]" />
                    {isShopify ? (
                        <img src={LiorPozin} alt="lior-pozin" className="absolute bottom-[10px] left-[0px] w-[120px] h-[150px] z-[10]" />
                    ) : (
                        <img src={PaulLipsky} alt="paul-lipsky" className="absolute bottom-[0px] left-[0px] w-[167px] h-[251px]" />
                    )}
                    {isShopify ? <FreeTrialCroOval className="absolute bottom-[0px] left-[5px] z-[0]" /> : ""}
                </div>
                <div className="h-full w-full">
                    <div className="flex items-center justify-between mb-4">
                        <p><span className="font-bold">Enjoyed your Trial?</span> Save Up to {formatCurrency(maxSaveAmount)} today!</p>
                        <div className={`flex justify-center items-center h-8 rounded-full bg-neutral2 w-full ${isShopify ? "max-w-[84.45px]" : "max-w-[62px]"}`}>
                            {isShopify ? (
                                <Shopify className="w-[64.45px] h-[16.74px]" />
                            ) : (
                                <Ebay className="w-[41px] h-[21.75px]" />
                            )}
                        </div>
                    </div>
                    <PeriodSelect selected={selectedPeriod} onSelect={onSelectPeriod} isShopify={isShopify} />
                    <BulletPoints isShopify={isShopify} isMonthly={isMonthly} />
                    <div className="h-[2px] w-full bg-secondary rounded-full mb-4 mt-4" />
                    <div className="flex gap-4 mb-2">
                        <Button title={isShopify ? "Upgrade Now!" : "Explore Plans!"} height="h-11" color="smoothGreen" className="!shadow-greenShadow"
                            titleClassName="!text-base dark:text-darkBlue dark:group-hover:text-white" handleClick={explorePlans} />
                        <Button title="Continue Free Trial" height="h-11" className="border-none"
                            titleClassName="!text-base dark:text-darkBlue dark:group-hover:text-white" handleClick={continueTrial} />
                    </div>
                    <p className="text-sm leading-[20px] text-center">Upgrade now for powerful tools and incredible value</p>
                </div>
            </div>
        </Modal>
    )
}

export default FreeTrialsCro