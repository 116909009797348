import { CAMPAIGN_END_DATE, HIDE_DAYS_COUNTER } from "config/campaign-settings";
import dayjs from "dayjs";
import { FC } from "react";
import TimeCounter from "./TimeCounter";

interface Props {
    isDarkBg?: boolean;
    pulsing?: boolean;
    isProTools?: boolean;
    fromRegFunnel?: boolean;
}

const deadline = new Date(CAMPAIGN_END_DATE);

const DaysCounter: FC<Props> = ({ isDarkBg, pulsing, isProTools, fromRegFunnel }) => {
    const hideCounter = dayjs().isAfter(deadline) || HIDE_DAYS_COUNTER

    const formatNumber = (num: number) => {
        if (num < 10) {
            return `0${num}`;
        } else {
            return num;
        }
    };
    const daysColor = isDarkBg && !isProTools ? "!text-canaryYellow" : "";
    const dotColor = fromRegFunnel || isProTools ? "bg-yellow" : "bg-electricBlue"
    const periodTitleColor = isDarkBg ? "text-white" : "text-grey900 dark:text-grey100"

    return hideCounter ? <div /> : (
        <div
            className={`justify-center gap-2 items-center flex ${isDarkBg ? "text-white" : "dark:text-grey100 text-deepSpace900"} ${pulsing ? "animate-[counterPulse_1.5s_infinite] max-w-[300px] mx-auto" : ""}`}
        >
            <p className={`text-center text-sm leading-[20px] font-normal ${isProTools ? "text-yellow" : isDarkBg ? "" : "text-darkBlue"}`}> Offer Ends:</p>
            <TimeCounter deadline={deadline} daysNumColor={daysColor} daysTitleColor={daysColor} dotColor={dotColor} periodTitleColor={periodTitleColor} />
        </div>
    )
}

export default DaysCounter;