import { ReactComponent as Info } from "assets/icons/info.svg";
import { FC, useId } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "styles/info.css";

interface InfoTooltipProps {
  title: string;
  className?: string;
  iconClassName?: string;
  maxWidth?: string;
  place?: "top" | "right" | "bottom" | "left";
  children?: string | JSX.Element;
}

const InfoTooltip: FC<InfoTooltipProps> = ({
  title,
  className,
  iconClassName,
  maxWidth,
  place,
  children,
}) => {
  const anchorId = useId();

  return (
    <>
      <div
        data-tooltip-id={anchorId}
        data-tooltip-content={title}
        data-tooltip-place={place}

        className={`${className || ""} cursor-pointer`}
        id={anchorId}

      >
        {children ? children : <Info className={`${iconClassName} info-icon`} />}
      </div>
      <Tooltip
        id={anchorId}
        className={`bg-darkBlue opacity-100 px-2.5 pt-2.5 pb-3 rounded-10 !z-[9999] dark:bg-grey700 dark:text-grey100 ${maxWidth || " max-w-[300px]"} text-[10px]`}

      />
    </>
  );
};

export default InfoTooltip;