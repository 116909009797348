import BillingBackButton from "components/Plans/BackButton";
import PlansDiscountTxt from "components/Plans/DiscountTxt";
import PlanSwitch from "components/Plans/PlanSwitch";
import SlidedPlansWithComparison from "components/Plans/SlidedPlansWithComparison";
import SlidedShopifyWithComparison from "components/Plans/SlidedShopifyWithComparison";
import { DEFAULT_PLAN_PERIOD } from "config/campaign-settings";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import usePlans from "hooks/usePlans";
import LoadingPage from "pages/Loading";
import SelectMarketplace from "pages/Shopify/Billing/components/SelectMarketplace";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { makeQueryParams } from "utils/makeQueryParams";
interface Props {
    paymentStatus?: number;
}
const Upgrade = ({
    paymentStatus,
}: Props) => {
    const { search } = useLocation();
    const isShopifyActive = search.includes("marketplace=shopify")
    const [activeTab, setActiveTab] = useState<"ebay" | "shopify">(isShopifyActive ? "shopify" : "ebay");
    const user = useDecodeToken();
    const { plans, handlePlanChange } = usePlans(DEFAULT_PLAN_PERIOD);
    const onMarketplaceChange = (tab: "ebay" | "shopify") => setActiveTab(tab);
    const { data, isLoading } = useGetUserPlanQuery(null);
    const shopifyPlan = data?.result.find((item) => item.isShopify);
    const currentPlan = user.package_name || "";
    const shopifyIsNotActive = shopifyPlan?.isCanceled || user.shopify_package_name === "";
    const shopifyAlreadyTrial = user.shopify_already_trial === "1"
    const ebayPayNow = paymentStatus === 0;
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const ebayPlan = data?.result.find((item) => !item.isShopify)
    const isCanceled = ebayPlan?.isCanceled;
    const isTrial = /^true$/i.test(user.active_trail);
    const handleSelectPlan = (newVal: TSelectedPlan, isShopify?: boolean) => {
        dispatch(onSelectPlan(newVal));
        const isEbayPurchase = (paymentStatus === 0 || isCanceled) && !isTrial;
        const redirLink = PATH.managementPanel[(isShopify ? shopifyIsNotActive : isEbayPurchase) ? "subscriptionPurchase" : "subscriptionUpgrade"]
        navigate(makeQueryParams(redirLink, {
            type: newVal.type,
            ...(newVal.skip && { skip: true }),
            ...(isShopify && { isShopify: true })
        }));
    };

    return (
        <LoadingPage loading={isLoading}>
            <div className="flex justify-center flex-col items-center" >
                <BillingBackButton>
                    <div className="flex flex-col gap-4 w-full">
                        <h4 className="text-center text-lg font-medium dark:text-grey100">Pick your Plan</h4>
                        <h6 className="text-center font-bold dark:text-grey100">Select your Marketplace</h6>
                        <SelectMarketplace marketplace={activeTab} onMarketplaceChange={onMarketplaceChange} />
                        <PlansDiscountTxt activePlans={activeTab} period={plans.period} showYearlyTxt />
                    </div>
                </BillingBackButton>
                {activeTab === "ebay" ? (
                    <div className="flex justify-center items-center flex-col w-full">
                        <PlanSwitch
                            activePlan={plans.period}
                            handlePlanChange={handlePlanChange}
                            shortSwitchStyles="py-6 max-w-[1010px]"
                        />
                        <SlidedPlansWithComparison
                            plans={plans}
                            handleSelectPlan={handleSelectPlan}
                            isPayNow={ebayPayNow}
                        />
                    </div>
                ) : <div className="w-full pt-4">
                    <SlidedShopifyWithComparison
                        isPayNow={shopifyIsNotActive && shopifyAlreadyTrial}
                        fromRegistration={shopifyIsNotActive && !shopifyAlreadyTrial}
                        handleSelectPlan={(newVal) => handleSelectPlan(newVal, true)} />
                </div>}
            </div>
        </LoadingPage>
    );
};
export default Upgrade;