import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as SelectMenu } from "assets/icons/menu.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import { ReactComponent as Truck } from "assets/icons/truck.svg";
import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import Select from "components/Select";
import useModal from "hooks/useModal";
import { exclude } from "pages/ZikPro/AutopilotScan";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useUploadFolderItemsMutation,
  useUploadProductsMutation
} from "store/api/productManagementApi";
import {
  useGetBulkStoresQuery,
  useGetUploadSettingsQuery
} from "store/api/quickSettingsApi";
import { FolderFilters } from "types/services/productMangement";
import { AddToStoreType } from "./ConnectLister";

interface Props {
  type: "Turbo" | "AutoPilot" | "Folder";
  onShowAddChange: (type: AddToStoreType) => void;
  selectedItems: number[];
  allChecked: boolean;
  folderFilters?: FolderFilters;
  helpers: ReturnType<useModal>;
  onUploadProgress: () => void;
}

const types = [
  {
    value: "AutoDS",
    text: "AutoDS",
  },
  {
    value: "Salefreaks",
    text: "Sale Freaks",
  },
];
const title_source = (type: "Turbo" | "AutoPilot" | "Folder") =>
  type !== "Turbo"
    ? [
      { value: "supplier", text: "Supplier" },
      { value: "eBay", text: "eBay" },
    ]
    : [{ value: "supplier", text: "Supplier" }];
const marketplace = [
  { value: "US", text: "eBay.com" },
  { value: "UK", text: "eBay.co.uk" },
];

const UploadListingsForm: FC<Props> = ({
  type,
  selectedItems,
  folderFilters,
  allChecked,
  onShowAddChange,
  helpers,
  onUploadProgress,
}) => {
  const [exportFolder, setExportFolder] = useState<string[]>([]);
  const { id } = useParams();
  const { data: bulkStores } = useGetBulkStoresQuery(undefined, {
    skip: !helpers.isOpen,
  });
  const result = bulkStores?.result;
  const saleFreakStores = result?.salesFreak.map((item) => ({
    value: item.bulkStoreId.toString(),
    text: item.storeName,
  }));

  const autoDsStores =
    result && result?.autoDS.length > 0
      ? result?.autoDS[0].autoDsStores.map((item) => ({
        text: item.store.name,
        value: item.store.id.toString(),
      }))
      : [];

  const [formValues, setFormValues] = useState({
    lister: "",
    adStore: "",
    sfStore: "",
    marketplace: "",
    source: "",
  });

  const errMsg = result?.errorMessage;
  const isAutoDS = formValues.lister === "AutoDS"
  const errType = isAutoDS ? (errMsg === "error_400" ? "400" : errMsg === "error_401" ? "401" : false) : false;

  const [uploadProducts, { isLoading: uploadLoading }] =
    useUploadProductsMutation();
  const [uploadFolderItems, { isLoading: uploadFolderLoading }] =
    useUploadFolderItemsMutation();

  const { data: uploadSettings, refetch } = useGetUploadSettingsQuery(undefined, {
    skip: !helpers.isOpen,
  });

  const onUploadSuccess = () => {
    helpers.close();
    onUploadProgress();
    toast.success(
      allChecked
        ? "All Products Upload in progress"
        : "Selected Products Upload in progress"
    );
  };
  useEffect(() => {
    if (helpers.isOpen) {
      refetch();
    }
  }, [helpers.isOpen, refetch]);
  useEffect(() => {
    if (result && uploadSettings?.result) {
      const defaultSettings = uploadSettings?.result;
      var supplierTitleSource = defaultSettings.tittleSource === "Supplier" ? "supplier" : defaultSettings.tittleSource;

      var defaultMarketPlace = defaultSettings.marketplace ? marketplace.find(x => x.value === defaultSettings.marketplace)?.value : marketplace[0].value;
      setFormValues({
        lister: defaultSettings.lister || types[0].value,
        marketplace: defaultMarketPlace || "",
        source: supplierTitleSource || title_source(type)[0].value,
        adStore: defaultSettings.store || autoDsStores?.[0]?.value || "",
        sfStore: saleFreakStores?.[0]?.value || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, uploadSettings]);

  const onUploadListings = () => {
    const selectedStore = autoDsStores?.find(
      (item) => item.value === formValues.adStore
    );
    const selectedSaleFreak = result?.salesFreak?.find(
      (item) => item.bulkStoreId.toString() === formValues.sfStore
    );

    if (formValues.source === null || formValues.source === "") {
      toast.error("Please Select a Title Source");

      return;
    }

    if (formValues.marketplace === null || formValues.marketplace === "") {
      toast.error("Please Select a Marketplace");

      return;
    }

    if (errType !== false) {

      return;
    }

    const payload = {
      type: formValues.lister,
      amazonSite: formValues.marketplace,
      singleScannedItemIds: allChecked ? [] : selectedItems,
      allChecked,
      token: selectedSaleFreak?.token || "",
      storeId: Number(selectedStore?.value) || 0,
      storeName: selectedStore?.text || "",
      title: formValues.source,
      isRestrictVeroWords: Boolean(exportFolder.includes("vero")),
      isRestrictRiskyWords: Boolean(exportFolder.includes("restricted")),
    };

    if (type === "Folder" && folderFilters) {
      uploadFolderItems({
        folderId: Number(id),
        ...(folderFilters.ebayMaxPrice !== 0 && {
          ebayMaxPrice: folderFilters.ebayMaxPrice,
        }),
        ...(folderFilters.ebayMinPrice !== 0 && {
          ebayMinPrice: folderFilters.ebayMinPrice,
        }),
        ...(folderFilters.multiMinPrice !== 0 && {
          multiMinPrice: folderFilters.multiMinPrice,
        }),
        ...(folderFilters.multiMaxPrice !== 0 && {
          multiMaxPrice: folderFilters.multiMaxPrice,
        }),
        ...(folderFilters.minProfit !== 0 && {
          minProfit: folderFilters.minProfit,
        }),
        ...(folderFilters.maxProfit !== 0 && {
          maxProfit: folderFilters.maxProfit,
        }),
        ...(folderFilters.minSales !== 0 && {
          minSales: folderFilters.minSales,
        }),
        ...(folderFilters.maxSales !== 0 && {
          maxSales: folderFilters.maxSales,
        }),
        ...(folderFilters.searchText !== "" && {
          searchText: folderFilters.searchText,
        }),
        ...payload,
      })
        .unwrap()
        .then(() => onUploadSuccess());
    } else {
      uploadProducts({
        scanId: Number(id),
        pageType: type === "AutoPilot" ? "autopilot" : "turboscanner",
        ...payload,
      })
        .unwrap()
        .then(() => onUploadSuccess());
    }
  };

  const handleChange = (newVal: string, field: keyof typeof formValues) => {
    setFormValues((prev) => ({ ...prev, [field]: newVal }));
  };

  const onExportFolderChange = (newVal: string) => {
    const find = exportFolder.includes(newVal);
    const remove = exportFolder.filter((item) => item !== newVal);
    setExportFolder((prev) => (find ? remove : [...prev, newVal]));
  };
  const onListerChange = (newVal: string) => {
    handleChange(newVal, "lister");
    if (newVal === "AutoDS") {
      if (autoDsStores?.length === 0) {
        onShowAddChange("autoDs")
      }
    } else {
      if (saleFreakStores?.length === 0) {
        onShowAddChange("saleFreaks")
      }
    }
  }
  const rowClassName = "flex justify-between w-full px-[49.5px] gap-[30px] mb-5";
  const selectClassName = "w-full";
  const filterCardClassName = "!px-8 w-full"

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <div className={rowClassName}>
        <FiltersCard
          title="Select Lister"
          Icon={SelectMenu}
          iconClassName="w-[17px] h-[11px]"
          titleClassName="text-sm"
          titleWrapperClassName="!mb-1"
          className={filterCardClassName}
        >

          <Select
            className={selectClassName}
            options={types}
            btnClassName="w-full"
            title="Select Lister"
            value={formValues.lister}
            handleChange={onListerChange}
          />
        </FiltersCard>
        <FiltersCard
          Icon={Globe}
          title="Select Store"
          iconClassName="w-3.5 h-3.5"
          titleClassName="text-sm"
          titleWrapperClassName="!mb-1"
          className={filterCardClassName}
        >
          <Select
            className={selectClassName}
            options={
              isAutoDS ? autoDsStores : saleFreakStores
            }
            hasError={errType !== false}
            btnClassName="w-full"
            title="Select Store"
            value={
              isAutoDS
                ? formValues.adStore
                : formValues.sfStore
            }
            handleChange={(newVal) =>
              handleChange(
                newVal,
                isAutoDS ? "adStore" : "sfStore"
              )
            }
            CustomOptions={errType ?
              <p className="text-errorRed text-center">
                {errType === "400" ? "Please re-integrate your AutoDs account or try again later." : "Please connect the store in your AutoDs account."}
              </p> : undefined}
          />
        </FiltersCard>
      </div>
      <div className={rowClassName}>
        <FiltersCard
          title="Marketplace"
          Icon={Store}
          iconClassName="w-3.5 h-3"
          titleClassName="text-sm"
          titleWrapperClassName="!mb-1"
          className={filterCardClassName}
        >

          <Select
            className={selectClassName}
            options={marketplace}
            btnClassName="w-full"
            title="Select Marketplace"
            value={formValues.marketplace}
            handleChange={(newVal) => handleChange(newVal, "marketplace")}
          />
        </FiltersCard>
        <FiltersCard
          title="Title source"
          Icon={Truck}
          titleClassName="text-sm"
          titleWrapperClassName="!mb-1"
          className={filterCardClassName}
        >
          <Select
            className={selectClassName}
            options={title_source(type)}
            btnClassName="w-full"
            title="Select Source"
            value={formValues.source}
            handleChange={(newVal) => handleChange(newVal, "source")}
          />
        </FiltersCard>
      </div>

      <div className="px-5 mb-[27px] w-full !px-[49.5px]">
        <FilterCheckbox
          options={exclude()}
          multiselect
          value={exportFolder}
          className="w-full grid grid-cols-2 !gap-[30px]"
          handleChange={(newVal) => onExportFolderChange(newVal.toString())}
        />
      </div>
      <Button
        title="Upload Items"
        handleClick={onUploadListings}
        type="submit"
        height="h-9"
        loading={uploadLoading || uploadFolderLoading}
        className="max-w-[188px] dark:bg-deepSpace900"
        color="smoothGreen"
      />
    </div>
  );
};

export default UploadListingsForm;
