import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import { ReactComponent as TriangleAlert } from "assets/icons/triangle-alert.svg";
import { ReactComponent as Truck } from "assets/icons/truck.svg";
import Button from "components/Button";
import FiltersCard from "components/Filters/FiltersCard";
import Loading from "components/Loading";
import Select from "components/Select";
import useFindKeywords from "hooks/useFindKeywords";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useUploadSingleProductMutation } from "store/api/productManagementApi";
import { useGetBulkStoresQuery, useGetUploadSettingsQuery } from "store/api/quickSettingsApi";
import { makeQueryParams } from "utils/makeQueryParams";
import { ItemFinderMarketplace } from "..";
import { IntegrationStepTypes } from "./Index";

const filterWrapper = "w-full max-w-[267px] px-[32.5px]";

const marketplaces = [
    { value: "US", text: "eBay.com" },
    { value: "UK", text: "eBay.co.uk" },
];
interface Props {
    type: "Salefreaks" | "AutoDS";
    marketplace?: ItemFinderMarketplace;
    onIntegrationChange: (step: IntegrationStepTypes) => void;
    asin?: string;
    supplierTitle?: string;
    ebayTitle?: string;
    handleClose: () => void;
}
const ImportProductsTo: FC<Props> = ({ type, marketplace, asin, supplierTitle, ebayTitle, onIntegrationChange, handleClose }) => {
    const isAutoDS = type === "AutoDS";
    const [errorFields, setErrorFields] = useState<string[]>([])
    const [uploadProduct, { isLoading: uploadLoading }] =
        useUploadSingleProductMutation();

    const { data: bulkStores, isFetching: storesFetching } = useGetBulkStoresQuery();
    const bulks = bulkStores?.result;
    const errMsg = bulks?.errorMessage;
    const errType = isAutoDS ? (errMsg === "error_400" ? "400" : errMsg === "error_401" ? "401" : false) : false;
    const saleFreakStores = bulks?.salesFreak.map((item) => ({
        value: item.bulkStoreId.toString(),
        text: item.storeName,
        token: item.token
    }));

    const autoDsStores =
        bulks && bulks?.autoDS.length > 0
            ? bulks?.autoDS[0].autoDsStores.map((item) => ({
                text: item.store.name,
                value: item.store.id.toString(),
            }))
            : [];
    const stores = isAutoDS ? autoDsStores : saleFreakStores

    const [formValues, setFormValues] = useState({
        storeName: "",
        storeId: "",
        amazonSite: "",
        source: "",
    });
    console.log("s", stores)
    const { data: uploadSettings, isFetching: settingsFetching } = useGetUploadSettingsQuery(undefined, { refetchOnMountOrArgChange: true });

    useEffect(() => {

        if (uploadSettings?.result && bulkStores?.result) {
            const settings = uploadSettings.result;
            const findStore = stores?.find((store) => store?.value === settings.store)

            setFormValues({
                storeName: findStore?.text || "",
                storeId: findStore?.value || "",
                amazonSite: settings.marketplace || "",
                source: (settings.tittleSource || "").toLocaleLowerCase(),
            })
        }
    }, [uploadSettings, bulkStores])

    const titleSource = [
        { value: "supplier", text: marketplace === "WAL" ? "Walmart" : marketplace === "Ali" ? "AliExpress" : "Amazon" },
        { value: "ebay", text: "eBay" },
    ];

    const { storeName, storeId, amazonSite, source } = formValues
    const keywords = useFindKeywords((source === "ebay" ? ebayTitle : source === "supplier" ? supplierTitle : "") || "", true)
    const hasWarning = keywords.foundRisky || keywords.foundVero

    const checkFieldErrors = (field?: keyof typeof formValues) => {
        let errors = [];
        if (storeId === "" && field !== "storeName") {
            errors.push("store");
        }
        if (amazonSite === "" && field !== "amazonSite") {
            errors.push("amazonSite")
        }
        if (source === "" && field !== "source") {
            errors.push("source")
        }

        setErrorFields(errors);
    }
    const onAddProduct = () => {
        const saleFreaksToken = saleFreakStores?.find((item) => item.value === storeId)?.token || ""
        const supplierType = marketplace === "Ali" ? "ali" : marketplace === "WAL" ? "walmart" : "amazon";
        if (storeId === "" || amazonSite === "" || source === "") {
            checkFieldErrors();
        } else {
            if (errType === false) {
                uploadProduct({
                    storeType: type,
                    supplierType,
                    asin: asin || "",
                    title: (source === "ebay" ? ebayTitle : supplierTitle) || "",
                    titleSource: source,
                    amazonSite: amazonSite,
                    token: isAutoDS ? "" : saleFreaksToken,
                    storeName: storeName,
                    storeId: Number(storeId),
                }).unwrap().then(() => {
                    toast.success(`Product successfully imported to ${type === "AutoDS" ? "AutoDS" : "SaleFreaks"}`);
                    handleClose();
                })
            }
        }
    }
    const handleChange = (newVal: string, field: keyof typeof formValues) => {
        if (errorFields.length > 0) {
            checkFieldErrors(field);
        }
        setFormValues((prev) => ({ ...prev, [field]: newVal }));
    };
    const selectSameProps = {
        listWrapperClassName: "!top-11",
        btnClassName: "w-full !h-[35px]"
    }

    return (
        <div className="flex flex-col items-center">
            <h6 className="mb-2 text-xl font-bold">Import Products to {isAutoDS ? "AutoDS" : "SaleFreaks"}</h6>
            <div className="mb-8 text-xl font-medium">
                <span>If you encounter any issues, please check your</span>{" "}
                <Link target="_blank" to={makeQueryParams(PATH.quickSettings.root, { tab: "integrations" })} className="underline text-electricBlue dark:text-lilac400 hover:text-primaryPurple dark:hover:text-purple500">
                    {isAutoDS ? "AutoDS" : "SaleFreaks"} settings
                </Link>
            </div>
            {settingsFetching || storesFetching ?
                <div className="flex items-center h-[110px]">
                    <Loading fill="fill-primaryPurple" />
                </div>
                : (
                    <div className="flex justify-between w-full">
                        <FiltersCard
                            Icon={Globe}
                            title="Select Store"
                            titleWrapperClassName="mb-6"
                            iconClassName="w-4 h-4"
                            className={filterWrapper}
                            infoTitle="Pick a supplier to scan the items from (Amazon.com, Amazon.co.uk, Walmart.com)"
                        >
                            <Select
                                options={stores}
                                title="Select Store"
                                hasError={errorFields.includes("store") || errType !== false}
                                value={storeId}
                                handleChange={(id, name) => {
                                    if (errorFields.length > 0) {
                                        checkFieldErrors("storeName");
                                    }
                                    setFormValues(prev => ({ ...prev, storeId: id, storeName: name }))
                                }}
                                CustomOptions={errType ?
                                    <p className="text-center text-errorRed">
                                        {errType === "400" ? "Please re-integrate your AutoDs account or try again later." : "Please connect the store in your AutoDs account."}
                                    </p>
                                    : undefined}
                                {...selectSameProps}
                            />
                        </FiltersCard>
                        <FiltersCard
                            Icon={Store}
                            title="Marketplace"
                            titleWrapperClassName="mb-6"
                            iconClassName="w-3.5 h-3"
                            className={filterWrapper}
                            infoTitle="Pick a supplier to scan the items from (Amazon.com, Amazon.co.uk, Walmart.com)"
                        >
                            <Select
                                options={marketplaces}
                                title="Select Marketplace"
                                hasError={errorFields.includes("amazonSite")}
                                value={amazonSite}
                                handleChange={(newVal) => handleChange(newVal, "amazonSite")}
                                {...selectSameProps}
                            />
                        </FiltersCard>
                        <FiltersCard
                            Icon={Truck}
                            title="Title Source"
                            titleWrapperClassName="mb-6"
                            iconClassName="w-4 h-3"
                            className={filterWrapper}
                            infoTitle="Pick a supplier to scan the items from (Amazon.com, Amazon.co.uk, Walmart.com)"
                        >
                            <Select
                                options={titleSource}
                                title="Title"
                                hasError={errorFields.includes("source")}
                                value={source}
                                handleChange={(newVal) => handleChange(newVal, "source")}
                                {...selectSameProps}
                            />
                        </FiltersCard>
                    </div>
                )}
            {errorFields.length > 0 ? (
                <p className="mt-4 text-errorRed">*{errorFields.length > 1 ? "These fields are" : "This field is"} mandatory. Select an option to continue.</p>
            ) : ""}
            {hasWarning ? (
                <div className={`text-errorRed flex flex-col justify-center items-center text-center mt-4`}>
                    <div className="flex gap-2 items-center mb-2">
                        <div className="bg-errorRed p-[2px] rounded-full">
                            <div className="rounded-full w-5 h-5 flex pt-[1px] justify-center border border-white">
                                <TriangleAlert className="fill-white" />
                            </div>
                        </div>

                        <p className="font-bold">
                            Warning!
                        </p>
                    </div>
                    <p>
                        The product you’re trying to list has been flagged as a Vero or risky item, which could lead to account suspension. Are you sure you want to proceed?
                    </p>
                </div>
            ) : ""}
            <div className="flex items-center w-full gap-[34px] justify-center mt-8">
                <Button title="Add Product" className="max-w-[300px] dark:bg-deepSpace900" variant="outlined" height="h-[60px]" titleClassName="!font-bold !text-base" color="smoothGreen"
                    handleClick={onAddProduct} loading={uploadLoading} />
                {hasWarning ? (
                    <Button title="Don't Add Product" className="max-w-[300px] dark:bg-deepSpace900" variant="outlined" height="h-[60px]" titleClassName="!font-bold !text-base" handleClick={handleClose} />
                ) : ""}
            </div>
        </div>
    )
}

export default ImportProductsTo;