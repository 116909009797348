import { createApi } from "@reduxjs/toolkit/query/react";
import {
  GetIsShopifyUser,
  GetSelectedBulkStoreListRes,
  GetSettingsRes,
  SubCount,
  UpdateSettingsReq,
  UpdateSettingsRes,
  UpdateStoreReq,
} from "types/services/quickSettings";
import {
  GetUploadSettingsRes,
  GetUserBulkStoresRes,
  GetUserDetailsRes,
  UpdateAffiliateReq,
  UpdateBreakEvenReq,
  UpdateCompetitorPrefReq,
  UpdatePasswordReq,
  UpdateSearchPrefReq,
  UpdateStoreSettingsReq,
  UpdateUserDetailsReq,
  UpdateUserDetailsRes,
} from "types/services/user";
import { GetTokensRes } from "../../types/services/quickSettings";
import { SelectedBulkStoreRes } from "./../../types/services/quickSettings";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const quickSettingsApi = createApi({
  reducerPath: "quickSettingsApi",
  baseQuery,
  tagTypes: [queryTags.quickSettings.getSettings],
  endpoints: (builder) => ({
    getSettings: builder.query<GetSettingsRes, {} | void>({
      query: () => ({
        url: queryKeys.user.getSettings,
        method: "GET",
      }),
      providesTags: [queryTags.quickSettings.getSettings],
    }),
    isShopifyUser: builder.query<GetIsShopifyUser, {} | void>({
      query: () => ({
        url: queryKeys.user.isShopifyUser,
        method: "GET",
      }),
      providesTags: [queryTags.quickSettings.getSettings],
    }),
    shopifySubCount: builder.query<SubCount, {} | void>({
      query: () => ({
        url: queryKeys.user.shopifySubCount,
        method: "GET",
      }),
    }),
    ebaySubCount: builder.query<SubCount, {} | void>({
      query: () => ({
        url: queryKeys.user.ebaySubCount,
        method: "GET",
      }),
    }),
    getStore: builder.query<GetTokensRes, void>({
      query: () => ({
        url: queryKeys.user.getStore,
        method: "GET",
      }),
      providesTags: [queryTags.quickSettings.tokenList],
    }),
    getSelectedBulkStore: builder.query<GetSelectedBulkStoreListRes, void>({
      query: () => ({
        url: queryKeys.user.getSelectedBulkStore,
        method: "GET",
      }),
      providesTags: [queryTags.quickSettings.tokenList],
    }),
    selectedBulkStore: builder.query<SelectedBulkStoreRes, void>({
      query: () => ({
        url: queryKeys.user.selectedBulkStore,
        method: "GET",
      }),
      providesTags: [queryTags.quickSettings.tokenList],
    }),
    updateSelectedBulkStore: builder.mutation<UpdateSettingsRes, number>({
      query: (bulkStoreId) => ({
        url: queryKeys.user.updateSelectedBulkStore,
        method: "PATCH",
        params: { bulkStoreId },
      }),
      invalidatesTags: [queryTags.quickSettings.tokenList],
    }),
    saveSettings: builder.mutation<UpdateSettingsRes, UpdateSettingsReq>({
      query: (body) => ({
        url: queryKeys.user.saveSettings,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [queryTags.quickSettings.getSettings],
    }),
    addStore: builder.mutation<boolean, UpdateStoreReq>({
      query: (body) => ({
        url: queryKeys.user.updateStore,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [queryTags.quickSettings.tokenList],
    }),
    deleteStore: builder.mutation<null, string>({
      query: (bulkStoreID) => ({
        url: queryKeys.user.deleteStore,
        method: "DELETE",
        params: { bulkStoreID },
      }),
      invalidatesTags: [queryTags.quickSettings.tokenList],
    }),
    getUserDetails: builder.query<GetUserDetailsRes, void>({
      query: () => ({
        url: queryKeys.user.getUserDetails,
        method: "GET",
      }),
      providesTags: [queryTags.quickSettings.getUserDeatils],
    }),
    updateUserDetails: builder.mutation<
      UpdateUserDetailsRes,
      UpdateUserDetailsReq
    >({
      query: (body) => ({
        url: queryKeys.user.updateAccountDetails,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [queryTags.quickSettings.getUserDeatils],
    }),
    updateSearchPref: builder.mutation<null, UpdateSearchPrefReq>({
      query: (body) => ({
        url: queryKeys.user.updateSearchPreference,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [queryTags.quickSettings.getSettings],
    }),
    updateCompetitorPref: builder.mutation<null, UpdateCompetitorPrefReq>({
      query: (body) => ({
        url: queryKeys.user.updateCompetitorSetting,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [queryTags.quickSettings.getSettings],
    }),
    updateBreakEven: builder.mutation<null, UpdateBreakEvenReq>({
      query: (params) => ({
        url: queryKeys.user.updateBreakEven,
        method: "PATCH",
        params,
      }),
    }),
    updateAffiliate: builder.mutation<null, UpdateAffiliateReq>({
      query: (params) => ({
        url: queryKeys.user.updateAffiliate,
        method: "PATCH",
        params,
      }),
    }),
    updatePassword: builder.mutation<null, UpdatePasswordReq>({
      query: (body) => ({
        url: queryKeys.user.updatePassword,
        method: "PATCH",
        body,
      }),
    }),
    updateSupplier: builder.mutation<null, string>({
      query: (site) => ({
        url: queryKeys.user.updateSupplier,
        params: { site },
        method: "PATCH",
      }),
      invalidatesTags: [queryTags.quickSettings.getSettings],
    }),
    updateStoreSettings: builder.mutation<null, UpdateStoreSettingsReq>({
      query: (body) => ({
        url: queryKeys.user.updateStoreSettings,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [queryTags.quickSettings.getSettings],
    }),
    authAutods: builder.query<{ message: string }, string>({
      query: (code) => ({
        url: queryKeys.user.authAutods,
        params: { code },
        method: "POST",
      }),
    }),
    getBulkStores: builder.query<GetUserBulkStoresRes, void>({
      query: () => ({
        url: queryKeys.user.getBulkStores,
        method: "GET",
      }),
      providesTags: [queryTags.quickSettings.tokenList],
    }),
    getUploadSettings: builder.query<GetUploadSettingsRes, void>({
      query: () => ({
        url: queryKeys.user.getUploadSettings,
        method: "GET",
      }),
      providesTags: [queryTags.quickSettings.tokenList],
    }),
    toggleDarkMode: builder.mutation<null, null>({
      query: () => ({
        url: queryKeys.user.toggleDarkMode,
        method: "PATCH",
      }),
      invalidatesTags: [queryTags.quickSettings.getSettings],
    }),
  }),
});

export const {
  useIsShopifyUserQuery,
  useAddStoreMutation,
  useGetSettingsQuery,
  useAuthAutodsQuery,
  useGetUploadSettingsQuery,
  useUpdateStoreSettingsMutation,
  useGetStoreQuery,
  useSaveSettingsMutation,
  useGetUserDetailsQuery,
  useUpdateSearchPrefMutation,
  useDeleteStoreMutation,
  useUpdateCompetitorPrefMutation,
  useUpdateUserDetailsMutation,
  useUpdateBreakEvenMutation,
  useUpdateAffiliateMutation,
  useUpdatePasswordMutation,
  useUpdateSupplierMutation,
  useGetBulkStoresQuery,
  useToggleDarkModeMutation,
  useEbaySubCountQuery,
  useShopifySubCountQuery,
  useGetSelectedBulkStoreQuery,
  useSelectedBulkStoreQuery,
  useUpdateSelectedBulkStoreMutation,
} = quickSettingsApi;
