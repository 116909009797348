import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { FC, SVGProps } from "react";

const CheckedBox: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <div className="relative">
            <div className="bg-spaceGreen w-[7px] h-[7px] absolute rounded-full flex items-center justify-center -right-[2px] -top-[2px]">
                <Checkmark className="fill-darkBlue w-[5px] h-[4px]" />
            </div>
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.95015 0.00313214C6.91632 0.00680076 6.88309 0.0147024 6.85133 0.0262727L0.270877 2.48707C0.199996 2.51219 0.137644 2.55593 0.0908804 2.61293L0.0876452 2.61688C0.0438226 2.67135 0.0155879 2.73569 0.00558812 2.8037C0.00529401 2.80652 0.00499989 2.80935 0.00470578 2.81217C0.00205878 2.82741 0.000588223 2.84293 0 2.85845V10.1409C0.000882334 10.3029 0.104998 10.448 0.262053 10.5067L0.261171 10.5084L6.85074 12.9731C6.94691 13.009 7.05309 13.009 7.14926 12.9731L13.7388 10.5084L13.7379 10.5067C13.8953 10.4477 13.9991 10.3029 14 10.1409V2.85817C14.0006 2.6801 13.8771 2.52376 13.6985 2.47607L11.2465 1.55863L11.2336 1.52843L11.1998 1.54141L7.14897 0.0262727C7.08544 0.00284994 7.0175 -0.00505171 6.95015 0.00313214ZM7.00073 0.817002L7.95777 1.17512L2.49877 3.21543L1.54173 2.85817L7.00073 0.817002ZM9.0901 1.5987L10.0786 1.96838L4.67343 4.02789L3.63198 3.63902L9.0901 1.5987ZM11.2051 2.38971L12.4565 2.85817L7.00073 4.89679L5.80017 4.44837L11.2051 2.38971ZM13.1771 3.43612V9.86862L7.41102 12.0261V5.59214L13.1771 3.43612ZM4.71431 4.89143L6.58721 5.59129V12.0252L0.824394 9.86918V3.43696L2.17642 3.94238M2.99758 4.24942L3.89256 4.58355C3.89198 4.58693 3.89139 4.59004 3.89109 4.59371" />
            </svg>
        </div>
    )
}

export default CheckedBox