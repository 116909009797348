import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { autopilotAliScanCols, autopilotScanCols } from "assets/tableColumns/autopilotScanList";
import Card from "components/Card";
import HoveredIcon from "components/HoveredIcon";
import ItemFinder from "components/ItemFinder";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import { SortDir } from "hooks/useSort";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteSelectedScanMutation, useLoadScanQuery } from "store/api/autopilotApi";
import { UsePaginationResponse } from "types/common";
import { ScanResultList as List } from "types/services/autopilot";
import { IconFinderItem } from "types/services/iconFinder";
import { CURRENCY_SIGNS } from "utils/formatCurrency";

const tableStyles: TableStyles = {
  minWidth: "",
  px: "px-0 pr-5",
  body: {
    rowHeight: "h-[109px]",
    contHeight: "h-[71px]",
  },
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[61px]",
  },
};

const initialSelectedItem = {
  title: "",
  price: 0,
  sold: 0,
  img: "",
  url: "",
  id: 0,
  isSimilar: false,
  isGreen: false,
};

enum SortDirection {
  asc = "asc",
  desc = "desc",
}
interface Props {
  pagination: UsePaginationResponse;
  sort: {
    direction: any;
    column: string;
  };
  changeSelectedItems: (newList: number[]) => void;
  onSortChange: (col: string, dir: any) => void;
  selectedIds: number[]
}
const AutopilotScanList: FC<Props> = ({ pagination, sort, onSortChange, changeSelectedItems, selectedIds }) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [rowSelection, setRowSelection] = useState({});
  const supplier = searchParams.get("sup");
  const currency = supplier === "UK" ? "£" : "$";
  const modal = useModal();
  const [selectedItem, setSelectedItem] = useState<IconFinderItem>(initialSelectedItem);

  const [deleteSelectedItems] = useDeleteSelectedScanMutation();
  const { data, isLoading } = useLoadScanQuery({
    autoScanId: Number(id),
    start: pagination.current * 50,
    length: 50,
    sortColumn: sort.column,
    sortColumnDir: sort.direction,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getRowId = (row: any, relativeIndex: any, parent: any) =>
    // In row object you have access to data.
    // Yon choose parameter. In this example I used uniqueId
    parent ? [parent.id, row.autoScanDetailId].join(".") : row.autoScanDetailId


  const handleSort = (column: string) => {
    const dir =
      sort.column === column
        ? sort.direction === SortDirection.desc
          ? SortDirection.asc
          : SortDirection.desc
        : SortDirection.asc;

    onSortChange(column, dir);
  };

  const onBoxClick = (item: IconFinderItem) => {
    modal.open();
    setSelectedItem({ ...item, url: item.url.startsWith("https://") ? item.url : "https://" + item.url });
  };

  const { table } = useTable({
    columns:
      supplier === "Ali"
        ? autopilotAliScanCols(onBoxClick, handleSort)
        : autopilotScanCols(handleSort),
    columnDeps: [sort, pagination.current],
    data: data?.result.scanResult || [],
    dataDeps: [data],
    enableRowSelection: true,
    rowSelection,
    onRowSelectionChange: setRowSelection,
    sort: true,
    getRowId,
  });

  useEffect(() => {
    const selectedNumbers = Object.keys(rowSelection).map(Number);
    changeSelectedItems(selectedNumbers)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);


  const onDeleteSuccess = () => {
    toast.success("Selected items have been deleted.");
    setRowSelection({});
  };
  const onDeleteFail = () => toast.error("Something went wrong");

  const handleDeleteSelectedItems = async () => {
    await deleteSelectedItems({
      scanId: Number(id),
      singleScannedItemIds: selectedIds,
      allChecked: false,
    })
      .unwrap()
      .then(onDeleteSuccess)
      .catch(onDeleteFail);
  };

  return (
    <Card className="px-5 py-6" shadow="shadow">
      <div className="mb-6 flex justify-between items-center">
        <HoveredIcon
          Icon={Trash}
          variant="outlined"
          title="Delete selected items"
          anchorId="autopilot-list-delete-selected-items"
          width="w-11"
          height="h-11"
          className="hover:bg-primaryPurple dark:hover:bg-purple500"
          iconClassName="w-[17px] h-[19px]"
          handleClick={handleDeleteSelectedItems}
        />

        <Pagination pagination={pagination} />
      </div>
      <div className="overflow-x-auto scrollbar">

        <Table<List> table={table} styles={tableStyles} isLoading={isLoading}
          sort={{
            ...sort,
            direction: sort.direction === "asc" ? "asc" as SortDir : "des" as SortDir,
          }} />
      </div>

      {selectedItem && (
        <ItemFinder currency={currency as CURRENCY_SIGNS} helpers={modal} item={selectedItem} marketplace="Ali" />
      )}
    </Card>
  );
};

export default AutopilotScanList;
