import { HIDE_YEARLY_PERIOD_TITLE } from "config/campaign-settings";
import { useEbaySubCountQuery } from "store/api/quickSettingsApi";
import EbayPlanPeriodSelect from "./EbayPeriodSelect";
import PlanPeriodRadio from "./PlanPeriodRadio";

interface Props {
  activePlan: string;
  handlePlanChange: (type: string) => void;
  shortSwitchStyles?: string;
  longSwitchStyles?: string;
  showTwoPlans?: boolean;
  showLifetime?: boolean;
}
const switches = ["monthly", "yearly", "lifetime"];

const PlanSwitch = ({ activePlan, handlePlanChange, shortSwitchStyles, longSwitchStyles, showLifetime, showTwoPlans }: Props) => {
  const { data: ebaySubCount } = useEbaySubCountQuery();

  const hasEbayPlan = ebaySubCount?.result !== 0;
  return (hasEbayPlan || showLifetime) ? (
    <div className={`w-full flex justify-end max-w-[1148px] py-2 max-sm:py-4 mx-auto ${longSwitchStyles}`}>
      <div className="flex gap-y-2 max-sm:flex-wrap max-sm:justify-start justify-end row-reverse border-2 border-white w-fit rounded-[8px] py-1.5">
        {switches.map((itm, i) => (
          <PlanPeriodRadio key={i} period={itm} active={activePlan} onPeriodSelect={handlePlanChange} showLabel={itm === "yearly" && !HIDE_YEARLY_PERIOD_TITLE} />
        ))}
      </div>
    </div>
  ) : <EbayPlanPeriodSelect selectedPeriod={activePlan} handleClick={handlePlanChange} wrapperStyles={shortSwitchStyles} showTwoPlans={showTwoPlans} />
};

export default PlanSwitch;
