import useModal from "hooks/useModal";
import { FC, useState } from "react";
import { IconFinderItem } from "types/services/iconFinder";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { ItemFinderMarketplace, SaveItem } from ".";
import ItemFinderIntegration from "./Integration/Index";
import AliBabaSimilarItems from "./SimilarItems/AliBabaItems";
import AliExpressSimilarItems from "./SimilarItems/AliExpressItems";
import AmazonSimilarItem from "./SimilarItems/AmazonItem";
import CjdSimilarItems from "./SimilarItems/CjdItems";
import WalmartSimilarItem from "./SimilarItems/WalmartItem";

interface Props {
    isFromMarketInsights?: boolean;
    marketplace: ItemFinderMarketplace
    selectedItem: IconFinderItem;
    fromPE?: boolean;
    thirdParty?: boolean;
    currency?: CURRENCY_SIGNS;
    saveLoading?: boolean;
    onSaveItem?: (itm: SaveItem) => void;
    ebayTitle?: string;
    isShopify?: boolean;
    changeSimilarLoading: (loading: boolean) => void;
    amazonPESite?: string;
    selectedFolderId?: string | null;

}
const SimilarItems: FC<Props> = ({ thirdParty, changeSimilarLoading, selectedFolderId, amazonPESite, ebayTitle, isShopify, fromPE, currency, isFromMarketInsights, onSaveItem, saveLoading, ...props }) => {
    const modal = useModal();
    const [integrationItem, setIntegrationItem] = useState({
        title: "", id: ""
    });
    const handleIntegration = (title: string, id: string) => {
        modal.open();
        setIntegrationItem({
            title, id
        })
    }
    const { marketplace } = props;
    const saveToFolder = {
        onSaveItem, saveLoading, ebayTitle, handleIntegration
    }
    const sameProps = {
        isFromMarketInsights, currency
    }
    return (
        <div className="w-full">
            {marketplace === "US" ? (
                <AmazonSimilarItem {...props} {...sameProps} {...saveToFolder} fromPE={fromPE} amazonPESite={amazonPESite} selectedFolderId={selectedFolderId} />
            ) : marketplace === "WAL" ? (
                <WalmartSimilarItem {...props} {...sameProps} {...saveToFolder} selectedFolderId={selectedFolderId} />
            ) : marketplace === "Cjd" ? (
                <CjdSimilarItems {...props} changeSimilarLoading={changeSimilarLoading} />
            ) : marketplace === "Baba" ? (
                <AliBabaSimilarItems {...props} changeSimilarLoading={changeSimilarLoading} isShopify={isShopify} />
            ) : (
                <AliExpressSimilarItems {...props} {...saveToFolder} changeSimilarLoading={changeSimilarLoading} isShopify={isShopify} selectedFolderId={selectedFolderId} />
            )}
            <ItemFinderIntegration helpers={modal} marketplace={marketplace} ebayTitle={ebayTitle} supplierTitle={integrationItem.title} asin={integrationItem.id} />
        </div>)

}

export default SimilarItems;