import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useAuthAutodsQuery } from "store/api/quickSettingsApi";
import { GetAutoDsPage, RemoveAutoDsPage, SetIntegrationFlag } from "utils/localStorage";
import { makeQueryParams } from "utils/makeQueryParams";
import LoadingPage from "./Loading";

export const AutoDs = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const navigate = useNavigate();
  const lastPage = GetAutoDsPage();
  const { isSuccess, isError } = useAuthAutodsQuery(code || "");

  const redirToIntegrations = () =>
    navigate(PATH.quickSettings.integration + "&autods=1");

  useEffect(() => {
    if (isSuccess) {
      if (lastPage) {
        RemoveAutoDsPage();
        if (lastPage.includes('isItemFinder=true')) {
          SetIntegrationFlag("true");
          //close this window
          setTimeout(() => window.close(), 500);
        }
        if (lastPage.includes(PATH.quickSettings.integration)) {
          redirToIntegrations();
        } else {
          navigate(makeQueryParams(lastPage, { autods: 1 }) || "");
        }
      } else {
        redirToIntegrations();
      }
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      navigate(lastPage || PATH.quickSettings.integration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return <LoadingPage loading={true}> </LoadingPage>;
};
