import { exactSimilarFilter, supplierSites } from "assets/data/ebayPEFilters";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as Dropshipper } from "assets/icons/dropshipper.svg";
import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as SupplierMatch } from "assets/icons/supplier.svg";
import InfoTooltip from "components/Card/InfoTooltip";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import useOutsideClick from "hooks/useOutsideClick";
import { FC, useState } from "react";

enum ITEM_TYPE {
    dropshippersOnly = "dropshippers-only",
}

interface Props {
    isDropshippersOnly: boolean;
    marketplace: string;
    supplierItemMatch: string[];
    handleDropshippersChange: () => void;
    handleSupplierChange: (newVal: string | number) => void;
    handleSupplierItemMatch: (newVal: string | number) => void;
    supplierSite: string[];
}
const dropshippersOnly = { text: "Dropshippers Only", value: ITEM_TYPE.dropshippersOnly, icon: Dropshipper, iconClassName: "w-[18px] h-[18px] -mr-2" }

const DropshippersOnlyField: FC<Props> = ({ isDropshippersOnly, handleDropshippersChange, marketplace, supplierItemMatch, supplierSite, handleSupplierChange, handleSupplierItemMatch }) => {
    const hideSupplierItemMatch = supplierSite.includes("2") && supplierSite.length === 1;
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false)
    const [ref] = useOutsideClick<HTMLDivElement>(handleClose);
    const onDropshippersOnlyClick = () => {
        handleDropshippersChange();
        setOpen(!isDropshippersOnly)
    }
    const handleArrowClick = () => {
        setOpen(prev => !prev)
    }
    const filterCheckbox = {
        className: "!gap-2",
        checkboxClassName: "!w-4 !h-4",
        checkmarkClassName: "!w-2 !h-[6px] mt-[2px]",
        maxCheckedAndDisableAfter: 3,
        multiselect: true,
        rectangleCheckbox: true,
    }
    return (
        <div className="relative" ref={ref}>
            <div className="flex items-center w-fit border-2 border-neutral2 dark:bg-deepSpace900 dark:border-grey700 p-2 rounded-[20px]" onClick={() => open ? handleClose() : {}}>
                <div className="flex items-center justify-center w-6 h-6 mr-2">
                    <Box className="w-[14px] h-[13px] fill-darkBlue dark:fill-grey100" />
                </div>
                <p className="mr-3 dark:text-grey100">Other</p>
                <div>
                    <FilterCheckbox
                        options={[dropshippersOnly]}
                        rectangleCheckbox
                        optionLabelClassName="h-11 pl-1 !px-3 min-w-[231px]"
                        checkboxClassName="!w-[18px] !h-[18px] rounded-full"
                        checkmarkClassName="!w-2.5 !h-2 mt-[1px]"
                        handleChange={onDropshippersOnlyClick}
                        value={isDropshippersOnly ? ITEM_TYPE.dropshippersOnly : ""} />
                </div>
                {isDropshippersOnly ? (
                    <button className="w-6 h-6 flex justify-center items-center" onClick={handleArrowClick}>
                        <ArrowLeft className={`fill-primaryPurple dark:fill-purple500 transition-all ${!open ? "rotate-180" : ""}`} />
                    </button>
                ) : ""}
            </div>
            {open ? (
                <div className="bg-white z-10 border-primaryPurple dark:border-lilac400 border-2 dark:bg-deepSpace900 rounded-[16px] p-1.5 absolute bottom-[0px] left-[372px] min-w-[386px] z-[9999]">
                    <div className="mb-2">
                        <div className="flex items-start justify-between dark:text-grey100 py-1 mb-2">
                            <div className="flex items-center gap-2">
                                <div className="w-6 h-6 flex items-center justify-center">
                                    <Globe className="fill-darkBlue w-[14px] h-[14px] dark:fill-grey100" />
                                </div>
                                <h6 className="text-sm font-bold">Supplier Website</h6>
                            </div>
                            <InfoTooltip title="Choose which website or supplier you are sourcing your product from" />
                        </div>
                        <FilterCheckbox
                            options={supplierSites(marketplace)}
                            optionLabelClassName="h-11 pl-4 pr-3"
                            {...filterCheckbox}
                            handleChange={handleSupplierChange}
                            value={supplierSite}
                        />
                    </div>
                    {!hideSupplierItemMatch && (
                        <div>
                            <div className="flex items-start justify-between dark:text-grey100 py-1">
                                <div className="flex items-center gap-2">
                                    <div className="w-6 h-6 flex items-center justify-center ml-1">
                                        <SupplierMatch className="fill-darkBlue w-4 h-3 dark:fill-grey100" />
                                    </div>
                                    <h6 className="text-sm font-bold">Supplier Item Match</h6>
                                </div>
                                <InfoTooltip title="Select only products that are similar or perfect matches to the chosen supplier." />
                            </div>
                            <p className="text-[10px] text-grey400 dark:text-grey300 mb-2 ml-2">Filter Exact or Similar Items for Amazon and Walmart suppliers.</p>
                            <FilterCheckbox
                                options={exactSimilarFilter}
                                optionLabelClassName="h-11 pl-3 pr-3"
                                {...filterCheckbox}
                                handleChange={handleSupplierItemMatch}
                                value={supplierItemMatch}
                            />
                        </div>
                    )}
                </div>

            ) : ""}
        </div>)
}

export default DropshippersOnlyField;