import { Cell, flexRender } from "@tanstack/react-table";
import { TableBodyStyles } from "./Table";

interface Props<T extends object> {
  cell: Cell<T, unknown>;
  isFirstCol: boolean;
  isLastCol: boolean;
  styles?: TableBodyStyles;
  alignBodyLeft?: boolean;
  previousCellExtracted?: boolean;
  isDisabled?: boolean;
}

const TableBodyCell = <T extends object>({
  cell,
  isFirstCol,
  alignBodyLeft,
  isDisabled,
  styles,
  isLastCol,
  previousCellExtracted,
}: Props<T>) => {
  const { columnDef } = cell.column;
  const { meta } = columnDef;
  const isSelected = cell.row.getIsSelected()
  const tdMainStyles = `pb-2 px-0 
    ${previousCellExtracted ? "pl-2" : ""} 
    ${meta?.extract ? "pr-2" : ""}
  `;
  const selected = styles?.selected
  const disabled = styles?.disabled;
  const defaultBorderColor = "border-secondary dark:border-deepSpace800";
  const disabledStyles = (isDivider?: boolean) => (isDivider ? disabled?.dividerBorderColor : disabled?.outlineBorderColor) || defaultBorderColor;
  const selectedStyles = (isDivider?: boolean) => (isDivider ? selected?.dividerBorderColor : selected?.outlineBorderColor) || defaultBorderColor;
  const borderColor = (isDivider?: boolean) => isDisabled ? disabledStyles(isDivider) : isSelected ? selectedStyles(isDivider) : defaultBorderColor;
  const bg = isDisabled ? disabled?.bg : isSelected ? styles?.selected?.bg : "dark:bg-black900"
  const tdStyles = `flex items-center border-y-2 ${borderColor(false)} ${bg}
    ${meta?.className || ""}
    ${isFirstCol || previousCellExtracted ? "border-l-2 rounded-l-10" : ""} 
    ${isLastCol || meta?.extract ? "border-r-2 rounded-r-10" : ""}
    ${styles?.rowHeight || "h-[60px]"}
    ${styles?.tb || ""}
  `;

  const tdContStyles = `${borderColor(true)} flex items-center w-full px-1
    ${meta?.align || alignBodyLeft ? "justify-start pl-3" : "justify-center"}
    ${isLastCol || meta?.hideBorder ? "" : "border-r-2"}
    ${styles?.cont || ""}
    ${styles?.contHeight || "h-[36px]"}
  `;

  return (
    <td
      className={tdMainStyles}
      style={{
        minWidth: columnDef?.minSize,
        ...(columnDef?.maxSize ? { maxWidth: columnDef?.maxSize } : {}),
      }}
    >
      <div className={tdStyles}>
        <div className={tdContStyles}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </div>
      </div>
    </td>
  );
};

export default TableBodyCell;
