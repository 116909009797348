import { ReactComponent as CompetitorResearch } from "assets/icons/diamond.svg";
import { ReactComponent as Heart } from "assets/icons/heart.svg";
import useDecodeToken from "hooks/useDecodeToken";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { PLAN_TYPE } from "types/plans";

interface Props {
  submenus: {
    title: string;
    disabled?: boolean;
    id: string | number;
    limited?: boolean;
    href?: string;
    path?: string;
  }[];
  id: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  header: string;
  isShopify?: boolean;
  bookmarkItem: string[];
  iconClassName?: string;
  handleAddBookmark: (id: string) => void;
  handleRemoveBookmark: (id: string) => void;
  storeName?: string;
}




const ToolsItem: FC<Props> = ({
  submenus,
  header,
  Icon,
  bookmarkItem,
  iconClassName,
  handleAddBookmark,
  handleRemoveBookmark,
  storeName
}) => {
  const [isHovered, setIsHovered] = useState("");
  const findItem = (submenu_id: string) =>
    bookmarkItem.find((item) => item === submenu_id);
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const isProPlus = plan === PLAN_TYPE.PRO_PLUS

  const handleBookmark = (id: string) =>
    findItem(id) ? handleRemoveBookmark(id) : handleAddBookmark(id);

  const getPath = (id: string | number, path: string | undefined) => {
    if (id === 602 && storeName !== undefined) {
      return `${PATH.store.root}/${storeName}`
    }

    return path;
  }

  return (
    <div className="w-full font-normal">
      <div className={`flex items-center w-full relative pl-2 pb-1 mb-2 after:bg-secondary dark:after:bg-grey500 after:absolute after:bottom-0 after:left-0 after:w-full after:h-[1px] after:rounded-full`}>
        <div className="w-6 h-6 flex items-center justify-center mr-[5px]">
          <Icon
            className={`fill-smoothGreen dark:fill-paradiseBlue ${iconClassName}`}
          />
        </div>
        <p className="font-bold dark:text-lilac400 text-sm">{header}</p>
      </div>

      <div className={`flex flex-col mb-4 gap-2`}>
        {submenus.map(({ title, disabled, path, href, id, limited }, index) => (
          <div className="flex items-center h-fit" key={index}>
            {disabled ? (
              <div className="text-grey300 dark:text-grey600 h-8 px-2 text-sm flex items-center justify-between w-full">
                <div className="flex items-center">
                  <div className="w-6 h-6 flex items-center justify-center mr-[5px]">
                    <CompetitorResearch className="fill-grey300 dark:fill-grey600 w-[14px] h-3" />
                  </div>
                  <p>{title}</p>
                </div>
                <Link
                  className={`text-[10px] py-[3.5px] leading-[12px] font-normal px-[7px] rounded-full my-auto cursor-pointer font-normal
                  bg-white text-darkBlue border-smoothGreen border hover:bg-primaryPurple hover:border-primaryPurple hover:text-white
                  dark:text-white dark:bg-purple500 dark:hover:bg-lilac400 dark:border-purple500 dark:hover:border-lilac400 dark:hover:text-darkBlue`}
                  to={path || ""}
                  target="_blank"
                  rel="noreferrer"
                >
                  Upgrade Plan
                </Link>
              </div>
            ) : (
              <div
                className={`text-sm flex items-center h-8 border-2 w-full pl-1.5 pr-2 gap-[5px]  ${title === isHovered
                  ? "rounded-full border-primaryPurple dark:border-purple500"
                  : "border-transparent"
                  }`}

                onMouseOver={() => setIsHovered(title)}
                onMouseOut={() => setIsHovered("")}
              >
                {findItem(id.toString()) || title === isHovered ? (
                  <div className="w-6 h-6 flex items-center justify-center">
                    <Heart
                      className={`cursor-pointer w-fit ${!findItem(id.toString())
                        ? "fill-lilac400 dark:fill-grey500"
                        : "fill-primaryPurple dark:fill-purple500"
                        }`}
                      onClick={() => handleBookmark(id.toString())}
                    />
                  </div>
                ) : (
                  ""
                )}
                <Link
                  className="cursor-pointer w-full leading-[32px]"
                  to={href || getPath(id, path) || ""}
                  target="_blank"

                >
                  {title}
                </Link>
                {limited && isProPlus ? (<p className="text-[10px] min-w-[80px] text-right text-primaryPurple dark:text-purple500">
                  10 scans/month
                </p>) : ""}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToolsItem;
