import { proToolsEnterprise, proToolsProPlus } from "assets/data/plans/plans";
import { autoPilot } from "assets/data/plans/proToolsContent";
import Modal from "components/Modal";
import DaysCounter from "components/Plans/DaysCounter";
import SlidedPlans from "components/Plans/SlidedPlans";
import { CAMPAIGN_END_DATE, HIDE_DAYS_COUNTER } from "config/campaign-settings";
import dayjs from "dayjs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import SubscriptionPurchase from "pages/ManagementPanel/components/ManagePayments/SubscriptionPurchase";
import SubscriptionUpgrade from "pages/ManagementPanel/components/ManagePayments/SubscriptionUpgrade";
import { ZIK_PRO_TABS } from "pages/ZikPro";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { getProToolsContent } from "utils/proTools";
interface Props {
  activeTab: ZIK_PRO_TABS | null;
}

const ProTools: FC<Props> = ({ activeTab }) => {
  const modal = useModal();
  const [searchParam] = useSearchParams();
  const hideCounter = dayjs().isAfter(new Date(CAMPAIGN_END_DATE)) || HIDE_DAYS_COUNTER;
  const [plans, setPlans] = useState(proToolsProPlus);
  const { selectedPlan } = useAppSelector((store) => store.planSlice)
  const content: {
    headingPlan?: string;
    title: string;
    description: string;
    video?: string;
    image?: string;
  } = getProToolsContent(searchParam.get("tab") || "");
  const userDetails = useDecodeToken();
  const dispatch = useAppDispatch();
  const handleSelectPlan = (newVal: TSelectedPlan) => {
    dispatch(onSelectPlan(newVal));
  };

  const user = useDecodeToken();

  const showPurchase = user.free_trial === "True"

  useEffect(() => {
    if (activeTab === ZIK_PRO_TABS.autopilot || activeTab === ZIK_PRO_TABS.turboScanner) {
      setPlans(proToolsEnterprise)
    } else {
      setPlans(proToolsProPlus)
    }
  }, [activeTab])

  const handleProToolUpgrade = () => {
    modal.open();
  };

  return (
    <>
      <div className="pt-[9px]">
        <div className="mx-auto flex justify-center mb-[15px]">
          <div>
            <div className="text-center text-lg font-bold dark:text-grey100 mb-[15px]">Upgrade to ZIK's {content.headingPlan} Plan</div>
            <div
              className="text-base text-center font-medium dark:text-grey100"
              dangerouslySetInnerHTML={{ __html: content.title }}
            />

            <p className={`text-center text-sm font-normal dark:text-grey100 ${content.headingPlan === autoPilot.headingPlan ? "mt-5" : ""}`}>
              {content.description}
            </p>
            <div>
              {userDetails.active_trail !== "True" && <div className="text-center font-medium dark:text-grey100 mt-[15px]">
                Upgrade now and we'll refund remaining credits from your current
                plan
              </div>}
            </div>
            {hideCounter ? "" : (
              <div className={`mt-5 bg-deepSpace900 w-fit justify-center mx-auto py-1 px-2 rounded-[8px] flex`}>
                <DaysCounter isDarkBg isProTools />
              </div>
            )}
          </div>
        </div>

        <SlidedPlans
          plans={plans}
          showTwoPlans={false}
          plansWrapperClassName="max-w-[1146px] mt-5 mb-10"
          handleSelectPlan={handleSelectPlan}
          handleProToolUpgrade={handleProToolUpgrade}
        />
      </div>
      <Modal
        className="max-w-[1300px]"
        maxWidth="900"
        helpers={modal}
        buttonCloseClassName="top-[12px]"
      >
        {showPurchase ? (
          <SubscriptionPurchase selectedPlan={selectedPlan} />
        ) : (
          <SubscriptionUpgrade />
        )}
      </Modal>
    </>
  );
};

export default ProTools;
