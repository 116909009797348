import BillingBackButton from "components/Plans/BackButton";
import PlansDiscountTxt from "components/Plans/DiscountTxt";
import SlidedShopifyWithComparison from "components/Plans/SlidedShopifyWithComparison";
import Toaster from "components/Toast";
import useDecodeToken from "hooks/useDecodeToken";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import { useEbaySubCountQuery } from "store/api/quickSettingsApi";
import ShopifyBillingHeader from "./components/BillingHeader";
import ShopifyBillingEbayPlans from "./components/EbayPlans";
import SelectMarketplace from "./components/SelectMarketplace";


const ShopifyPlans = () => {
    const user = useDecodeToken();
    const { search } = useLocation();
    const isEbayActive = search.includes("marketplace=ebay")
    const { data: ebaySubCount } = useEbaySubCountQuery();
    const [activeTab, setActiveTab] = useState<"ebay" | "shopify">(isEbayActive ? "ebay" : "shopify");
    const onMarketplaceChange = (tab: "ebay" | "shopify") => setActiveTab(tab);
    const currentPlan = user.shopify_package_name === "ShopProY" ? "yearly" :
        user.shopify_package_name === "ShopProM" ? "monthly" : "";
    const { data } = useGetUserPlanQuery(null);
    const shopifyPlan = data?.result.find((item) => item.isShopify);
    const isActivePlan = !shopifyPlan?.isCanceled && currentPlan !== "";
    const selectedPeriod = search.includes("yearly") ? "yearly" : search.includes("lifetime") ? "lifetime" : "monthly";
    return (
        <div className="dark:text-grey100">
            <ShopifyBillingHeader />
            <BillingBackButton>
                <div className="flex flex-col gap-4 w-full">
                    <h4 className="text-center text-lg font-medium">Pick your plan</h4>
                    <h6 className="text-center font-bold dark:text-grey100">Select your Marketplace</h6>
                    <SelectMarketplace marketplace={activeTab} onMarketplaceChange={onMarketplaceChange} />
                    <PlansDiscountTxt activePlans={activeTab} period={selectedPeriod} showTwoPlans={ebaySubCount?.result === 0} showYearlyTxt />
                </div>
            </BillingBackButton>
            {activeTab === "ebay" ? (
                <ShopifyBillingEbayPlans />
            ) :
                (<div className="w-full pt-4">
                    <SlidedShopifyWithComparison isPayNow={!isActivePlan} />
                </div>)}
            <Toaster />
        </div>
    )
}

export default ShopifyPlans