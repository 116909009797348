import { ReactComponent as Reload } from "assets/icons/reload.svg";
import HoveredIcon from "components/HoveredIcon";
import Input from "components/Input";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAddManuallyMutation } from "store/api/iconFinderApi";
import { getAmazonDomain } from "utils/settings";
import { SimilarItemType } from "./SimilarItem";

const smthWentWrong = "Something went wrong, please try again later"

interface Props {
    isWal: boolean;
    itemId: string;
    onSimilarItemChange?: (itm: SimilarItemType) => void;
    profit: string;
    wrapperClassName?: string;
}
const AddItemByAsinOrId: FC<Props> = ({ isWal, itemId, profit, onSimilarItemChange, wrapperClassName }) => {
    const [addItemValue, setAddItemValue] = useState("");
    const [postAddManually, { isLoading, data }] = useAddManuallyMutation();

    useEffect(() => {
        const item = data?.result.item
        if (data && item && onSimilarItemChange) {
            onSimilarItemChange({
                id: item.asin,
                outOfStock: item.outOfStock,
                pictureUrl: item.amazonImageURL,
                price: item.amazonFormattedPrice,
                similiar: false,
                title: item.amazonTitle,
                url: isWal ? `https://www.walmart.com/ip/${item.asin}` : `https://www.amazon${getAmazonDomain(item.amazonSite || "US")}/dp/${item.asin}`,
                profit,
                rating: item.rating,
                totalReviews: item.totalReviews,
                isPrime: item.isPrime
            })
        }
    }, [data])
    const onReloadItemId = () => {
        postAddManually({
            asin: addItemValue,
            itemId,
            isWal,
        }).unwrap().then((data) => {
            if (data.result.item) {
                setAddItemValue("")
                toast.success(isWal ? "Item ID reloaded successfully" : "Asin Added Successfully");
            } else {
                toast.error(smthWentWrong);
            }
        }).catch((error) => {
            if (error) {
                toast.error(smthWentWrong);
            }
        })
    }

    const onAddItemValueChange = (newVal: string) => {
        if (newVal.length <= 11) {
            setAddItemValue(newVal.toUpperCase().trim());
        }
    };

    return <div className={`flex items-center gap-4 mb-6 ${wrapperClassName}`}>
        <Input
            className="max-w-[171px] h-9 !pb-0 w-full"
            eraseClassName=""
            placeholder={isWal ? "Item ID" : "ASIN"}
            erase
            value={addItemValue}
            handleChange={onAddItemValueChange}
        />
        {addItemValue.length > 0 ? (
            <HoveredIcon
                variant="outlined"
                Icon={Reload}
                isLoading={isLoading}
                handleClick={onReloadItemId}
                title="Reload"
                anchorId="add-item-by-asin-or-id-reload"
                iconClassName={`w-3.5 group-hover:rotate-180 group-hover:transition-rotate group-hover:duration-200`}
            />
        ) : (
            ""
        )}
    </div>

}

export default AddItemByAsinOrId;