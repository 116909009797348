import { marketPlaces, noOfEntries } from "assets/data/filters";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as Dropshipper } from "assets/icons/dropshipper.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { prEbayCols } from "assets/tableColumns/prEbay";
import Card from "components/Card";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import ItemFinder from "components/ItemFinder";
import Scrollbar from "components/Scrollbar";
import ScrollToTop from "components/ScrollToTop";
import SearchTable from "components/Search/SearchTable";
import Select from "components/Select";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import useShowByField, { ITEM_TYPE } from "hooks/useShowByField";
import useTable from "hooks/useTable";
import { useEffect, useState } from "react";
import { IconFinderItem } from "types/services/iconFinder";
import { PrEbaySellerList } from "types/services/pr";
import { CURRENCY_SIGNS } from "utils/formatCurrency";

export const conditionList = [
  {
    text: "Dropshippers Only",
    value: ITEM_TYPE.dropshippersOnly,
    icon: Dropshipper,
    iconClassName: "w-[18px] h-[18px] dark:text-lilac400",
    checkedIconClassName: "dark:!text-deepSpace900"
  },
  {
    text: "Exclude VeRO", value: ITEM_TYPE.excludeVero, icon: Box,
    iconClassName: "w-[15px] h-[auto] fill-darkBlue group-hover:fill-white dark:fill-lilac400 dark:group-hover:fill-lilac400",
    checkedIconClassName: "dark:!fill-deepSpace900 fill-white"
  },
];

const initialSelectedItem = {
  title: "",
  price: 0,
  sold: 0,
  img: "",
  url: "",
  id: 0,
  isSimilar: false,
  isGreen: false,
};

const tableStyles: TableStyles = {
  minWidth: "min-w-[1150px]",
  px: "px-0",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[60px]",
    cont: ""
  },
  body: {
    contHeight: "h-[50px]",
    rowHeight: "h-[85px]",
    cont: "px-3",
    selected: {
      outlineBorderColor: "border-primaryPurple dark:border-purple500",
      bg: "bg-neutral2 dark:bg-deepSpace900"
    }
  },
};

const ProductResearchEbayList = () => {
  const modal = useModal();
  const [rowSelection, setRowSelection] = useState({});
  const [selectedItem, setSelectedItem] =
    useState<IconFinderItem>(initialSelectedItem);
  const { isExpand } = useAppSelector((state) => state.themeSlice);
  const {
    filters: { ebay },
    ebayData: {
      result: { sellers, currency },
    },
  } = useAppSelector((state) => state.prSlice);



  const { filteredList, itemType, changeItemType, options } = useShowByField(
    sellers || [],
    "title"
  );

  useEffect(() => {

    if (sellers) {
      if (sellers.every((item) => item.sales === 0)) {
        setSorting([{ id: "totalQuantitySold", desc: true }])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellers])

  const onItemFinderClose = () => {
    setRowSelection({})
  }
  const onBoxClick = (item: IconFinderItem) => {
    modal.open();
    setSelectedItem({ ...item, url: "https://" + item.url });
  };

  const onSellerClick = (sellerName: string) => {
    const ebayLink = marketPlaces.find((item) => item.value === ebay.eBaySite);
    window.open(`${ebayLink?.link}/usr/${sellerName}`, "_blank");
  };

  const { table, minMaxFilter, setSorting } = useTable<PrEbaySellerList>({
    columns: prEbayCols(
      onSellerClick,
      currency,
      onBoxClick,
      ebay.ebaySearchedPeriod,
      ebay.shippingLocation
    ),
    dataDeps: [filteredList],
    data: filteredList,
    initialSortBy: "sales",
    enableMultiRowSelection: false,
    enableRowSelection: true,
    rowSelection,
    onRowSelectionChange: setRowSelection,
    desc: true,
    minMax: true,
    pagination: true,
    initialPageSize: 100,
    sort: true,
    search: true,
    columnDeps: [ebay.ebaySearchedPeriod],
  });

  return (
    <Card className="w-full  pt-[15px] pb-4 mb-4" shadow={false}>
      <div className="flex max-md:flex-col max-md:gap-4 items-end justify-between px-4 mb-6">
        <div className="flex gap-6 items-center max-sm:flex-col w-full">
          <Select
            options={noOfEntries}
            value={table.getState().pagination.pageSize.toString()}
            Icon={Menu}
            iconClassName="w-[11px] h-[7px] ml-[2px] mr-[8px]"
            listWrapperClassName="!top-[50px]"
            btnClassName="!h-11 !pb-2.5 !min-w-[140px] max-w-[140px]"
            titleClassName="!mr-2"
            handleChange={(value) => table.setPageSize(Number(value))}
          />
          <Select
            value=""
            Icon={Plus}
            btnClassName="min-w-[183px] !h-11"
            iconClassName="w-[9px] h-[9px] ml-[3.33px] mr-[6.33px]"
            title="Filters"
            listWrapperClassName="dark:!bg-deepSpace900 !pr-2 py-6 !top-[50px]"
            handleChange={() => { }}
            CustomOptions={
              <div className="w-full flex flex-col gap-4">
                <div>
                  <h6 className="text-xs font-bold text-center mb-[3px]">{ebay.ebaySearchedPeriod} Day Sales</h6>
                  <MinMaxFilter
                    value={minMaxFilter("sales")}
                    name="sales"
                    inputGap="gap-[13px]"
                    inputWidth="w-16"
                    inputClassName="pt-[0px] h-[23px]"
                    handleChange={minMaxFilter("sales").onChange}
                  />
                </div>
                <div className="bg-neutral2 dark:bg-grey700 h-[2px] w-full rounded-full" />
                <div>
                  <h6 className="text-xs font-bold text-center mb-[3px]">Price</h6>
                  <MinMaxFilter
                    value={minMaxFilter("currentPrice")}
                    name="currentPrice"
                    inputGap="gap-[13px]"
                    inputWidth="w-16"
                    inputClassName="pt-[0px] h-[23px]"
                    handleChange={minMaxFilter("currentPrice").onChange}
                  />
                </div>
              </div>
            }
          />
          <FilterCheckbox
            options={conditionList}
            multiselect
            rectangleCheckbox
            checkboxClassName="!w-4 !h-4"
            checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
            optionClassName="w-full"
            optionLabelClassName="!w-full h-11"
            className="!flex-row !gap-4 w-full max-w-[454px]"
            handleChange={(newVal) => changeItemType(newVal.toString())}
            value={itemType}
          />
        </div>
        <SearchTable handleApply={table.setGlobalFilter} contClassName="!h-11 !max-w-[339px]" />
      </div>
      <div className="px-4">
        <Scrollbar className={isExpand ? "max-[1420px]:overflow-x-auto" : "max-[1310px]:overflow-x-auto"}>
          <Table<PrEbaySellerList> table={table} styles={tableStyles} />
        </Scrollbar>
      </div>


      <ScrollToTop scrollTo={715} />
      {selectedItem && (
        <ItemFinder
          currency={currency as CURRENCY_SIGNS}
          helpers={modal}
          item={selectedItem}
          onHandleClose={onItemFinderClose}
        />
      )}
    </Card>
  );
};

export default ProductResearchEbayList;
