
import { FC, useEffect } from "react";
import CheckmarkAnimation from "./checkmark-animation.gif";

interface Props {
    handleClose: () => void;
}
const IntegrationSuccessfull: FC<Props> = ({ handleClose }) => {
    useEffect(() => {
        setTimeout(() => {
            handleClose();
        }, 2000)
    }, [])
    return (
        <div className="w-full flex flex-col items-center justify-center gap-4">
            <img src={CheckmarkAnimation} alt="checkmark animation" className="w-[150px] h-[150px]" />
            <h6 className="font-bold text-[24px]">Integration Successful!</h6>
        </div>
    )
}

export default IntegrationSuccessfull;