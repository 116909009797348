import Loading from "components/Loading";
import { FC, useId } from "react";
import { Tooltip } from "react-tooltip";
interface Props {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  HoveredIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  className?: string;
  width?: string;
  variant?: "outlined" | "default";
  height?: string;
  iconColor?: string;
  isLoading?: boolean;
  anchorId?: string;
  title?: string;
  loadingClassName?: string;
  iconClassName?: string;
  hoveredIconClassName?: string;
  handleClick?: () => void;
  tooltipClassName?: string;
  noHover?: boolean;
}

const HoveredIcon: FC<Props> = ({
  Icon,
  width,
  height,
  noHover,
  HoveredIcon,
  variant = "default",
  iconClassName,
  loadingClassName,
  hoveredIconClassName,
  className,
  isLoading,
  iconColor,
  anchorId,
  handleClick,
  title,
  tooltipClassName,
}) => {
  const id = useId();
  const customId = anchorId || id;

  return (
    <button
      className={`${noHover ? "!cursor-default" : "hover:border-primaryPurple dark:hover:border-purple500"} border-2 flex justify-center ${width || "w-8"
        } ${height || "h-8"} items-center ${variant === "outlined"
          ? "border-darkBlue hover:bg-primaryPurple dark:hover:bg-purple500 cursor-pointer dark:border-grey100"
          : "border-transparent"
        } rounded-full ${className || ""} group`}
      onClick={isLoading ? () => { } : handleClick}
      data-tooltip-id={customId}
      data-tooltip-content={title}
    >
      {isLoading ? (
        <Loading
          fill="fill-smoothGreen dark:fill-paradiseBlue"
          className={`mx-auto ${loadingClassName}`}
          width={16}
          height={16}
        />
      ) : (
        <>
          {HoveredIcon && !noHover && (
            <HoveredIcon
              className={`hidden group-hover:block ${variant === "outlined" ? "fill-white" : "fill-primaryPurple dark:fill-purple500"
                } ${hoveredIconClassName || ""}`}
            />
          )}
          <Icon
            className={`${variant === "outlined"
              ? "group-hover:fill-white"
              : "group-hover:fill-primaryPurple dark:group-hover:fill-purple500"} 
              ${iconColor || "fill-darkBlue dark:fill-grey100"} 
              ${HoveredIcon ? "group-hover:hidden block" : ""} 
              ${iconClassName || ""}`}
          />
        </>
      )}
      {title && <Tooltip id={customId}
        className={`bg-darkBlue opacity-100 px-2.5 pt-2.5 pb-3 rounded-10 !z-[9999] dark:bg-grey700 dark:text-grey100 text-[10px] max-w-[300px] ${tooltipClassName}`} />}
    </button>
  );
};

export default HoveredIcon;
